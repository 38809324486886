export const ZZZDeadlyCharData = [
  {
    char: 'anby-demara',
    name: 'Anby',
    rarity: '4*',
    app_free: 4.66,
    round_free: 14352,
    app_dupes: 1.43,
    round_dupes: 22159,
    current_app_rate: 9.19,
    prev_app_rate: 15.18,
    current_avg_score: 15796,
    prev_avg_score: 15906,
    boss_1_usage: 8.78,
    boss_1_score: 16046,
    boss_2_usage: 0.46,
    boss_2_score: 14608,
    boss_3_usage: 0.72,
    boss_3_score: 13426
  },
  {
    char: 'anby-demara-soldier-0',
    name: 'Anby S0',
    rarity: 'Limited 5*',
    app_free: 3.11,
    round_free: 18357,
    app_dupes: 2.83,
    round_dupes: 33110,
    current_app_rate: 12.69,
    prev_app_rate: 12.69,
    current_avg_score: 22117,
    prev_avg_score: 22117,
    boss_1_usage: 13.38,
    boss_1_score: 22273,
    boss_2_usage: 0.17,
    boss_2_score: 0,
    boss_3_usage: 0.19,
    boss_3_score: 0
  },
  {
    char: 'anton',
    name: 'Anton',
    rarity: '4*',
    app_free: 0.38,
    round_free: 23671,
    app_dupes: 0.12,
    round_dupes: 0,
    current_app_rate: 0.62,
    prev_app_rate: 1.17,
    current_avg_score: 22911,
    prev_avg_score: 18696,
    boss_1_usage: 0.65,
    boss_1_score: 23279,
    boss_2_usage: 0,
    boss_2_score: 0,
    boss_3_usage: 0.02,
    boss_3_score: 0
  },
  {
    char: 'astra-yao',
    name: 'Astra Yao',
    rarity: 'Limited 5*',
    app_free: 20.89,
    round_free: 21971,
    app_dupes: 21.77,
    round_dupes: 35670,
    current_app_rate: 81.13,
    prev_app_rate: 78.96,
    current_avg_score: 25354,
    prev_avg_score: 24981,
    boss_1_usage: 22.16,
    boss_1_score: 25949,
    boss_2_usage: 17.74,
    boss_2_score: 31060,
    boss_3_usage: 49.39,
    boss_3_score: 23462
  },
  {
    char: 'ben',
    name: 'Ben',
    rarity: '4*',
    app_free: 0.64,
    round_free: 14213,
    app_dupes: 0.14,
    round_dupes: 0,
    current_app_rate: 1.23,
    prev_app_rate: 0.82,
    current_avg_score: 15932,
    prev_avg_score: 14024,
    boss_1_usage: 0.02,
    boss_1_score: 0,
    boss_2_usage: 0.11,
    boss_2_score: 0,
    boss_3_usage: 1.24,
    boss_3_score: 15862
  },
  {
    char: 'billy-kid',
    name: 'Billy',
    rarity: '4*',
    app_free: 0.22,
    round_free: 0,
    app_dupes: 0.09,
    round_dupes: 0,
    current_app_rate: 0.45,
    prev_app_rate: 0.87,
    current_avg_score: 14215,
    prev_avg_score: 16772,
    boss_1_usage: 0.21,
    boss_1_score: 0,
    boss_2_usage: 0.02,
    boss_2_score: 0,
    boss_3_usage: 0.27,
    boss_3_score: 0
  },
  {
    char: 'burnice',
    name: 'Burnice',
    rarity: 'Limited 5*',
    app_free: 12.43,
    round_free: 18649,
    app_dupes: 10.7,
    round_dupes: 29241,
    current_app_rate: 46.36,
    prev_app_rate: 41.27,
    current_avg_score: 21538,
    prev_avg_score: 22097,
    boss_1_usage: 6.74,
    boss_1_score: 22417,
    boss_2_usage: 13.69,
    boss_2_score: 28554,
    boss_3_usage: 30.61,
    boss_3_score: 18332
  },
  {
    char: 'caesar',
    name: 'Caesar',
    rarity: 'Limited 5*',
    app_free: 9.12,
    round_free: 18254,
    app_dupes: 11.03,
    round_dupes: 29548,
    current_app_rate: 41.63,
    prev_app_rate: 42.3,
    current_avg_score: 21152,
    prev_avg_score: 19713,
    boss_1_usage: 12.91,
    boss_1_score: 21784,
    boss_2_usage: 9.52,
    boss_2_score: 24067,
    boss_3_usage: 23.3,
    boss_3_score: 19887
  },
  {
    char: 'corin',
    name: 'Corin',
    rarity: '4*',
    app_free: 0.26,
    round_free: 0,
    app_dupes: 0.12,
    round_dupes: 0,
    current_app_rate: 0.6,
    prev_app_rate: 1.19,
    current_avg_score: 18305,
    prev_avg_score: 14432,
    boss_1_usage: 0.35,
    boss_1_score: 0,
    boss_2_usage: 0.04,
    boss_2_score: 0,
    boss_3_usage: 0.27,
    boss_3_score: 0
  },
  {
    char: 'ellen',
    name: 'Ellen',
    rarity: 'Limited 5*',
    app_free: 1.12,
    round_free: 12436,
    app_dupes: 4.78,
    round_dupes: 19481,
    current_app_rate: 11.33,
    prev_app_rate: 41.15,
    current_avg_score: 14855,
    prev_avg_score: 17227,
    boss_1_usage: 4.22,
    boss_1_score: 15643,
    boss_2_usage: 6.15,
    boss_2_score: 15395,
    boss_3_usage: 1.84,
    boss_3_score: 11769
  },
  {
    char: 'evelyn',
    name: 'Evelyn',
    rarity: 'Limited 5*',
    app_free: 12.98,
    round_free: 19478,
    app_dupes: 8.62,
    round_dupes: 32607,
    current_app_rate: 46.93,
    prev_app_rate: 8.44,
    current_avg_score: 22481,
    prev_avg_score: 20670,
    boss_1_usage: 1.21,
    boss_1_score: 21317,
    boss_2_usage: 0.42,
    boss_2_score: 22910,
    boss_3_usage: 51.02,
    boss_3_score: 22508
  },
  {
    char: 'grace-howard',
    name: 'Grace',
    rarity: 'Standard 5*',
    app_free: 1.55,
    round_free: 15027,
    app_dupes: 1.35,
    round_dupes: 27748,
    current_app_rate: 5.59,
    prev_app_rate: 6.8,
    current_avg_score: 20106,
    prev_avg_score: 16021,
    boss_1_usage: 2.93,
    boss_1_score: 15529,
    boss_2_usage: 2.86,
    boss_2_score: 24999,
    boss_3_usage: 0.21,
    boss_3_score: 0
  },
  {
    char: 'harumasa',
    name: 'Harumasa',
    rarity: 'Limited 5*',
    app_free: 10.53,
    round_free: 18388,
    app_dupes: 5.9,
    round_dupes: 27497,
    current_app_rate: 25.17,
    prev_app_rate: 33.65,
    current_avg_score: 19966,
    prev_avg_score: 17939,
    boss_1_usage: 25.97,
    boss_1_score: 20054,
    boss_2_usage: 1.12,
    boss_2_score: 16938,
    boss_3_usage: 0.14,
    boss_3_score: 0
  },
  {
    char: 'jane-doe',
    name: 'Jane Doe',
    rarity: 'Limited 5*',
    app_free: 5.78,
    round_free: 15878,
    app_dupes: 5.89,
    round_dupes: 22595,
    current_app_rate: 21.48,
    prev_app_rate: 28.1,
    current_avg_score: 17147,
    prev_avg_score: 16884,
    boss_1_usage: 4.32,
    boss_1_score: 16224,
    boss_2_usage: 1.38,
    boss_2_score: 18172,
    boss_3_usage: 18.2,
    boss_3_score: 17280
  },
  {
    char: 'koleda',
    name: 'Koleda',
    rarity: 'Standard 5*',
    app_free: 7.13,
    round_free: 16378,
    app_dupes: 2.14,
    round_dupes: 26051,
    current_app_rate: 17.9,
    prev_app_rate: 5.29,
    current_avg_score: 18937,
    prev_avg_score: 15165,
    boss_1_usage: 0.82,
    boss_1_score: 14799,
    boss_2_usage: 0.4,
    boss_2_score: 20716,
    boss_3_usage: 18.84,
    boss_3_score: 19106
  },
  {
    char: 'lighter',
    name: 'Lighter',
    rarity: 'Limited 5*',
    app_free: 4.49,
    round_free: 20374,
    app_dupes: 5.08,
    round_dupes: 34526,
    current_app_rate: 19.85,
    prev_app_rate: 15.23,
    current_avg_score: 24154,
    prev_avg_score: 22443,
    boss_1_usage: 0.62,
    boss_1_score: 20448,
    boss_2_usage: 2.33,
    boss_2_score: 22385,
    boss_3_usage: 19.2,
    boss_3_score: 24469
  },
  {
    char: 'lucy',
    name: 'Lucy',
    rarity: '4*',
    app_free: 15.69,
    round_free: 17118,
    app_dupes: 11.38,
    round_dupes: 29970,
    current_app_rate: 52.18,
    prev_app_rate: 44.33,
    current_avg_score: 20541,
    prev_avg_score: 20936,
    boss_1_usage: 6.16,
    boss_1_score: 20266,
    boss_2_usage: 15.94,
    boss_2_score: 27728,
    boss_3_usage: 35.36,
    boss_3_score: 17944
  },
  {
    char: 'lycaon',
    name: 'Lycaon',
    rarity: 'Standard 5*',
    app_free: 2.71,
    round_free: 16044,
    app_dupes: 11.6,
    round_dupes: 30758,
    current_app_rate: 29.61,
    prev_app_rate: 41.86,
    current_avg_score: 22314,
    prev_avg_score: 19388,
    boss_1_usage: 2.73,
    boss_1_score: 14608,
    boss_2_usage: 26.77,
    boss_2_score: 23903,
    boss_3_usage: 2.13,
    boss_3_score: 12897
  },
  {
    char: 'miyabi',
    name: 'Miyabi',
    rarity: 'Limited 5*',
    app_free: 5.97,
    round_free: 22249,
    app_dupes: 34.72,
    round_dupes: 36253,
    current_app_rate: 90.37,
    prev_app_rate: 91.12,
    current_avg_score: 27083,
    prev_avg_score: 26552,
    boss_1_usage: 2.3,
    boss_1_score: 24248,
    boss_2_usage: 91.78,
    boss_2_score: 27240,
    boss_3_usage: 2.1,
    boss_3_score: 20386
  },
  {
    char: 'nekomata',
    name: 'Nekomata',
    rarity: 'Standard 5*',
    app_free: 0.81,
    round_free: 15470,
    app_dupes: 0.26,
    round_dupes: 0,
    current_app_rate: 1.35,
    prev_app_rate: 1.87,
    current_avg_score: 16803,
    prev_avg_score: 15824,
    boss_1_usage: 0.75,
    boss_1_score: 18805,
    boss_2_usage: 0.04,
    boss_2_score: 0,
    boss_3_usage: 0.7,
    boss_3_score: 14724
  },
  {
    char: 'nicole-demara',
    name: 'Nicole',
    rarity: '4*',
    app_free: 19.53,
    round_free: 21388,
    app_dupes: 14.02,
    round_dupes: 32414,
    current_app_rate: 54.61,
    prev_app_rate: 52.23,
    current_avg_score: 23391,
    prev_avg_score: 20553,
    boss_1_usage: 42.52,
    boss_1_score: 23051,
    boss_2_usage: 9.52,
    boss_2_score: 27536,
    boss_3_usage: 7.14,
    boss_3_score: 20975
  },
  {
    char: 'piper',
    name: 'Piper',
    rarity: '4*',
    app_free: 4.18,
    round_free: 16084,
    app_dupes: 2.21,
    round_dupes: 24835,
    current_app_rate: 11.48,
    prev_app_rate: 10.7,
    current_avg_score: 18319,
    prev_avg_score: 17713,
    boss_1_usage: 1.14,
    boss_1_score: 16963,
    boss_2_usage: 2.86,
    boss_2_score: 22000,
    boss_3_usage: 8.69,
    boss_3_score: 17410
  },
  {
    char: 'pulchra',
    name: 'Pulchra',
    rarity: '4*',
    app_free: 1.24,
    round_free: 15343,
    app_dupes: 1,
    round_dupes: 28956,
    current_app_rate: 4.87,
    prev_app_rate: 4.87,
    current_avg_score: 19948,
    prev_avg_score: 19948,
    boss_1_usage: 4.63,
    boss_1_score: 20597,
    boss_2_usage: 0.11,
    boss_2_score: 0,
    boss_3_usage: 0.54,
    boss_3_score: 14547
  },
  {
    char: 'qingyi',
    name: 'Qingyi',
    rarity: 'Limited 5*',
    app_free: 17.61,
    round_free: 21210,
    app_dupes: 12.67,
    round_dupes: 28789,
    current_app_rate: 48.33,
    prev_app_rate: 44.92,
    current_avg_score: 22117,
    prev_avg_score: 18813,
    boss_1_usage: 48.7,
    boss_1_score: 22502,
    boss_2_usage: 0.7,
    boss_2_score: 17539,
    boss_3_usage: 2.99,
    boss_3_score: 16683
  },
  {
    char: 'rina',
    name: 'Rina',
    rarity: 'Standard 5*',
    app_free: 10.72,
    round_free: 18997,
    app_dupes: 10.46,
    round_dupes: 32664,
    current_app_rate: 38.36,
    prev_app_rate: 39.58,
    current_avg_score: 22880,
    prev_avg_score: 20031,
    boss_1_usage: 25.03,
    boss_1_score: 20575,
    boss_2_usage: 15.17,
    boss_2_score: 28062,
    boss_3_usage: 1.07,
    boss_3_score: 12001
  },
  {
    char: 'seth',
    name: 'Seth',
    rarity: '4*',
    app_free: 3.42,
    round_free: 15547,
    app_dupes: 3.12,
    round_dupes: 22402,
    current_app_rate: 11.64,
    prev_app_rate: 17.85,
    current_avg_score: 16990,
    prev_avg_score: 16679,
    boss_1_usage: 6.2,
    boss_1_score: 17994,
    boss_2_usage: 2.2,
    boss_2_score: 20697,
    boss_3_usage: 4.31,
    boss_3_score: 13179
  },
  {
    char: 'soldier-11',
    name: 'Soldier 11',
    rarity: 'Standard 5*',
    app_free: 7.08,
    round_free: 15938,
    app_dupes: 1.54,
    round_dupes: 23773,
    current_app_rate: 13.07,
    prev_app_rate: 3.58,
    current_avg_score: 17370,
    prev_avg_score: 14494,
    boss_1_usage: 0.19,
    boss_1_score: 0,
    boss_2_usage: 0.17,
    boss_2_score: 0,
    boss_3_usage: 14.32,
    boss_3_score: 17364
  },
  {
    char: 'soukaku',
    name: 'Soukaku',
    rarity: '4*',
    app_free: 3.47,
    round_free: 16883,
    app_dupes: 16.37,
    round_dupes: 31366,
    current_app_rate: 42.09,
    prev_app_rate: 47.86,
    current_avg_score: 22838,
    prev_avg_score: 19649,
    boss_1_usage: 3.19,
    boss_1_score: 15552,
    boss_2_usage: 39.86,
    boss_2_score: 23923,
    boss_3_usage: 1.82,
    boss_3_score: 12403
  },
  {
    char: 'yanagi',
    name: 'Yanagi',
    rarity: 'Limited 5*',
    app_free: 9.56,
    round_free: 21354,
    app_dupes: 13.19,
    round_dupes: 36608,
    current_app_rate: 48.98,
    prev_app_rate: 52.45,
    current_avg_score: 25903,
    prev_avg_score: 23348,
    boss_1_usage: 19.86,
    boss_1_score: 22174,
    boss_2_usage: 31.1,
    boss_2_score: 28978,
    boss_3_usage: 1.53,
    boss_3_score: 17776
  },
  {
    char: 'zhu-yuan',
    name: 'Zhu Yuan',
    rarity: 'Limited 5*',
    app_free: 12,
    round_free: 22251,
    app_dupes: 7.79,
    round_dupes: 28807,
    current_app_rate: 29.92,
    prev_app_rate: 34.22,
    current_avg_score: 22959,
    prev_avg_score: 19082,
    boss_1_usage: 30.6,
    boss_1_score: 23398,
    boss_2_usage: 0.44,
    boss_2_score: 15422,
    boss_3_usage: 1.38,
    boss_3_score: 15781
  }
];
