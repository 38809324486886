export const ZZZCharactersDaze = [
  {
    id: 0,
    name: 'Lycaon',
    slug: 'lycaon',
    disclaimer: '',
    // Damage
    dps: {
      damage: 14103,
      damage_e1: 14103,
      damage_e2: 14103,
      damage_e3: 15356,
      damage_e4: 15356,
      damage_e5: 16609,
      damage_e6: 22168
    },
    dmg: {
      damage: 228441,
      damage_e1: 228441,
      damage_e2: 228441,
      damage_e3: 248739,
      damage_e4: 248739,
      damage_e5: 269037,
      damage_e6: 359072
    },
    // Daze
    dps_standard: {
      damage: 704,
      damage_e1: 755,
      damage_e2: 755,
      damage_e3: 802,
      damage_e4: 802,
      damage_e5: 850,
      damage_e6: 850
    },
    dmg_standard: {
      damage: 11399,
      damage_e1: 12227,
      damage_e2: 12227,
      damage_e3: 12995,
      damage_e4: 12995,
      damage_e5: 13763,
      damage_e6: 13763
    },
    time: 16.2,
    disk_6: 'Impact%',
    disk_5: 'Ice DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Swing Jazz',
    weapon: 'The Restrained',
    weapon_S: '1'
  },
  {
    id: 1,
    name: 'Anby',
    slug: 'anby-demara',
    disclaimer: '',
    // Damage
    dps: {
      damage: 11492,
      damage_e1: 11492,
      damage_e2: 11492,
      damage_e3: 12435,
      damage_e4: 12435,
      damage_e5: 13377,
      damage_e6: 15209
    },
    dmg: {
      damage: 164532,
      damage_e1: 164532,
      damage_e2: 164532,
      damage_e3: 178025,
      damage_e4: 178025,
      damage_e5: 191519,
      damage_e6: 217754
    },
    // Daze
    dps_standard: {
      damage: 724,
      damage_e1: 724,
      damage_e2: 743,
      damage_e3: 789,
      damage_e4: 789,
      damage_e5: 836,
      damage_e6: 836
    },
    dmg_standard: {
      damage: 10361,
      damage_e1: 10361,
      damage_e2: 10631,
      damage_e3: 11299,
      damage_e4: 11299,
      damage_e5: 11967,
      damage_e6: 11967
    },
    time: 14.32,
    disk_6: 'Impact%',
    disk_5: 'Electric DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Swing Jazz',
    weapon: 'The Restrained',
    weapon_S: '1'
  },
  {
    id: 2,
    name: 'Koleda',
    slug: 'koleda',
    disclaimer: '',
    // Damage
    dps: {
      damage: 10716,
      damage_e1: 10716,
      damage_e2: 10716,
      damage_e3: 11585,
      damage_e4: 11585,
      damage_e5: 12455,
      damage_e6: 13315
    },
    dmg: {
      damage: 192019,
      damage_e1: 192019,
      damage_e2: 192019,
      damage_e3: 207598,
      damage_e4: 207598,
      damage_e5: 223178,
      damage_e6: 238586
    },
    // Daze
    dps_standard: {
      damage: 867,
      damage_e1: 871,
      damage_e2: 871,
      damage_e3: 926,
      damage_e4: 926,
      damage_e5: 980,
      damage_e6: 980
    },
    dmg_standard: {
      damage: 15538,
      damage_e1: 15610,
      damage_e2: 15610,
      damage_e3: 16589,
      damage_e4: 16589,
      damage_e5: 17568,
      damage_e6: 17568
    },
    time: 17.92,
    disk_6: 'Impact%',
    disk_5: 'Fire DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Swing Jazz',
    weapon: 'Hellfire Gears',
    weapon_S: '1'
  },
  {
    id: 3,
    name: 'Qingyi',
    slug: 'qingyi',
    disclaimer: '',
    // Damage
    dps: {
      damage: 19337,
      damage_e1: 23792,
      damage_e2: 23792,
      damage_e3: 25790,
      damage_e4: 25790,
      damage_e5: 27788,
      damage_e6: 38310
    },
    dmg: {
      damage: 344815,
      damage_e1: 424258,
      damage_e2: 424258,
      damage_e3: 459883,
      damage_e4: 459883,
      damage_e5: 495508,
      damage_e6: 683153
    },
    // Daze
    dps_standard: {
      damage: 786,
      damage_e1: 795,
      damage_e2: 843,
      damage_e3: 895,
      damage_e4: 895,
      damage_e5: 946,
      damage_e6: 946
    },
    dmg_standard: {
      damage: 14013,
      damage_e1: 14179,
      damage_e2: 15031,
      damage_e3: 15952,
      damage_e4: 15952,
      damage_e5: 16873,
      damage_e6: 16873
    },
    time: 17.83,
    disk_6: 'Impact%',
    disk_5: 'Electric DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Swing Jazz',
    weapon: 'Ice-Jade Teapot',
    weapon_S: '1'
  },
  {
    id: 4,
    name: 'Caesar',
    slug: 'caesar',
    disclaimer: '',
    // Damage
    dps: {
      damage: 16619,
      damage_e1: 19112,
      damage_e2: 22259,
      damage_e3: 24161,
      damage_e4: 24161,
      damage_e5: 26064,
      damage_e6: 63586
    },
    dmg: {
      damage: 67307,
      damage_e1: 77403,
      damage_e2: 90149,
      damage_e3: 97854,
      damage_e4: 97854,
      damage_e5: 105558,
      damage_e6: 257525
    },
    // Daze
    dps_standard: {
      damage: 828,
      damage_e1: 828,
      damage_e2: 828,
      damage_e3: 880,
      damage_e4: 880,
      damage_e5: 932,
      damage_e6: 932
    },
    dmg_standard: {
      damage: 3354,
      damage_e1: 3354,
      damage_e2: 3354,
      damage_e3: 3564,
      damage_e4: 3564,
      damage_e5: 3774,
      damage_e6: 3774
    },
    time: 4.05,
    disk_6: 'Impact%',
    disk_5: 'Physical DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Proto Punk',
    set_2: 'Shockstar Disco',
    weapon: 'Tusks of Fury',
    weapon_S: '1'
  },
  {
    id: 5,
    name: 'Lighter',
    slug: 'lighter',
    disclaimer: '',
    // Damage
    dps: {
      damage: 29598,
      damage_e1: 32929,
      damage_e2: 35687,
      damage_e3: 38633,
      damage_e4: 38633,
      damage_e5: 41580,
      damage_e6: 46827
    },
    dmg: {
      damage: 311788,
      damage_e1: 346878,
      damage_e2: 375926,
      damage_e3: 406963,
      damage_e4: 406963,
      damage_e5: 437999,
      damage_e6: 493274
    },
    // Daze
    dps_standard: {
      damage: 821,
      damage_e1: 821,
      damage_e2: 821,
      damage_e3: 870,
      damage_e4: 870,
      damage_e5: 920,
      damage_e6: 920
    },
    dmg_standard: {
      damage: 3354,
      damage_e1: 3354,
      damage_e2: 3354,
      damage_e3: 3564,
      damage_e4: 3564,
      damage_e5: 3774,
      damage_e6: 3774
    },
    time: 10.53,
    disk_6: 'Impact%',
    disk_5: 'ATK%',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Swing Jazz',
    weapon: 'Blazing Laurel',
    weapon_S: '1'
  },
  {
    id: 6,
    name: 'Pulchra',
    slug: 'pulchra',
    disclaimer: '',
    // Damage
    dps: {
      damage: 11671,
      damage_e1: 12420,
      damage_e2: 13662,
      damage_e3: 14849,
      damage_e4: 14849,
      damage_e5: 16036,
      damage_e6: 18277
    },
    dmg: {
      damage: 205169,
      damage_e1: 218337,
      damage_e2: 240171,
      damage_e3: 261036,
      damage_e4: 261036,
      damage_e5: 281902,
      damage_e6: 321285
    },
    // Daze
    dps_standard: {
      damage: 563,
      damage_e1: 563,
      damage_e2: 563,
      damage_e3: 598,
      damage_e4: 598,
      damage_e5: 632,
      damage_e6: 632
    },
    dmg_standard: {
      damage: 9906,
      damage_e1: 9906,
      damage_e2: 9906,
      damage_e3: 10513,
      damage_e4: 10513,
      damage_e5: 11119,
      damage_e6: 11119
    },
    time: 17.58,
    disk_6: 'Impact%',
    disk_5: 'Physical DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Astral Voice',
    set_2: 'Shockstar Disco',
    weapon: 'Box Cutter',
    weapon_S: '5'
  },
  {
    id: 7,
    name: 'Trigger',
    slug: 'trigger',
    disclaimer: '',
    // Damage
    dps: {
      damage: 16625,
      damage_e1: 19088,
      damage_e2: 20887,
      damage_e3: 22612,
      damage_e4: 23361,
      damage_e5: 25085,
      damage_e6: 40634
    },
    dmg: {
      damage: 476717,
      damage_e1: 547342,
      damage_e2: 598915,
      damage_e3: 648373,
      damage_e4: 669840,
      damage_e5: 719298,
      damage_e6: 1165141
    },
    // Daze
    dps_standard: {
      damage: 558,
      damage_e1: 558,
      damage_e2: 558,
      damage_e3: 592,
      damage_e4: 611,
      damage_e5: 646,
      damage_e6: 646
    },
    dmg_standard: {
      damage: 15986,
      damage_e1: 15986,
      damage_e2: 15986,
      damage_e3: 16968,
      damage_e4: 17530,
      damage_e5: 18512,
      damage_e6: 18512
    },
    time: 28.67,
    disk_6: 'Impact%',
    disk_5: 'Electric DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Woodpecker Electro',
    weapon: 'Spectral Gaze',
    weapon_S: '1'
  },
  {
    id: 8,
    name: 'Trigger',
    slug: 'trigger-2',
    disclaimer: '',
    // Damage
    dps: {
      damage: 41189,
      damage_e1: 47291,
      damage_e2: 51747,
      damage_e3: 56020,
      damage_e4: 57875,
      damage_e5: 62148,
      damage_e6: 100669
    },
    dmg: {
      damage: 476717,
      damage_e1: 547342,
      damage_e2: 598915,
      damage_e3: 648373,
      damage_e4: 669840,
      damage_e5: 719298,
      damage_e6: 1165141
    },
    // Daze
    dps_standard: {
      damage: 1381,
      damage_e1: 1381,
      damage_e2: 1381,
      damage_e3: 1466,
      damage_e4: 1515,
      damage_e5: 1599,
      damage_e6: 1599
    },
    dmg_standard: {
      damage: 15986,
      damage_e1: 15986,
      damage_e2: 15986,
      damage_e3: 16968,
      damage_e4: 17530,
      damage_e5: 18512,
      damage_e6: 18512
    },
    time: 11.57,
    disk_6: 'Impact%',
    disk_5: 'Electric DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Woodpecker Electro',
    weapon: 'Spectral Gaze',
    weapon_S: '1'
  }
];
