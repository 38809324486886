export const ShiyuDuos = [
  {
    char: 'astra-yao',
    app: 82.81,
    char_1: 'evelyn',
    app_rate_1: 41.94,
    avg_round_1: 108,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 24.81,
    avg_round_2: 95,
    app_flat_2: 0,
    char_3: 'anby-demara-soldier-0',
    app_rate_3: 17.48,
    avg_round_3: 118,
    app_flat_3: 0,
    char_4: 'nicole-demara',
    app_rate_4: 15.34,
    avg_round_4: 102,
    app_flat_4: 0,
    char_5: 'yanagi',
    app_rate_5: 14.3,
    avg_round_5: 99,
    app_flat_5: 0,
    char_6: 'koleda',
    app_rate_6: 12.46,
    avg_round_6: 120,
    app_flat_6: 0,
    char_7: 'lighter',
    app_rate_7: 12.21,
    avg_round_7: 94,
    app_flat_7: 0,
    char_8: 'qingyi',
    app_rate_8: 11.98,
    avg_round_8: 129,
    app_flat_8: 0,
    char_9: 'caesar',
    app_rate_9: 10.14,
    avg_round_9: 111,
    app_flat_9: 0,
    char_10: 'burnice',
    app_rate_10: 7.19,
    avg_round_10: 101,
    app_flat_10: 0,
    char_11: 'harumasa',
    app_rate_11: 5.69,
    avg_round_11: 139,
    app_flat_11: 0,
    char_12: 'pulchra',
    app_rate_12: 4.53,
    avg_round_12: 126,
    app_flat_12: 0,
    char_13: 'lucy',
    app_rate_13: 3.43,
    avg_round_13: 116,
    app_flat_13: 0,
    char_14: 'jane-doe',
    app_rate_14: 3.26,
    avg_round_14: 124,
    app_flat_14: 0,
    char_15: 'lycaon',
    app_rate_15: 2.49,
    avg_round_15: 132,
    app_flat_15: 0,
    char_16: 'soldier-11',
    app_rate_16: 2.36,
    avg_round_16: 133,
    app_flat_16: 0,
    char_17: 'rina',
    app_rate_17: 1.67,
    avg_round_17: 117,
    app_flat_17: 0,
    char_18: 'soukaku',
    app_rate_18: 1.47,
    avg_round_18: 116,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 1.39,
    avg_round_19: 137,
    app_flat_19: 0,
    char_20: 'anby-demara',
    app_rate_20: 1.39,
    avg_round_20: 151,
    app_flat_20: 0,
    char_21: 'ellen',
    app_rate_21: 0.99,
    avg_round_21: 172,
    app_flat_21: 0,
    char_22: 'piper',
    app_rate_22: 0.94,
    avg_round_22: 115,
    app_flat_22: 0,
    char_23: 'seth',
    app_rate_23: 0.87,
    avg_round_23: 142,
    app_flat_23: 0,
    char_24: 'grace-howard',
    app_rate_24: 0.72,
    avg_round_24: 123,
    app_flat_24: 0,
    char_25: 'nekomata',
    app_rate_25: 0.3,
    avg_round_25: 182,
    app_flat_25: 0,
    char_26: 'billy-kid',
    app_rate_26: 0.2,
    avg_round_26: 128,
    app_flat_26: 0,
    char_27: 'corin',
    app_rate_27: 0.17,
    avg_round_27: 124,
    app_flat_27: 0,
    char_28: 'ben',
    app_rate_28: 0.15,
    avg_round_28: 114,
    app_flat_28: 0,
    char_29: 'anton',
    app_rate_29: 0.12,
    avg_round_29: 134,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'miyabi',
    app: 71.03,
    char_1: 'yanagi',
    app_rate_1: 45.8,
    avg_round_1: 111,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 28.93,
    avg_round_2: 95,
    app_flat_2: 0,
    char_3: 'soukaku',
    app_rate_3: 23.04,
    avg_round_3: 131,
    app_flat_3: 0,
    char_4: 'lucy',
    app_rate_4: 17.74,
    avg_round_4: 107,
    app_flat_4: 0,
    char_5: 'rina',
    app_rate_5: 17.19,
    avg_round_5: 116,
    app_flat_5: 0,
    char_6: 'burnice',
    app_rate_6: 16.81,
    avg_round_6: 100,
    app_flat_6: 0,
    char_7: 'lycaon',
    app_rate_7: 14.35,
    avg_round_7: 132,
    app_flat_7: 0,
    char_8: 'caesar',
    app_rate_8: 11.04,
    avg_round_8: 122,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 9.57,
    avg_round_9: 114,
    app_flat_9: 0,
    char_10: 'lighter',
    app_rate_10: 2.7,
    avg_round_10: 105,
    app_flat_10: 0,
    char_11: 'piper',
    app_rate_11: 2.23,
    avg_round_11: 139,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 2.12,
    avg_round_12: 136,
    app_flat_12: 0,
    char_13: 'harumasa',
    app_rate_13: 1.57,
    avg_round_13: 143,
    app_flat_13: 0,
    char_14: 'qingyi',
    app_rate_14: 1.57,
    avg_round_14: 149,
    app_flat_14: 0,
    char_15: 'anby-demara-soldier-0',
    app_rate_15: 0.7,
    avg_round_15: 139,
    app_flat_15: 0,
    char_16: 'evelyn',
    app_rate_16: 0.58,
    avg_round_16: 118,
    app_flat_16: 0,
    char_17: 'seth',
    app_rate_17: 0.55,
    avg_round_17: 157,
    app_flat_17: 0,
    char_18: 'koleda',
    app_rate_18: 0.52,
    avg_round_18: 160,
    app_flat_18: 0,
    char_19: 'jane-doe',
    app_rate_19: 0.41,
    avg_round_19: 126,
    app_flat_19: 0,
    char_20: 'anby-demara',
    app_rate_20: 0.38,
    avg_round_20: 158,
    app_flat_20: 0,
    char_21: 'ellen',
    app_rate_21: 0.26,
    avg_round_21: 175,
    app_flat_21: 0,
    char_22: 'soldier-11',
    app_rate_22: 0.17,
    avg_round_22: 183,
    app_flat_22: 0,
    char_23: 'pulchra',
    app_rate_23: 0.17,
    avg_round_23: 167,
    app_flat_23: 0,
    char_24: 'zhu-yuan',
    app_rate_24: 0.09,
    avg_round_24: 106,
    app_flat_24: 0,
    char_25: 'ben',
    app_rate_25: 0.06,
    avg_round_25: 122,
    app_flat_25: 0,
    char_26: 'corin',
    app_rate_26: 0.03,
    avg_round_26: 600,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'evelyn',
    app: 43.44,
    char_1: 'astra-yao',
    app_rate_1: 79.95,
    avg_round_1: 108,
    app_flat_1: 0,
    char_2: 'lighter',
    app_rate_2: 28.2,
    avg_round_2: 99,
    app_flat_2: 0,
    char_3: 'koleda',
    app_rate_3: 27.39,
    avg_round_3: 125,
    app_flat_3: 0,
    char_4: 'lucy',
    app_rate_4: 19.38,
    avg_round_4: 131,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 17.16,
    avg_round_5: 121,
    app_flat_5: 0,
    char_6: 'nicole-demara',
    app_rate_6: 10.85,
    avg_round_6: 103,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 6.11,
    avg_round_7: 137,
    app_flat_7: 0,
    char_8: 'qingyi',
    app_rate_8: 5.64,
    avg_round_8: 121,
    app_flat_8: 0,
    char_9: 'pulchra',
    app_rate_9: 1.71,
    avg_round_9: 140,
    app_flat_9: 0,
    char_10: 'miyabi',
    app_rate_10: 0.95,
    avg_round_10: 118,
    app_flat_10: 0,
    char_11: 'soldier-11',
    app_rate_11: 0.71,
    avg_round_11: 163,
    app_flat_11: 0,
    char_12: 'anby-demara',
    app_rate_12: 0.57,
    avg_round_12: 134,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 0.47,
    avg_round_13: 151,
    app_flat_13: 0,
    char_14: 'lycaon',
    app_rate_14: 0.38,
    avg_round_14: 154,
    app_flat_14: 0,
    char_15: 'ben',
    app_rate_15: 0.24,
    avg_round_15: 140,
    app_flat_15: 0,
    char_16: 'rina',
    app_rate_16: 0.24,
    avg_round_16: 117,
    app_flat_16: 0,
    char_17: 'soukaku',
    app_rate_17: 0.09,
    avg_round_17: 82,
    app_flat_17: 0,
    char_18: 'nekomata',
    app_rate_18: 0.09,
    avg_round_18: 259,
    app_flat_18: 0,
    char_19: 'harumasa',
    app_rate_19: 0.05,
    avg_round_19: 115,
    app_flat_19: 0,
    char_20: 'piper',
    app_rate_20: 0.05,
    avg_round_20: 237,
    app_flat_20: 0,
    char_21: 'ellen',
    app_rate_21: 0.05,
    avg_round_21: 600,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yanagi',
    app: 41.05,
    char_1: 'miyabi',
    app_rate_1: 79.24,
    avg_round_1: 111,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 33.15,
    avg_round_2: 121,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 28.84,
    avg_round_3: 99,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 16.4,
    avg_round_4: 124,
    app_flat_4: 0,
    char_5: 'soukaku',
    app_rate_5: 8.68,
    avg_round_5: 125,
    app_flat_5: 0,
    char_6: 'burnice',
    app_rate_6: 8.58,
    avg_round_6: 127,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 5.12,
    avg_round_7: 123,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 4.96,
    avg_round_8: 128,
    app_flat_8: 0,
    char_9: 'seth',
    app_rate_9: 4.21,
    avg_round_9: 157,
    app_flat_9: 0,
    char_10: 'qingyi',
    app_rate_10: 2.41,
    avg_round_10: 174,
    app_flat_10: 0,
    char_11: 'anby-demara-soldier-0',
    app_rate_11: 2.06,
    avg_round_11: 159,
    app_flat_11: 0,
    char_12: 'harumasa',
    app_rate_12: 2.01,
    avg_round_12: 157,
    app_flat_12: 0,
    char_13: 'lycaon',
    app_rate_13: 1.15,
    avg_round_13: 133,
    app_flat_13: 0,
    char_14: 'jane-doe',
    app_rate_14: 1.05,
    avg_round_14: 150,
    app_flat_14: 0,
    char_15: 'grace-howard',
    app_rate_15: 0.6,
    avg_round_15: 193,
    app_flat_15: 0,
    char_16: 'anby-demara',
    app_rate_16: 0.45,
    avg_round_16: 173,
    app_flat_16: 0,
    char_17: 'piper',
    app_rate_17: 0.35,
    avg_round_17: 180,
    app_flat_17: 0,
    char_18: 'pulchra',
    app_rate_18: 0.25,
    avg_round_18: 155,
    app_flat_18: 0,
    char_19: 'koleda',
    app_rate_19: 0.1,
    avg_round_19: 147,
    app_flat_19: 0,
    char_20: 'zhu-yuan',
    app_rate_20: 0.1,
    avg_round_20: 159,
    app_flat_20: 0,
    char_21: 'soldier-11',
    app_rate_21: 0.05,
    avg_round_21: 600,
    app_flat_21: 0,
    char_22: 'ellen',
    app_rate_22: 0.05,
    avg_round_22: 600,
    app_flat_22: 0,
    char_23: 'lighter',
    app_rate_23: 0.05,
    avg_round_23: 600,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lucy',
    app: 38.67,
    char_1: 'burnice',
    app_rate_1: 47.66,
    avg_round_1: 124,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 32.59,
    avg_round_2: 107,
    app_flat_2: 0,
    char_3: 'evelyn',
    app_rate_3: 21.78,
    avg_round_3: 131,
    app_flat_3: 0,
    char_4: 'jane-doe',
    app_rate_4: 14.96,
    avg_round_4: 141,
    app_flat_4: 0,
    char_5: 'lighter',
    app_rate_5: 14.06,
    avg_round_5: 119,
    app_flat_5: 0,
    char_6: 'piper',
    app_rate_6: 13.42,
    avg_round_6: 140,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 11.13,
    avg_round_7: 147,
    app_flat_7: 0,
    char_8: 'soldier-11',
    app_rate_8: 10.76,
    avg_round_8: 150,
    app_flat_8: 0,
    char_9: 'astra-yao',
    app_rate_9: 7.35,
    avg_round_9: 116,
    app_flat_9: 0,
    char_10: 'koleda',
    app_rate_10: 5.86,
    avg_round_10: 157,
    app_flat_10: 0,
    char_11: 'yanagi',
    app_rate_11: 5.43,
    avg_round_11: 123,
    app_flat_11: 0,
    char_12: 'anby-demara-soldier-0',
    app_rate_12: 4.63,
    avg_round_12: 145,
    app_flat_12: 0,
    char_13: 'qingyi',
    app_rate_13: 2.45,
    avg_round_13: 154,
    app_flat_13: 0,
    char_14: 'pulchra',
    app_rate_14: 2.29,
    avg_round_14: 165,
    app_flat_14: 0,
    char_15: 'seth',
    app_rate_15: 1.22,
    avg_round_15: 212,
    app_flat_15: 0,
    char_16: 'nicole-demara',
    app_rate_16: 1.17,
    avg_round_16: 135,
    app_flat_16: 0,
    char_17: 'ben',
    app_rate_17: 0.91,
    avg_round_17: 145,
    app_flat_17: 0,
    char_18: 'soukaku',
    app_rate_18: 0.59,
    avg_round_18: 181,
    app_flat_18: 0,
    char_19: 'rina',
    app_rate_19: 0.48,
    avg_round_19: 140,
    app_flat_19: 0,
    char_20: 'harumasa',
    app_rate_20: 0.37,
    avg_round_20: 170,
    app_flat_20: 0,
    char_21: 'lycaon',
    app_rate_21: 0.27,
    avg_round_21: 188,
    app_flat_21: 0,
    char_22: 'nekomata',
    app_rate_22: 0.16,
    avg_round_22: 239,
    app_flat_22: 0,
    char_23: 'corin',
    app_rate_23: 0.11,
    avg_round_23: 268,
    app_flat_23: 0,
    char_24: 'anby-demara',
    app_rate_24: 0.11,
    avg_round_24: 194,
    app_flat_24: 0,
    char_25: 'ellen',
    app_rate_25: 0.05,
    avg_round_25: 141,
    app_flat_25: 0,
    char_26: 'grace-howard',
    app_rate_26: 0.05,
    avg_round_26: 230,
    app_flat_26: 0,
    char_27: 'billy-kid',
    app_rate_27: 0.05,
    avg_round_27: 203,
    app_flat_27: 0,
    char_28: 'zhu-yuan',
    app_rate_28: 0.05,
    avg_round_28: 198,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'burnice',
    app: 36.87,
    char_1: 'lucy',
    app_rate_1: 49.97,
    avg_round_1: 124,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 32.38,
    avg_round_2: 100,
    app_flat_2: 0,
    char_3: 'jane-doe',
    app_rate_3: 31.43,
    avg_round_3: 138,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 18.54,
    avg_round_4: 144,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 16.14,
    avg_round_5: 101,
    app_flat_5: 0,
    char_6: 'piper',
    app_rate_6: 15.41,
    avg_round_6: 136,
    app_flat_6: 0,
    char_7: 'yanagi',
    app_rate_7: 9.55,
    avg_round_7: 127,
    app_flat_7: 0,
    char_8: 'evelyn',
    app_rate_8: 7.2,
    avg_round_8: 137,
    app_flat_8: 0,
    char_9: 'soldier-11',
    app_rate_9: 4.08,
    avg_round_9: 161,
    app_flat_9: 0,
    char_10: 'lighter',
    app_rate_10: 3.52,
    avg_round_10: 137,
    app_flat_10: 0,
    char_11: 'soukaku',
    app_rate_11: 2.51,
    avg_round_11: 122,
    app_flat_11: 0,
    char_12: 'rina',
    app_rate_12: 2.4,
    avg_round_12: 127,
    app_flat_12: 0,
    char_13: 'seth',
    app_rate_13: 2.23,
    avg_round_13: 168,
    app_flat_13: 0,
    char_14: 'nicole-demara',
    app_rate_14: 1.34,
    avg_round_14: 137,
    app_flat_14: 0,
    char_15: 'pulchra',
    app_rate_15: 1.23,
    avg_round_15: 194,
    app_flat_15: 0,
    char_16: 'koleda',
    app_rate_16: 0.5,
    avg_round_16: 194,
    app_flat_16: 0,
    char_17: 'qingyi',
    app_rate_17: 0.39,
    avg_round_17: 154,
    app_flat_17: 0,
    char_18: 'ben',
    app_rate_18: 0.28,
    avg_round_18: 127,
    app_flat_18: 0,
    char_19: 'anby-demara-soldier-0',
    app_rate_19: 0.17,
    avg_round_19: 180,
    app_flat_19: 0,
    char_20: 'grace-howard',
    app_rate_20: 0.17,
    avg_round_20: 169,
    app_flat_20: 0,
    char_21: 'harumasa',
    app_rate_21: 0.17,
    avg_round_21: 149,
    app_flat_21: 0,
    char_22: 'nekomata',
    app_rate_22: 0.17,
    avg_round_22: 201,
    app_flat_22: 0,
    char_23: 'lycaon',
    app_rate_23: 0.11,
    avg_round_23: 140,
    app_flat_23: 0,
    char_24: 'anby-demara',
    app_rate_24: 0.06,
    avg_round_24: 600,
    app_flat_24: 0,
    char_25: 'corin',
    app_rate_25: 0.06,
    avg_round_25: 219,
    app_flat_25: 0,
    char_26: 'ellen',
    app_rate_26: 0.06,
    avg_round_26: 600,
    app_flat_26: 0,
    char_27: 'zhu-yuan',
    app_rate_27: 0.06,
    avg_round_27: 169,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'caesar',
    app: 36.59,
    char_1: 'anby-demara-soldier-0',
    app_rate_1: 23.47,
    avg_round_1: 135,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 22.96,
    avg_round_2: 111,
    app_flat_2: 0,
    char_3: 'miyabi',
    app_rate_3: 21.44,
    avg_round_3: 122,
    app_flat_3: 0,
    char_4: 'evelyn',
    app_rate_4: 20.37,
    avg_round_4: 121,
    app_flat_4: 0,
    char_5: 'burnice',
    app_rate_5: 18.68,
    avg_round_5: 144,
    app_flat_5: 0,
    char_6: 'yanagi',
    app_rate_6: 18.4,
    avg_round_6: 124,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 11.76,
    avg_round_7: 147,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 11.59,
    avg_round_8: 150,
    app_flat_8: 0,
    char_9: 'qingyi',
    app_rate_9: 6.81,
    avg_round_9: 157,
    app_flat_9: 0,
    char_10: 'pulchra',
    app_rate_10: 6.36,
    avg_round_10: 149,
    app_flat_10: 0,
    char_11: 'soldier-11',
    app_rate_11: 6.02,
    avg_round_11: 144,
    app_flat_11: 0,
    char_12: 'rina',
    app_rate_12: 5.63,
    avg_round_12: 138,
    app_flat_12: 0,
    char_13: 'piper',
    app_rate_13: 4.67,
    avg_round_13: 155,
    app_flat_13: 0,
    char_14: 'nicole-demara',
    app_rate_14: 3.6,
    avg_round_14: 140,
    app_flat_14: 0,
    char_15: 'lighter',
    app_rate_15: 3.6,
    avg_round_15: 119,
    app_flat_15: 0,
    char_16: 'harumasa',
    app_rate_16: 3.1,
    avg_round_16: 170,
    app_flat_16: 0,
    char_17: 'soukaku',
    app_rate_17: 2.93,
    avg_round_17: 144,
    app_flat_17: 0,
    char_18: 'ellen',
    app_rate_18: 1.97,
    avg_round_18: 162,
    app_flat_18: 0,
    char_19: 'seth',
    app_rate_19: 1.69,
    avg_round_19: 194,
    app_flat_19: 0,
    char_20: 'koleda',
    app_rate_20: 1.41,
    avg_round_20: 163,
    app_flat_20: 0,
    char_21: 'lycaon',
    app_rate_21: 1.07,
    avg_round_21: 164,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.68,
    avg_round_22: 174,
    app_flat_22: 0,
    char_23: 'anby-demara',
    app_rate_23: 0.56,
    avg_round_23: 203,
    app_flat_23: 0,
    char_24: 'billy-kid',
    app_rate_24: 0.34,
    avg_round_24: 166,
    app_flat_24: 0,
    char_25: 'corin',
    app_rate_25: 0.28,
    avg_round_25: 152,
    app_flat_25: 0,
    char_26: 'anton',
    app_rate_26: 0.17,
    avg_round_26: 190,
    app_flat_26: 0,
    char_27: 'ben',
    app_rate_27: 0.11,
    avg_round_27: 231,
    app_flat_27: 0,
    char_28: 'nekomata',
    app_rate_28: 0.11,
    avg_round_28: 186,
    app_flat_28: 0,
    char_29: 'grace-howard',
    app_rate_29: 0.06,
    avg_round_29: 177,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'qingyi',
    app: 29.46,
    char_1: 'harumasa',
    app_rate_1: 41.37,
    avg_round_1: 146,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 33.68,
    avg_round_2: 129,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 29.28,
    avg_round_3: 150,
    app_flat_3: 0,
    char_4: 'anby-demara-soldier-0',
    app_rate_4: 25.86,
    avg_round_4: 145,
    app_flat_4: 0,
    char_5: 'rina',
    app_rate_5: 21.52,
    avg_round_5: 155,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 14.81,
    avg_round_6: 157,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 8.46,
    avg_round_7: 157,
    app_flat_7: 0,
    char_8: 'evelyn',
    app_rate_8: 8.32,
    avg_round_8: 121,
    app_flat_8: 0,
    char_9: 'miyabi',
    app_rate_9: 3.77,
    avg_round_9: 149,
    app_flat_9: 0,
    char_10: 'yanagi',
    app_rate_10: 3.35,
    avg_round_10: 174,
    app_flat_10: 0,
    char_11: 'lucy',
    app_rate_11: 3.21,
    avg_round_11: 154,
    app_flat_11: 0,
    char_12: 'soukaku',
    app_rate_12: 1.12,
    avg_round_12: 152,
    app_flat_12: 0,
    char_13: 'anton',
    app_rate_13: 0.7,
    avg_round_13: 145,
    app_flat_13: 0,
    char_14: 'soldier-11',
    app_rate_14: 0.7,
    avg_round_14: 148,
    app_flat_14: 0,
    char_15: 'jane-doe',
    app_rate_15: 0.63,
    avg_round_15: 179,
    app_flat_15: 0,
    char_16: 'corin',
    app_rate_16: 0.56,
    avg_round_16: 157,
    app_flat_16: 0,
    char_17: 'burnice',
    app_rate_17: 0.49,
    avg_round_17: 154,
    app_flat_17: 0,
    char_18: 'ellen',
    app_rate_18: 0.49,
    avg_round_18: 218,
    app_flat_18: 0,
    char_19: 'seth',
    app_rate_19: 0.42,
    avg_round_19: 250,
    app_flat_19: 0,
    char_20: 'pulchra',
    app_rate_20: 0.35,
    avg_round_20: 179,
    app_flat_20: 0,
    char_21: 'nekomata',
    app_rate_21: 0.35,
    avg_round_21: 185,
    app_flat_21: 0,
    char_22: 'grace-howard',
    app_rate_22: 0.28,
    avg_round_22: 165,
    app_flat_22: 0,
    char_23: 'billy-kid',
    app_rate_23: 0.07,
    avg_round_23: 139,
    app_flat_23: 0,
    char_24: 'piper',
    app_rate_24: 0.07,
    avg_round_24: 600,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nicole-demara',
    app: 28.39,
    char_1: 'astra-yao',
    app_rate_1: 44.74,
    avg_round_1: 102,
    app_flat_1: 0,
    char_2: 'qingyi',
    app_rate_2: 30.38,
    avg_round_2: 150,
    app_flat_2: 0,
    char_3: 'miyabi',
    app_rate_3: 23.93,
    avg_round_3: 114,
    app_flat_3: 0,
    char_4: 'anby-demara-soldier-0',
    app_rate_4: 22.48,
    avg_round_4: 124,
    app_flat_4: 0,
    char_5: 'evelyn',
    app_rate_5: 16.61,
    avg_round_5: 103,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 15.95,
    avg_round_6: 155,
    app_flat_6: 0,
    char_7: 'harumasa',
    app_rate_7: 13.92,
    avg_round_7: 150,
    app_flat_7: 0,
    char_8: 'yanagi',
    app_rate_8: 7.18,
    avg_round_8: 128,
    app_flat_8: 0,
    char_9: 'caesar',
    app_rate_9: 4.64,
    avg_round_9: 140,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 3.7,
    avg_round_10: 169,
    app_flat_10: 0,
    char_11: 'pulchra',
    app_rate_11: 2.54,
    avg_round_11: 153,
    app_flat_11: 0,
    char_12: 'burnice',
    app_rate_12: 1.74,
    avg_round_12: 137,
    app_flat_12: 0,
    char_13: 'lycaon',
    app_rate_13: 1.67,
    avg_round_13: 143,
    app_flat_13: 0,
    char_14: 'lucy',
    app_rate_14: 1.6,
    avg_round_14: 135,
    app_flat_14: 0,
    char_15: 'soukaku',
    app_rate_15: 1.31,
    avg_round_15: 140,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 1.16,
    avg_round_16: 162,
    app_flat_16: 0,
    char_17: 'rina',
    app_rate_17: 0.8,
    avg_round_17: 117,
    app_flat_17: 0,
    char_18: 'lighter',
    app_rate_18: 0.8,
    avg_round_18: 137,
    app_flat_18: 0,
    char_19: 'koleda',
    app_rate_19: 0.73,
    avg_round_19: 150,
    app_flat_19: 0,
    char_20: 'soldier-11',
    app_rate_20: 0.73,
    avg_round_20: 151,
    app_flat_20: 0,
    char_21: 'seth',
    app_rate_21: 0.73,
    avg_round_21: 188,
    app_flat_21: 0,
    char_22: 'anton',
    app_rate_22: 0.65,
    avg_round_22: 142,
    app_flat_22: 0,
    char_23: 'nekomata',
    app_rate_23: 0.58,
    avg_round_23: 158,
    app_flat_23: 0,
    char_24: 'billy-kid',
    app_rate_24: 0.51,
    avg_round_24: 125,
    app_flat_24: 0,
    char_25: 'piper',
    app_rate_25: 0.36,
    avg_round_25: 150,
    app_flat_25: 0,
    char_26: 'corin',
    app_rate_26: 0.22,
    avg_round_26: 132,
    app_flat_26: 0,
    char_27: 'ellen',
    app_rate_27: 0.22,
    avg_round_27: 193,
    app_flat_27: 0,
    char_28: 'grace-howard',
    app_rate_28: 0.22,
    avg_round_28: 138,
    app_flat_28: 0,
    char_29: 'ben',
    app_rate_29: 0.07,
    avg_round_29: 97,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara-soldier-0',
    app: 26.46,
    char_1: 'astra-yao',
    app_rate_1: 54.71,
    avg_round_1: 118,
    app_flat_1: 0,
    char_2: 'caesar',
    app_rate_2: 32.45,
    avg_round_2: 135,
    app_flat_2: 0,
    char_3: 'qingyi',
    app_rate_3: 28.79,
    avg_round_3: 145,
    app_flat_3: 0,
    char_4: 'pulchra',
    app_rate_4: 28.02,
    avg_round_4: 140,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 24.12,
    avg_round_5: 124,
    app_flat_5: 0,
    char_6: 'rina',
    app_rate_6: 14.79,
    avg_round_6: 156,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 6.77,
    avg_round_7: 145,
    app_flat_7: 0,
    char_8: 'anby-demara',
    app_rate_8: 3.19,
    avg_round_8: 167,
    app_flat_8: 0,
    char_9: 'yanagi',
    app_rate_9: 3.19,
    avg_round_9: 159,
    app_flat_9: 0,
    char_10: 'miyabi',
    app_rate_10: 1.87,
    avg_round_10: 139,
    app_flat_10: 0,
    char_11: 'soukaku',
    app_rate_11: 0.31,
    avg_round_11: 600,
    app_flat_11: 0,
    char_12: 'lycaon',
    app_rate_12: 0.31,
    avg_round_12: 171,
    app_flat_12: 0,
    char_13: 'seth',
    app_rate_13: 0.31,
    avg_round_13: 168,
    app_flat_13: 0,
    char_14: 'burnice',
    app_rate_14: 0.23,
    avg_round_14: 180,
    app_flat_14: 0,
    char_15: 'jane-doe',
    app_rate_15: 0.16,
    avg_round_15: 196,
    app_flat_15: 0,
    char_16: 'grace-howard',
    app_rate_16: 0.08,
    avg_round_16: 158,
    app_flat_16: 0,
    char_17: 'harumasa',
    app_rate_17: 0.08,
    avg_round_17: 600,
    app_flat_17: 0,
    char_18: 'lighter',
    app_rate_18: 0.08,
    avg_round_18: 143,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.08,
    avg_round_19: 231,
    app_flat_19: 0,
    char_20: 'zhu-yuan',
    app_rate_20: 0.08,
    avg_round_20: 184,
    app_flat_20: 0,
    char_21: 'koleda',
    app_rate_21: 0.08,
    avg_round_21: 172,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'rina',
    app: 25.78,
    char_1: 'yanagi',
    app_rate_1: 52.8,
    avg_round_1: 121,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 47.36,
    avg_round_2: 116,
    app_flat_2: 0,
    char_3: 'qingyi',
    app_rate_3: 24.6,
    avg_round_3: 155,
    app_flat_3: 0,
    char_4: 'harumasa',
    app_rate_4: 20.45,
    avg_round_4: 157,
    app_flat_4: 0,
    char_5: 'anby-demara-soldier-0',
    app_rate_5: 15.18,
    avg_round_5: 156,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 7.99,
    avg_round_6: 138,
    app_flat_6: 0,
    char_7: 'astra-yao',
    app_rate_7: 5.35,
    avg_round_7: 117,
    app_flat_7: 0,
    char_8: 'seth',
    app_rate_8: 4.87,
    avg_round_8: 162,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 4.07,
    avg_round_9: 150,
    app_flat_9: 0,
    char_10: 'burnice',
    app_rate_10: 3.43,
    avg_round_10: 127,
    app_flat_10: 0,
    char_11: 'pulchra',
    app_rate_11: 3.12,
    avg_round_11: 159,
    app_flat_11: 0,
    char_12: 'anby-demara',
    app_rate_12: 2.48,
    avg_round_12: 191,
    app_flat_12: 0,
    char_13: 'lycaon',
    app_rate_13: 1.76,
    avg_round_13: 161,
    app_flat_13: 0,
    char_14: 'soukaku',
    app_rate_14: 1.2,
    avg_round_14: 150,
    app_flat_14: 0,
    char_15: 'jane-doe',
    app_rate_15: 1.12,
    avg_round_15: 184,
    app_flat_15: 0,
    char_16: 'nicole-demara',
    app_rate_16: 0.88,
    avg_round_16: 117,
    app_flat_16: 0,
    char_17: 'lucy',
    app_rate_17: 0.72,
    avg_round_17: 140,
    app_flat_17: 0,
    char_18: 'anton',
    app_rate_18: 0.72,
    avg_round_18: 165,
    app_flat_18: 0,
    char_19: 'ellen',
    app_rate_19: 0.64,
    avg_round_19: 120,
    app_flat_19: 0,
    char_20: 'corin',
    app_rate_20: 0.48,
    avg_round_20: 163,
    app_flat_20: 0,
    char_21: 'evelyn',
    app_rate_21: 0.4,
    avg_round_21: 117,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.16,
    avg_round_22: 193,
    app_flat_22: 0,
    char_23: 'koleda',
    app_rate_23: 0.16,
    avg_round_23: 600,
    app_flat_23: 0,
    char_24: 'lighter',
    app_rate_24: 0.08,
    avg_round_24: 600,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lighter',
    app: 18.24,
    char_1: 'evelyn',
    app_rate_1: 67.16,
    avg_round_1: 99,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 55.42,
    avg_round_2: 94,
    app_flat_2: 0,
    char_3: 'lucy',
    app_rate_3: 29.8,
    avg_round_3: 119,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 14.45,
    avg_round_4: 128,
    app_flat_4: 0,
    char_5: 'miyabi',
    app_rate_5: 10.5,
    avg_round_5: 105,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 7.22,
    avg_round_6: 119,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 7.11,
    avg_round_7: 137,
    app_flat_7: 0,
    char_8: 'ellen',
    app_rate_8: 2.37,
    avg_round_8: 162,
    app_flat_8: 0,
    char_9: 'soukaku',
    app_rate_9: 1.24,
    avg_round_9: 198,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 1.24,
    avg_round_10: 137,
    app_flat_10: 0,
    char_11: 'ben',
    app_rate_11: 0.9,
    avg_round_11: 105,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 0.79,
    avg_round_12: 183,
    app_flat_12: 0,
    char_13: 'lycaon',
    app_rate_13: 0.68,
    avg_round_13: 143,
    app_flat_13: 0,
    char_14: 'piper',
    app_rate_14: 0.34,
    avg_round_14: 223,
    app_flat_14: 0,
    char_15: 'koleda',
    app_rate_15: 0.23,
    avg_round_15: 125,
    app_flat_15: 0,
    char_16: 'pulchra',
    app_rate_16: 0.11,
    avg_round_16: 140,
    app_flat_16: 0,
    char_17: 'harumasa',
    app_rate_17: 0.11,
    avg_round_17: 211,
    app_flat_17: 0,
    char_18: 'corin',
    app_rate_18: 0.11,
    avg_round_18: 219,
    app_flat_18: 0,
    char_19: 'anby-demara-soldier-0',
    app_rate_19: 0.11,
    avg_round_19: 143,
    app_flat_19: 0,
    char_20: 'yanagi',
    app_rate_20: 0.11,
    avg_round_20: 600,
    app_flat_20: 0,
    char_21: 'rina',
    app_rate_21: 0.11,
    avg_round_21: 600,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.11,
    avg_round_22: 162,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soukaku',
    app: 17.62,
    char_1: 'miyabi',
    app_rate_1: 92.87,
    avg_round_1: 131,
    app_flat_1: 0,
    char_2: 'lycaon',
    app_rate_2: 46.5,
    avg_round_2: 141,
    app_flat_2: 0,
    char_3: 'yanagi',
    app_rate_3: 20.21,
    avg_round_3: 125,
    app_flat_3: 0,
    char_4: 'ellen',
    app_rate_4: 7.01,
    avg_round_4: 207,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 6.89,
    avg_round_5: 116,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 6.07,
    avg_round_6: 144,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 5.26,
    avg_round_7: 122,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 2.1,
    avg_round_8: 140,
    app_flat_8: 0,
    char_9: 'qingyi',
    app_rate_9: 1.87,
    avg_round_9: 152,
    app_flat_9: 0,
    char_10: 'rina',
    app_rate_10: 1.75,
    avg_round_10: 150,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 1.64,
    avg_round_11: 142,
    app_flat_11: 0,
    char_12: 'lucy',
    app_rate_12: 1.29,
    avg_round_12: 181,
    app_flat_12: 0,
    char_13: 'lighter',
    app_rate_13: 1.29,
    avg_round_13: 198,
    app_flat_13: 0,
    char_14: 'harumasa',
    app_rate_14: 0.82,
    avg_round_14: 150,
    app_flat_14: 0,
    char_15: 'piper',
    app_rate_15: 0.7,
    avg_round_15: 152,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 0.58,
    avg_round_16: 159,
    app_flat_16: 0,
    char_17: 'anby-demara-soldier-0',
    app_rate_17: 0.47,
    avg_round_17: 600,
    app_flat_17: 0,
    char_18: 'evelyn',
    app_rate_18: 0.23,
    avg_round_18: 82,
    app_flat_18: 0,
    char_19: 'koleda',
    app_rate_19: 0.23,
    avg_round_19: 197,
    app_flat_19: 0,
    char_20: 'seth',
    app_rate_20: 0.23,
    avg_round_20: 133,
    app_flat_20: 0,
    char_21: 'anby-demara',
    app_rate_21: 0.23,
    avg_round_21: 10,
    app_flat_21: 0,
    char_22: 'soldier-11',
    app_rate_22: 0.12,
    avg_round_22: 600,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'harumasa',
    app: 16.08,
    char_1: 'qingyi',
    app_rate_1: 75.8,
    avg_round_1: 146,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 32.78,
    avg_round_2: 157,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 29.32,
    avg_round_3: 139,
    app_flat_3: 0,
    char_4: 'nicole-demara',
    app_rate_4: 24.58,
    avg_round_4: 150,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 8.58,
    avg_round_5: 182,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 7.04,
    avg_round_6: 170,
    app_flat_6: 0,
    char_7: 'miyabi',
    app_rate_7: 6.91,
    avg_round_7: 143,
    app_flat_7: 0,
    char_8: 'yanagi',
    app_rate_8: 5.12,
    avg_round_8: 157,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 3.2,
    avg_round_9: 172,
    app_flat_9: 0,
    char_10: 'lycaon',
    app_rate_10: 1.92,
    avg_round_10: 186,
    app_flat_10: 0,
    char_11: 'soukaku',
    app_rate_11: 0.9,
    avg_round_11: 150,
    app_flat_11: 0,
    char_12: 'lucy',
    app_rate_12: 0.9,
    avg_round_12: 170,
    app_flat_12: 0,
    char_13: 'pulchra',
    app_rate_13: 0.9,
    avg_round_13: 211,
    app_flat_13: 0,
    char_14: 'seth',
    app_rate_14: 0.77,
    avg_round_14: 236,
    app_flat_14: 0,
    char_15: 'jane-doe',
    app_rate_15: 0.51,
    avg_round_15: 258,
    app_flat_15: 0,
    char_16: 'burnice',
    app_rate_16: 0.38,
    avg_round_16: 149,
    app_flat_16: 0,
    char_17: 'anby-demara-soldier-0',
    app_rate_17: 0.13,
    avg_round_17: 600,
    app_flat_17: 0,
    char_18: 'evelyn',
    app_rate_18: 0.13,
    avg_round_18: 115,
    app_flat_18: 0,
    char_19: 'lighter',
    app_rate_19: 0.13,
    avg_round_19: 211,
    app_flat_19: 0,
    char_20: 'koleda',
    app_rate_20: 0.13,
    avg_round_20: 600,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jane-doe',
    app: 14.95,
    char_1: 'burnice',
    app_rate_1: 77.55,
    avg_round_1: 138,
    app_flat_1: 0,
    char_2: 'lucy',
    app_rate_2: 38.71,
    avg_round_2: 141,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 28.37,
    avg_round_3: 150,
    app_flat_3: 0,
    char_4: 'astra-yao',
    app_rate_4: 18.04,
    avg_round_4: 124,
    app_flat_4: 0,
    char_5: 'seth',
    app_rate_5: 17.91,
    avg_round_5: 188,
    app_flat_5: 0,
    char_6: 'yanagi',
    app_rate_6: 2.89,
    avg_round_6: 150,
    app_flat_6: 0,
    char_7: 'nicole-demara',
    app_rate_7: 2.2,
    avg_round_7: 162,
    app_flat_7: 0,
    char_8: 'pulchra',
    app_rate_8: 2.07,
    avg_round_8: 196,
    app_flat_8: 0,
    char_9: 'rina',
    app_rate_9: 1.93,
    avg_round_9: 184,
    app_flat_9: 0,
    char_10: 'miyabi',
    app_rate_10: 1.93,
    avg_round_10: 126,
    app_flat_10: 0,
    char_11: 'evelyn',
    app_rate_11: 1.38,
    avg_round_11: 151,
    app_flat_11: 0,
    char_12: 'qingyi',
    app_rate_12: 1.24,
    avg_round_12: 179,
    app_flat_12: 0,
    char_13: 'lighter',
    app_rate_13: 0.96,
    avg_round_13: 183,
    app_flat_13: 0,
    char_14: 'piper',
    app_rate_14: 0.96,
    avg_round_14: 227,
    app_flat_14: 0,
    char_15: 'soukaku',
    app_rate_15: 0.69,
    avg_round_15: 159,
    app_flat_15: 0,
    char_16: 'koleda',
    app_rate_16: 0.69,
    avg_round_16: 202,
    app_flat_16: 0,
    char_17: 'harumasa',
    app_rate_17: 0.55,
    avg_round_17: 258,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.41,
    avg_round_18: 199,
    app_flat_18: 0,
    char_19: 'grace-howard',
    app_rate_19: 0.41,
    avg_round_19: 279,
    app_flat_19: 0,
    char_20: 'anby-demara-soldier-0',
    app_rate_20: 0.28,
    avg_round_20: 196,
    app_flat_20: 0,
    char_21: 'corin',
    app_rate_21: 0.14,
    avg_round_21: 600,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.14,
    avg_round_22: 600,
    app_flat_22: 0,
    char_23: 'anby-demara',
    app_rate_23: 0.14,
    avg_round_23: 233,
    app_flat_23: 0,
    char_24: 'nekomata',
    app_rate_24: 0.14,
    avg_round_24: 196,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'koleda',
    app: 13.65,
    char_1: 'evelyn',
    app_rate_1: 87.18,
    avg_round_1: 125,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 75.57,
    avg_round_2: 120,
    app_flat_2: 0,
    char_3: 'lucy',
    app_rate_3: 16.59,
    avg_round_3: 157,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 7.24,
    avg_round_4: 167,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 3.77,
    avg_round_5: 163,
    app_flat_5: 0,
    char_6: 'miyabi',
    app_rate_6: 2.71,
    avg_round_6: 160,
    app_flat_6: 0,
    char_7: 'nicole-demara',
    app_rate_7: 1.51,
    avg_round_7: 150,
    app_flat_7: 0,
    char_8: 'burnice',
    app_rate_8: 1.36,
    avg_round_8: 194,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 0.75,
    avg_round_9: 202,
    app_flat_9: 0,
    char_10: 'zhu-yuan',
    app_rate_10: 0.6,
    avg_round_10: 164,
    app_flat_10: 0,
    char_11: 'lighter',
    app_rate_11: 0.3,
    avg_round_11: 125,
    app_flat_11: 0,
    char_12: 'yanagi',
    app_rate_12: 0.3,
    avg_round_12: 147,
    app_flat_12: 0,
    char_13: 'ellen',
    app_rate_13: 0.3,
    avg_round_13: 197,
    app_flat_13: 0,
    char_14: 'soukaku',
    app_rate_14: 0.3,
    avg_round_14: 197,
    app_flat_14: 0,
    char_15: 'rina',
    app_rate_15: 0.3,
    avg_round_15: 600,
    app_flat_15: 0,
    char_16: 'ben',
    app_rate_16: 0.3,
    avg_round_16: 172,
    app_flat_16: 0,
    char_17: 'piper',
    app_rate_17: 0.15,
    avg_round_17: 158,
    app_flat_17: 0,
    char_18: 'pulchra',
    app_rate_18: 0.15,
    avg_round_18: 210,
    app_flat_18: 0,
    char_19: 'corin',
    app_rate_19: 0.15,
    avg_round_19: 174,
    app_flat_19: 0,
    char_20: 'harumasa',
    app_rate_20: 0.15,
    avg_round_20: 600,
    app_flat_20: 0,
    char_21: 'nekomata',
    app_rate_21: 0.15,
    avg_round_21: 264,
    app_flat_21: 0,
    char_22: 'anby-demara-soldier-0',
    app_rate_22: 0.15,
    avg_round_22: 172,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lycaon',
    app: 12.27,
    char_1: 'miyabi',
    app_rate_1: 83.05,
    avg_round_1: 132,
    app_flat_1: 0,
    char_2: 'soukaku',
    app_rate_2: 66.78,
    avg_round_2: 141,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 16.78,
    avg_round_3: 132,
    app_flat_3: 0,
    char_4: 'ellen',
    app_rate_4: 12.42,
    avg_round_4: 191,
    app_flat_4: 0,
    char_5: 'yanagi',
    app_rate_5: 3.86,
    avg_round_5: 133,
    app_flat_5: 0,
    char_6: 'nicole-demara',
    app_rate_6: 3.86,
    avg_round_6: 143,
    app_flat_6: 0,
    char_7: 'rina',
    app_rate_7: 3.69,
    avg_round_7: 161,
    app_flat_7: 0,
    char_8: 'caesar',
    app_rate_8: 3.19,
    avg_round_8: 164,
    app_flat_8: 0,
    char_9: 'harumasa',
    app_rate_9: 2.52,
    avg_round_9: 186,
    app_flat_9: 0,
    char_10: 'evelyn',
    app_rate_10: 1.34,
    avg_round_10: 154,
    app_flat_10: 0,
    char_11: 'lighter',
    app_rate_11: 1.01,
    avg_round_11: 143,
    app_flat_11: 0,
    char_12: 'lucy',
    app_rate_12: 0.84,
    avg_round_12: 188,
    app_flat_12: 0,
    char_13: 'anby-demara-soldier-0',
    app_rate_13: 0.67,
    avg_round_13: 171,
    app_flat_13: 0,
    char_14: 'corin',
    app_rate_14: 0.34,
    avg_round_14: 182,
    app_flat_14: 0,
    char_15: 'burnice',
    app_rate_15: 0.34,
    avg_round_15: 140,
    app_flat_15: 0,
    char_16: 'zhu-yuan',
    app_rate_16: 0.17,
    avg_round_16: 600,
    app_flat_16: 0,
    char_17: 'soldier-11',
    app_rate_17: 0.17,
    avg_round_17: 281,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'pulchra',
    app: 9.18,
    char_1: 'anby-demara-soldier-0',
    app_rate_1: 80.72,
    avg_round_1: 140,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 40.81,
    avg_round_2: 126,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 25.34,
    avg_round_3: 149,
    app_flat_3: 0,
    char_4: 'lucy',
    app_rate_4: 9.64,
    avg_round_4: 165,
    app_flat_4: 0,
    char_5: 'rina',
    app_rate_5: 8.74,
    avg_round_5: 159,
    app_flat_5: 0,
    char_6: 'evelyn',
    app_rate_6: 8.07,
    avg_round_6: 140,
    app_flat_6: 0,
    char_7: 'nicole-demara',
    app_rate_7: 7.85,
    avg_round_7: 153,
    app_flat_7: 0,
    char_8: 'burnice',
    app_rate_8: 4.93,
    avg_round_8: 194,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 3.36,
    avg_round_9: 196,
    app_flat_9: 0,
    char_10: 'harumasa',
    app_rate_10: 1.57,
    avg_round_10: 211,
    app_flat_10: 0,
    char_11: 'piper',
    app_rate_11: 1.35,
    avg_round_11: 188,
    app_flat_11: 0,
    char_12: 'miyabi',
    app_rate_12: 1.35,
    avg_round_12: 167,
    app_flat_12: 0,
    char_13: 'nekomata',
    app_rate_13: 1.12,
    avg_round_13: 161,
    app_flat_13: 0,
    char_14: 'soldier-11',
    app_rate_14: 1.12,
    avg_round_14: 147,
    app_flat_14: 0,
    char_15: 'qingyi',
    app_rate_15: 1.12,
    avg_round_15: 179,
    app_flat_15: 0,
    char_16: 'yanagi',
    app_rate_16: 1.12,
    avg_round_16: 155,
    app_flat_16: 0,
    char_17: 'corin',
    app_rate_17: 0.45,
    avg_round_17: 268,
    app_flat_17: 0,
    char_18: 'lighter',
    app_rate_18: 0.22,
    avg_round_18: 140,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.22,
    avg_round_19: 191,
    app_flat_19: 0,
    char_20: 'koleda',
    app_rate_20: 0.22,
    avg_round_20: 210,
    app_flat_20: 0,
    char_21: 'ben',
    app_rate_21: 0.22,
    avg_round_21: 288,
    app_flat_21: 0,
    char_22: 'ellen',
    app_rate_22: 0.22,
    avg_round_22: 137,
    app_flat_22: 0,
    char_23: 'seth',
    app_rate_23: 0.22,
    avg_round_23: 600,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'piper',
    app: 7.99,
    char_1: 'burnice',
    app_rate_1: 71.13,
    avg_round_1: 136,
    app_flat_1: 0,
    char_2: 'lucy',
    app_rate_2: 64.95,
    avg_round_2: 140,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 21.39,
    avg_round_3: 155,
    app_flat_3: 0,
    char_4: 'miyabi',
    app_rate_4: 19.85,
    avg_round_4: 139,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 9.79,
    avg_round_5: 115,
    app_flat_5: 0,
    char_6: 'yanagi',
    app_rate_6: 1.8,
    avg_round_6: 180,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 1.8,
    avg_round_7: 227,
    app_flat_7: 0,
    char_8: 'pulchra',
    app_rate_8: 1.55,
    avg_round_8: 188,
    app_flat_8: 0,
    char_9: 'soukaku',
    app_rate_9: 1.55,
    avg_round_9: 152,
    app_flat_9: 0,
    char_10: 'nekomata',
    app_rate_10: 1.29,
    avg_round_10: 208,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 1.29,
    avg_round_11: 150,
    app_flat_11: 0,
    char_12: 'soldier-11',
    app_rate_12: 0.77,
    avg_round_12: 170,
    app_flat_12: 0,
    char_13: 'lighter',
    app_rate_13: 0.77,
    avg_round_13: 223,
    app_flat_13: 0,
    char_14: 'ben',
    app_rate_14: 0.52,
    avg_round_14: 134,
    app_flat_14: 0,
    char_15: 'koleda',
    app_rate_15: 0.26,
    avg_round_15: 158,
    app_flat_15: 0,
    char_16: 'evelyn',
    app_rate_16: 0.26,
    avg_round_16: 237,
    app_flat_16: 0,
    char_17: 'ellen',
    app_rate_17: 0.26,
    avg_round_17: 273,
    app_flat_17: 0,
    char_18: 'corin',
    app_rate_18: 0.26,
    avg_round_18: 600,
    app_flat_18: 0,
    char_19: 'qingyi',
    app_rate_19: 0.26,
    avg_round_19: 600,
    app_flat_19: 0,
    char_20: 'seth',
    app_rate_20: 0.26,
    avg_round_20: 296,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soldier-11',
    app: 7.35,
    char_1: 'lucy',
    app_rate_1: 56.58,
    avg_round_1: 150,
    app_flat_1: 0,
    char_2: 'lighter',
    app_rate_2: 35.85,
    avg_round_2: 128,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 29.97,
    avg_round_3: 144,
    app_flat_3: 0,
    char_4: 'astra-yao',
    app_rate_4: 26.61,
    avg_round_4: 133,
    app_flat_4: 0,
    char_5: 'burnice',
    app_rate_5: 20.45,
    avg_round_5: 161,
    app_flat_5: 0,
    char_6: 'koleda',
    app_rate_6: 13.45,
    avg_round_6: 167,
    app_flat_6: 0,
    char_7: 'evelyn',
    app_rate_7: 4.2,
    avg_round_7: 163,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 2.8,
    avg_round_8: 151,
    app_flat_8: 0,
    char_9: 'qingyi',
    app_rate_9: 2.8,
    avg_round_9: 148,
    app_flat_9: 0,
    char_10: 'miyabi',
    app_rate_10: 1.68,
    avg_round_10: 183,
    app_flat_10: 0,
    char_11: 'pulchra',
    app_rate_11: 1.4,
    avg_round_11: 147,
    app_flat_11: 0,
    char_12: 'ben',
    app_rate_12: 0.84,
    avg_round_12: 158,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 0.84,
    avg_round_13: 199,
    app_flat_13: 0,
    char_14: 'piper',
    app_rate_14: 0.84,
    avg_round_14: 170,
    app_flat_14: 0,
    char_15: 'yanagi',
    app_rate_15: 0.28,
    avg_round_15: 600,
    app_flat_15: 0,
    char_16: 'soukaku',
    app_rate_16: 0.28,
    avg_round_16: 600,
    app_flat_16: 0,
    char_17: 'grace-howard',
    app_rate_17: 0.28,
    avg_round_17: 230,
    app_flat_17: 0,
    char_18: 'lycaon',
    app_rate_18: 0.28,
    avg_round_18: 281,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'zhu-yuan',
    app: 5.37,
    char_1: 'nicole-demara',
    app_rate_1: 84.29,
    avg_round_1: 155,
    app_flat_1: 0,
    char_2: 'qingyi',
    app_rate_2: 81.23,
    avg_round_2: 157,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 21.46,
    avg_round_3: 137,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 4.6,
    avg_round_4: 174,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 1.53,
    avg_round_5: 166,
    app_flat_5: 0,
    char_6: 'koleda',
    app_rate_6: 1.53,
    avg_round_6: 164,
    app_flat_6: 0,
    char_7: 'miyabi',
    app_rate_7: 1.15,
    avg_round_7: 106,
    app_flat_7: 0,
    char_8: 'rina',
    app_rate_8: 0.77,
    avg_round_8: 193,
    app_flat_8: 0,
    char_9: 'yanagi',
    app_rate_9: 0.77,
    avg_round_9: 159,
    app_flat_9: 0,
    char_10: 'seth',
    app_rate_10: 0.38,
    avg_round_10: 192,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 0.38,
    avg_round_11: 600,
    app_flat_11: 0,
    char_12: 'lycaon',
    app_rate_12: 0.38,
    avg_round_12: 600,
    app_flat_12: 0,
    char_13: 'lucy',
    app_rate_13: 0.38,
    avg_round_13: 198,
    app_flat_13: 0,
    char_14: 'lighter',
    app_rate_14: 0.38,
    avg_round_14: 162,
    app_flat_14: 0,
    char_15: 'anby-demara-soldier-0',
    app_rate_15: 0.38,
    avg_round_15: 184,
    app_flat_15: 0,
    char_16: 'burnice',
    app_rate_16: 0.38,
    avg_round_16: 169,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'seth',
    app: 4.71,
    char_1: 'jane-doe',
    app_rate_1: 56.77,
    avg_round_1: 188,
    app_flat_1: 0,
    char_2: 'yanagi',
    app_rate_2: 36.68,
    avg_round_2: 157,
    app_flat_2: 0,
    char_3: 'rina',
    app_rate_3: 26.64,
    avg_round_3: 162,
    app_flat_3: 0,
    char_4: 'burnice',
    app_rate_4: 17.47,
    avg_round_4: 168,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 15.28,
    avg_round_5: 142,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 13.1,
    avg_round_6: 194,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 10.04,
    avg_round_7: 212,
    app_flat_7: 0,
    char_8: 'miyabi',
    app_rate_8: 8.3,
    avg_round_8: 157,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 4.37,
    avg_round_9: 188,
    app_flat_9: 0,
    char_10: 'harumasa',
    app_rate_10: 2.62,
    avg_round_10: 236,
    app_flat_10: 0,
    char_11: 'qingyi',
    app_rate_11: 2.62,
    avg_round_11: 250,
    app_flat_11: 0,
    char_12: 'anby-demara-soldier-0',
    app_rate_12: 1.75,
    avg_round_12: 168,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 1.31,
    avg_round_13: 273,
    app_flat_13: 0,
    char_14: 'soukaku',
    app_rate_14: 0.87,
    avg_round_14: 133,
    app_flat_14: 0,
    char_15: 'zhu-yuan',
    app_rate_15: 0.44,
    avg_round_15: 192,
    app_flat_15: 0,
    char_16: 'corin',
    app_rate_16: 0.44,
    avg_round_16: 600,
    app_flat_16: 0,
    char_17: 'anby-demara',
    app_rate_17: 0.44,
    avg_round_17: 233,
    app_flat_17: 0,
    char_18: 'pulchra',
    app_rate_18: 0.44,
    avg_round_18: 600,
    app_flat_18: 0,
    char_19: 'piper',
    app_rate_19: 0.44,
    avg_round_19: 296,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara',
    app: 3.11,
    char_1: 'harumasa',
    app_rate_1: 44.37,
    avg_round_1: 182,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 37.09,
    avg_round_2: 151,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 33.77,
    avg_round_3: 169,
    app_flat_3: 0,
    char_4: 'anby-demara-soldier-0',
    app_rate_4: 27.15,
    avg_round_4: 167,
    app_flat_4: 0,
    char_5: 'rina',
    app_rate_5: 20.53,
    avg_round_5: 191,
    app_flat_5: 0,
    char_6: 'miyabi',
    app_rate_6: 8.61,
    avg_round_6: 158,
    app_flat_6: 0,
    char_7: 'evelyn',
    app_rate_7: 7.95,
    avg_round_7: 134,
    app_flat_7: 0,
    char_8: 'caesar',
    app_rate_8: 6.62,
    avg_round_8: 203,
    app_flat_8: 0,
    char_9: 'yanagi',
    app_rate_9: 5.96,
    avg_round_9: 173,
    app_flat_9: 0,
    char_10: 'zhu-yuan',
    app_rate_10: 2.65,
    avg_round_10: 166,
    app_flat_10: 0,
    char_11: 'anton',
    app_rate_11: 1.99,
    avg_round_11: 181,
    app_flat_11: 0,
    char_12: 'nekomata',
    app_rate_12: 1.99,
    avg_round_12: 161,
    app_flat_12: 0,
    char_13: 'lucy',
    app_rate_13: 1.32,
    avg_round_13: 194,
    app_flat_13: 0,
    char_14: 'soukaku',
    app_rate_14: 1.32,
    avg_round_14: 10,
    app_flat_14: 0,
    char_15: 'burnice',
    app_rate_15: 0.66,
    avg_round_15: 600,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 0.66,
    avg_round_16: 233,
    app_flat_16: 0,
    char_17: 'seth',
    app_rate_17: 0.66,
    avg_round_17: 233,
    app_flat_17: 0,
    char_18: 'ellen',
    app_rate_18: 0.66,
    avg_round_18: 249,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.66,
    avg_round_19: 231,
    app_flat_19: 0,
    char_20: 'grace-howard',
    app_rate_20: 0.66,
    avg_round_20: 600,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'ellen',
    app: 2.72,
    char_1: 'lycaon',
    app_rate_1: 56.06,
    avg_round_1: 191,
    app_flat_1: 0,
    char_2: 'soukaku',
    app_rate_2: 45.45,
    avg_round_2: 207,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 30.3,
    avg_round_3: 172,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 26.52,
    avg_round_4: 162,
    app_flat_4: 0,
    char_5: 'lighter',
    app_rate_5: 15.91,
    avg_round_5: 162,
    app_flat_5: 0,
    char_6: 'miyabi',
    app_rate_6: 6.82,
    avg_round_6: 175,
    app_flat_6: 0,
    char_7: 'rina',
    app_rate_7: 6.06,
    avg_round_7: 120,
    app_flat_7: 0,
    char_8: 'qingyi',
    app_rate_8: 5.3,
    avg_round_8: 218,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 2.27,
    avg_round_9: 193,
    app_flat_9: 0,
    char_10: 'koleda',
    app_rate_10: 1.52,
    avg_round_10: 197,
    app_flat_10: 0,
    char_11: 'lucy',
    app_rate_11: 0.76,
    avg_round_11: 141,
    app_flat_11: 0,
    char_12: 'pulchra',
    app_rate_12: 0.76,
    avg_round_12: 137,
    app_flat_12: 0,
    char_13: 'yanagi',
    app_rate_13: 0.76,
    avg_round_13: 600,
    app_flat_13: 0,
    char_14: 'piper',
    app_rate_14: 0.76,
    avg_round_14: 273,
    app_flat_14: 0,
    char_15: 'evelyn',
    app_rate_15: 0.76,
    avg_round_15: 600,
    app_flat_15: 0,
    char_16: 'anby-demara',
    app_rate_16: 0.76,
    avg_round_16: 249,
    app_flat_16: 0,
    char_17: 'burnice',
    app_rate_17: 0.76,
    avg_round_17: 600,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'grace-howard',
    app: 2.33,
    char_1: 'miyabi',
    app_rate_1: 64.6,
    avg_round_1: 136,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 45.13,
    avg_round_2: 150,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 25.66,
    avg_round_3: 123,
    app_flat_3: 0,
    char_4: 'harumasa',
    app_rate_4: 22.12,
    avg_round_4: 172,
    app_flat_4: 0,
    char_5: 'soukaku',
    app_rate_5: 12.39,
    avg_round_5: 142,
    app_flat_5: 0,
    char_6: 'yanagi',
    app_rate_6: 10.62,
    avg_round_6: 193,
    app_flat_6: 0,
    char_7: 'qingyi',
    app_rate_7: 3.54,
    avg_round_7: 165,
    app_flat_7: 0,
    char_8: 'burnice',
    app_rate_8: 2.65,
    avg_round_8: 169,
    app_flat_8: 0,
    char_9: 'seth',
    app_rate_9: 2.65,
    avg_round_9: 273,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 2.65,
    avg_round_10: 138,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 2.65,
    avg_round_11: 279,
    app_flat_11: 0,
    char_12: 'anton',
    app_rate_12: 0.88,
    avg_round_12: 182,
    app_flat_12: 0,
    char_13: 'caesar',
    app_rate_13: 0.88,
    avg_round_13: 177,
    app_flat_13: 0,
    char_14: 'anby-demara-soldier-0',
    app_rate_14: 0.88,
    avg_round_14: 158,
    app_flat_14: 0,
    char_15: 'soldier-11',
    app_rate_15: 0.88,
    avg_round_15: 230,
    app_flat_15: 0,
    char_16: 'lucy',
    app_rate_16: 0.88,
    avg_round_16: 230,
    app_flat_16: 0,
    char_17: 'anby-demara',
    app_rate_17: 0.88,
    avg_round_17: 600,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'ben',
    app: 0.56,
    char_1: 'lucy',
    app_rate_1: 62.96,
    avg_round_1: 145,
    app_flat_1: 0,
    char_2: 'lighter',
    app_rate_2: 29.63,
    avg_round_2: 105,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 22.22,
    avg_round_3: 114,
    app_flat_3: 0,
    char_4: 'burnice',
    app_rate_4: 18.52,
    avg_round_4: 127,
    app_flat_4: 0,
    char_5: 'evelyn',
    app_rate_5: 18.52,
    avg_round_5: 140,
    app_flat_5: 0,
    char_6: 'soldier-11',
    app_rate_6: 11.11,
    avg_round_6: 158,
    app_flat_6: 0,
    char_7: 'miyabi',
    app_rate_7: 7.41,
    avg_round_7: 122,
    app_flat_7: 0,
    char_8: 'piper',
    app_rate_8: 7.41,
    avg_round_8: 134,
    app_flat_8: 0,
    char_9: 'caesar',
    app_rate_9: 7.41,
    avg_round_9: 231,
    app_flat_9: 0,
    char_10: 'koleda',
    app_rate_10: 7.41,
    avg_round_10: 172,
    app_flat_10: 0,
    char_11: 'pulchra',
    app_rate_11: 3.7,
    avg_round_11: 288,
    app_flat_11: 0,
    char_12: 'nicole-demara',
    app_rate_12: 3.7,
    avg_round_12: 97,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nekomata',
    app: 0.49,
    char_1: 'astra-yao',
    app_rate_1: 50.0,
    avg_round_1: 182,
    app_flat_1: 0,
    char_2: 'nicole-demara',
    app_rate_2: 33.33,
    avg_round_2: 158,
    app_flat_2: 0,
    char_3: 'pulchra',
    app_rate_3: 20.83,
    avg_round_3: 161,
    app_flat_3: 0,
    char_4: 'qingyi',
    app_rate_4: 20.83,
    avg_round_4: 185,
    app_flat_4: 0,
    char_5: 'piper',
    app_rate_5: 20.83,
    avg_round_5: 208,
    app_flat_5: 0,
    char_6: 'anby-demara',
    app_rate_6: 12.5,
    avg_round_6: 161,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 12.5,
    avg_round_7: 239,
    app_flat_7: 0,
    char_8: 'burnice',
    app_rate_8: 12.5,
    avg_round_8: 201,
    app_flat_8: 0,
    char_9: 'caesar',
    app_rate_9: 8.33,
    avg_round_9: 186,
    app_flat_9: 0,
    char_10: 'evelyn',
    app_rate_10: 8.33,
    avg_round_10: 259,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 4.17,
    avg_round_11: 196,
    app_flat_11: 0,
    char_12: 'koleda',
    app_rate_12: 4.17,
    avg_round_12: 264,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'corin',
    app: 0.43,
    char_1: 'qingyi',
    app_rate_1: 38.1,
    avg_round_1: 157,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 33.33,
    avg_round_2: 124,
    app_flat_2: 0,
    char_3: 'rina',
    app_rate_3: 28.57,
    avg_round_3: 163,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 23.81,
    avg_round_4: 152,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 14.29,
    avg_round_5: 132,
    app_flat_5: 0,
    char_6: 'pulchra',
    app_rate_6: 9.52,
    avg_round_6: 268,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 9.52,
    avg_round_7: 268,
    app_flat_7: 0,
    char_8: 'lycaon',
    app_rate_8: 9.52,
    avg_round_8: 182,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 4.76,
    avg_round_9: 600,
    app_flat_9: 0,
    char_10: 'seth',
    app_rate_10: 4.76,
    avg_round_10: 600,
    app_flat_10: 0,
    char_11: 'burnice',
    app_rate_11: 4.76,
    avg_round_11: 219,
    app_flat_11: 0,
    char_12: 'lighter',
    app_rate_12: 4.76,
    avg_round_12: 219,
    app_flat_12: 0,
    char_13: 'koleda',
    app_rate_13: 4.76,
    avg_round_13: 174,
    app_flat_13: 0,
    char_14: 'miyabi',
    app_rate_14: 4.76,
    avg_round_14: 600,
    app_flat_14: 0,
    char_15: 'piper',
    app_rate_15: 4.76,
    avg_round_15: 600,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anton',
    app: 0.41,
    char_1: 'qingyi',
    app_rate_1: 50.0,
    avg_round_1: 145,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 45.0,
    avg_round_2: 165,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 45.0,
    avg_round_3: 142,
    app_flat_3: 0,
    char_4: 'astra-yao',
    app_rate_4: 25.0,
    avg_round_4: 134,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 15.0,
    avg_round_5: 190,
    app_flat_5: 0,
    char_6: 'anby-demara',
    app_rate_6: 15.0,
    avg_round_6: 181,
    app_flat_6: 0,
    char_7: 'grace-howard',
    app_rate_7: 5.0,
    avg_round_7: 182,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'billy-kid',
    app: 0.27,
    char_1: 'astra-yao',
    app_rate_1: 61.54,
    avg_round_1: 128,
    app_flat_1: 0,
    char_2: 'nicole-demara',
    app_rate_2: 53.85,
    avg_round_2: 125,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 46.15,
    avg_round_3: 166,
    app_flat_3: 0,
    char_4: 'pulchra',
    app_rate_4: 7.69,
    avg_round_4: 191,
    app_flat_4: 0,
    char_5: 'qingyi',
    app_rate_5: 7.69,
    avg_round_5: 139,
    app_flat_5: 0,
    char_6: 'lucy',
    app_rate_6: 7.69,
    avg_round_6: 203,
    app_flat_6: 0,
    char_7: 'anby-demara-soldier-0',
    app_rate_7: 7.69,
    avg_round_7: 231,
    app_flat_7: 0,
    char_8: 'anby-demara',
    app_rate_8: 7.69,
    avg_round_8: 231,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
