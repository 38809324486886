export const ZZZDeadlyDuos = [
  {
    char: 'miyabi',
    app: 90.37,
    char_1: 'soukaku',
    app_rate_1: 39.68,
    avg_round_1: 24836,
    app_flat_1: 0,
    char_2: 'yanagi',
    app_rate_2: 31.89,
    avg_round_2: 29537,
    app_flat_2: 0,
    char_3: 'lycaon',
    app_rate_3: 25.11,
    avg_round_3: 25344,
    app_flat_3: 0,
    char_4: 'astra-yao',
    app_rate_4: 17.52,
    avg_round_4: 32940,
    app_flat_4: 0,
    char_5: 'lucy',
    app_rate_5: 16.72,
    avg_round_5: 28011,
    app_flat_5: 0,
    char_6: 'rina',
    app_rate_6: 15.57,
    avg_round_6: 28672,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 12.57,
    avg_round_7: 30133,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 9.9,
    avg_round_8: 29391,
    app_flat_8: 0,
    char_9: 'caesar',
    app_rate_9: 8.9,
    avg_round_9: 25234,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 3.0,
    avg_round_10: 25286,
    app_flat_10: 0,
    char_11: 'piper',
    app_rate_11: 2.9,
    avg_round_11: 22971,
    app_flat_11: 0,
    char_12: 'seth',
    app_rate_12: 1.85,
    avg_round_12: 22598,
    app_flat_12: 0,
    char_13: 'lighter',
    app_rate_13: 1.83,
    avg_round_13: 24175,
    app_flat_13: 0,
    char_14: 'ellen',
    app_rate_14: 1.76,
    avg_round_14: 18502,
    app_flat_14: 0,
    char_15: 'harumasa',
    app_rate_15: 1.18,
    avg_round_15: 19803,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 0.99,
    avg_round_16: 22218,
    app_flat_16: 0,
    char_17: 'anby-demara',
    app_rate_17: 0.52,
    avg_round_17: 21811,
    app_flat_17: 0,
    char_18: 'qingyi',
    app_rate_18: 0.48,
    avg_round_18: 21905,
    app_flat_18: 0,
    char_19: 'koleda',
    app_rate_19: 0.42,
    avg_round_19: 20412,
    app_flat_19: 0,
    char_20: 'evelyn',
    app_rate_20: 0.29,
    avg_round_20: 20901,
    app_flat_20: 0,
    char_21: 'soldier-11',
    app_rate_21: 0.19,
    avg_round_21: 18228,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.15,
    avg_round_22: 17354,
    app_flat_22: 0,
    char_23: 'pulchra',
    app_rate_23: 0.13,
    avg_round_23: 18636,
    app_flat_23: 0,
    char_24: 'ben',
    app_rate_24: 0.1,
    avg_round_24: 19271,
    app_flat_24: 0,
    char_25: 'nekomata',
    app_rate_25: 0.04,
    avg_round_25: 23279,
    app_flat_25: 0,
    char_26: 'anby-demara-soldier-0',
    app_rate_26: 0.04,
    avg_round_26: 15324,
    app_flat_26: 0,
    char_27: 'billy-kid',
    app_rate_27: 0.02,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'corin',
    app_rate_28: 0.02,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'astra-yao',
    app: 81.13,
    char_1: 'evelyn',
    app_rate_1: 44.22,
    avg_round_1: 24181,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 19.51,
    avg_round_2: 32940,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 16.54,
    avg_round_3: 29705,
    app_flat_3: 0,
    char_4: 'koleda',
    app_rate_4: 14.15,
    avg_round_4: 21487,
    app_flat_4: 0,
    char_5: 'lighter',
    app_rate_5: 12.32,
    avg_round_5: 28424,
    app_flat_5: 0,
    char_6: 'qingyi',
    app_rate_6: 10.17,
    avg_round_6: 24207,
    app_flat_6: 0,
    char_7: 'yanagi',
    app_rate_7: 10.17,
    avg_round_7: 32053,
    app_flat_7: 0,
    char_8: 'caesar',
    app_rate_8: 8.36,
    avg_round_8: 25262,
    app_flat_8: 0,
    char_9: 'burnice',
    app_rate_9: 8.04,
    avg_round_9: 23558,
    app_flat_9: 0,
    char_10: 'zhu-yuan',
    app_rate_10: 7.09,
    avg_round_10: 27765,
    app_flat_10: 0,
    char_11: 'anby-demara-soldier-0',
    app_rate_11: 6.51,
    avg_round_11: 26170,
    app_flat_11: 0,
    char_12: 'harumasa',
    app_rate_12: 6.04,
    avg_round_12: 23614,
    app_flat_12: 0,
    char_13: 'lucy',
    app_rate_13: 5.47,
    avg_round_13: 22160,
    app_flat_13: 0,
    char_14: 'jane-doe',
    app_rate_14: 4.58,
    avg_round_14: 21631,
    app_flat_14: 0,
    char_15: 'lycaon',
    app_rate_15: 4.43,
    avg_round_15: 24367,
    app_flat_15: 0,
    char_16: 'soldier-11',
    app_rate_16: 4.11,
    avg_round_16: 20665,
    app_flat_16: 0,
    char_17: 'ellen',
    app_rate_17: 3.58,
    avg_round_17: 19786,
    app_flat_17: 0,
    char_18: 'soukaku',
    app_rate_18: 3.17,
    avg_round_18: 27150,
    app_flat_18: 0,
    char_19: 'piper',
    app_rate_19: 2.26,
    avg_round_19: 21992,
    app_flat_19: 0,
    char_20: 'anby-demara',
    app_rate_20: 2.13,
    avg_round_20: 20551,
    app_flat_20: 0,
    char_21: 'pulchra',
    app_rate_21: 1.85,
    avg_round_21: 23475,
    app_flat_21: 0,
    char_22: 'rina',
    app_rate_22: 1.83,
    avg_round_22: 25328,
    app_flat_22: 0,
    char_23: 'seth',
    app_rate_23: 1.51,
    avg_round_23: 18780,
    app_flat_23: 0,
    char_24: 'grace-howard',
    app_rate_24: 0.98,
    avg_round_24: 26470,
    app_flat_24: 0,
    char_25: 'nekomata',
    app_rate_25: 0.64,
    avg_round_25: 20145,
    app_flat_25: 0,
    char_26: 'ben',
    app_rate_26: 0.32,
    avg_round_26: 21611,
    app_flat_26: 0,
    char_27: 'anton',
    app_rate_27: 0.3,
    avg_round_27: 26270,
    app_flat_27: 0,
    char_28: 'billy-kid',
    app_rate_28: 0.19,
    avg_round_28: 16926,
    app_flat_28: 0,
    char_29: 'corin',
    app_rate_29: 0.09,
    avg_round_29: 28822,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nicole-demara',
    app: 54.61,
    char_1: 'zhu-yuan',
    app_rate_1: 47.64,
    avg_round_1: 23331,
    app_flat_1: 0,
    char_2: 'qingyi',
    app_rate_2: 45.65,
    avg_round_2: 23089,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 24.57,
    avg_round_3: 29705,
    app_flat_3: 0,
    char_4: 'miyabi',
    app_rate_4: 16.38,
    avg_round_4: 29391,
    app_flat_4: 0,
    char_5: 'harumasa',
    app_rate_5: 11.38,
    avg_round_5: 20400,
    app_flat_5: 0,
    char_6: 'evelyn',
    app_rate_6: 7.97,
    avg_round_6: 23917,
    app_flat_6: 0,
    char_7: 'anby-demara',
    app_rate_7: 7.78,
    avg_round_7: 16049,
    app_flat_7: 0,
    char_8: 'anby-demara-soldier-0',
    app_rate_8: 6.92,
    avg_round_8: 23801,
    app_flat_8: 0,
    char_9: 'yanagi',
    app_rate_9: 6.45,
    avg_round_9: 24726,
    app_flat_9: 0,
    char_10: 'caesar',
    app_rate_10: 3.76,
    avg_round_10: 22119,
    app_flat_10: 0,
    char_11: 'soukaku',
    app_rate_11: 3.16,
    avg_round_11: 21369,
    app_flat_11: 0,
    char_12: 'rina',
    app_rate_12: 2.62,
    avg_round_12: 23814,
    app_flat_12: 0,
    char_13: 'lucy',
    app_rate_13: 2.53,
    avg_round_13: 20219,
    app_flat_13: 0,
    char_14: 'lycaon',
    app_rate_14: 2.02,
    avg_round_14: 21174,
    app_flat_14: 0,
    char_15: 'burnice',
    app_rate_15: 1.68,
    avg_round_15: 24340,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 1.45,
    avg_round_16: 15058,
    app_flat_16: 0,
    char_17: 'nekomata',
    app_rate_17: 1.14,
    avg_round_17: 19494,
    app_flat_17: 0,
    char_18: 'pulchra',
    app_rate_18: 1.07,
    avg_round_18: 20449,
    app_flat_18: 0,
    char_19: 'seth',
    app_rate_19: 1.04,
    avg_round_19: 16282,
    app_flat_19: 0,
    char_20: 'ellen',
    app_rate_20: 0.82,
    avg_round_20: 18271,
    app_flat_20: 0,
    char_21: 'koleda',
    app_rate_21: 0.82,
    avg_round_21: 16038,
    app_flat_21: 0,
    char_22: 'grace-howard',
    app_rate_22: 0.7,
    avg_round_22: 15956,
    app_flat_22: 0,
    char_23: 'piper',
    app_rate_23: 0.66,
    avg_round_23: 18100,
    app_flat_23: 0,
    char_24: 'soldier-11',
    app_rate_24: 0.57,
    avg_round_24: 15819,
    app_flat_24: 0,
    char_25: 'lighter',
    app_rate_25: 0.47,
    avg_round_25: 16667,
    app_flat_25: 0,
    char_26: 'billy-kid',
    app_rate_26: 0.44,
    avg_round_26: 14978,
    app_flat_26: 0,
    char_27: 'anton',
    app_rate_27: 0.38,
    avg_round_27: 27480,
    app_flat_27: 0,
    char_28: 'corin',
    app_rate_28: 0.16,
    avg_round_28: 24735,
    app_flat_28: 0,
    char_29: 'ben',
    app_rate_29: 0.03,
    avg_round_29: 14522,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lucy',
    app: 52.18,
    char_1: 'burnice',
    app_rate_1: 40.11,
    avg_round_1: 21815,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 28.95,
    avg_round_2: 28011,
    app_flat_2: 0,
    char_3: 'evelyn',
    app_rate_3: 20.25,
    avg_round_3: 19610,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 15.22,
    avg_round_4: 16931,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 13.77,
    avg_round_5: 17434,
    app_flat_5: 0,
    char_6: 'piper',
    app_rate_6: 12.24,
    avg_round_6: 18527,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 11.71,
    avg_round_7: 18472,
    app_flat_7: 0,
    char_8: 'lighter',
    app_rate_8: 11.28,
    avg_round_8: 21265,
    app_flat_8: 0,
    char_9: 'yanagi',
    app_rate_9: 8.84,
    avg_round_9: 24797,
    app_flat_9: 0,
    char_10: 'astra-yao',
    app_rate_10: 8.5,
    avg_round_10: 22160,
    app_flat_10: 0,
    char_11: 'koleda',
    app_rate_11: 7.64,
    avg_round_11: 15468,
    app_flat_11: 0,
    char_12: 'anby-demara-soldier-0',
    app_rate_12: 2.81,
    avg_round_12: 19913,
    app_flat_12: 0,
    char_13: 'qingyi',
    app_rate_13: 2.65,
    avg_round_13: 17460,
    app_flat_13: 0,
    char_14: 'nicole-demara',
    app_rate_14: 2.65,
    avg_round_14: 20219,
    app_flat_14: 0,
    char_15: 'seth',
    app_rate_15: 2.12,
    avg_round_15: 14184,
    app_flat_15: 0,
    char_16: 'soukaku',
    app_rate_16: 1.85,
    avg_round_16: 23091,
    app_flat_16: 0,
    char_17: 'pulchra',
    app_rate_17: 1.56,
    avg_round_17: 17497,
    app_flat_17: 0,
    char_18: 'ben',
    app_rate_18: 1.22,
    avg_round_18: 16378,
    app_flat_18: 0,
    char_19: 'harumasa',
    app_rate_19: 1.19,
    avg_round_19: 17504,
    app_flat_19: 0,
    char_20: 'lycaon',
    app_rate_20: 1.16,
    avg_round_20: 17830,
    app_flat_20: 0,
    char_21: 'rina',
    app_rate_21: 1.16,
    avg_round_21: 23056,
    app_flat_21: 0,
    char_22: 'ellen',
    app_rate_22: 0.56,
    avg_round_22: 13696,
    app_flat_22: 0,
    char_23: 'nekomata',
    app_rate_23: 0.56,
    avg_round_23: 14253,
    app_flat_23: 0,
    char_24: 'zhu-yuan',
    app_rate_24: 0.53,
    avg_round_24: 20851,
    app_flat_24: 0,
    char_25: 'anby-demara',
    app_rate_25: 0.36,
    avg_round_25: 10838,
    app_flat_25: 0,
    char_26: 'grace-howard',
    app_rate_26: 0.26,
    avg_round_26: 18654,
    app_flat_26: 0,
    char_27: 'corin',
    app_rate_27: 0.23,
    avg_round_27: 12581,
    app_flat_27: 0,
    char_28: 'billy-kid',
    app_rate_28: 0.17,
    avg_round_28: 9806,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yanagi',
    app: 48.98,
    char_1: 'miyabi',
    app_rate_1: 58.83,
    avg_round_1: 29537,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 37.4,
    avg_round_2: 26109,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 16.85,
    avg_round_3: 32053,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 16.71,
    avg_round_4: 25036,
    app_flat_4: 0,
    char_5: 'burnice',
    app_rate_5: 15.86,
    avg_round_5: 23845,
    app_flat_5: 0,
    char_6: 'soukaku',
    app_rate_6: 9.8,
    avg_round_6: 25797,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 9.41,
    avg_round_7: 24797,
    app_flat_7: 0,
    char_8: 'seth',
    app_rate_8: 9.31,
    avg_round_8: 20489,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 7.19,
    avg_round_9: 24726,
    app_flat_9: 0,
    char_10: 'qingyi',
    app_rate_10: 3.88,
    avg_round_10: 18297,
    app_flat_10: 0,
    char_11: 'harumasa',
    app_rate_11: 3.49,
    avg_round_11: 17870,
    app_flat_11: 0,
    char_12: 'anby-demara',
    app_rate_12: 1.66,
    avg_round_12: 15964,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 1.55,
    avg_round_13: 18996,
    app_flat_13: 0,
    char_14: 'lycaon',
    app_rate_14: 1.37,
    avg_round_14: 25554,
    app_flat_14: 0,
    char_15: 'grace-howard',
    app_rate_15: 1.37,
    avg_round_15: 16664,
    app_flat_15: 0,
    char_16: 'piper',
    app_rate_16: 1.13,
    avg_round_16: 19616,
    app_flat_16: 0,
    char_17: 'zhu-yuan',
    app_rate_17: 0.63,
    avg_round_17: 14952,
    app_flat_17: 0,
    char_18: 'anby-demara-soldier-0',
    app_rate_18: 0.6,
    avg_round_18: 17516,
    app_flat_18: 0,
    char_19: 'ellen',
    app_rate_19: 0.35,
    avg_round_19: 18173,
    app_flat_19: 0,
    char_20: 'koleda',
    app_rate_20: 0.21,
    avg_round_20: 11576,
    app_flat_20: 0,
    char_21: 'evelyn',
    app_rate_21: 0.18,
    avg_round_21: 10615,
    app_flat_21: 0,
    char_22: 'lighter',
    app_rate_22: 0.07,
    avg_round_22: 11879,
    app_flat_22: 0,
    char_23: 'pulchra',
    app_rate_23: 0.07,
    avg_round_23: 11822,
    app_flat_23: 0,
    char_24: 'soldier-11',
    app_rate_24: 0.07,
    avg_round_24: 10702,
    app_flat_24: 0,
    char_25: 'corin',
    app_rate_25: 0.04,
    avg_round_25: 16474,
    app_flat_25: 0,
    char_26: 'nekomata',
    app_rate_26: 0.04,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'qingyi',
    app: 48.33,
    char_1: 'nicole-demara',
    app_rate_1: 51.59,
    avg_round_1: 23089,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 46.16,
    avg_round_2: 23414,
    app_flat_2: 0,
    char_3: 'harumasa',
    app_rate_3: 33.37,
    avg_round_3: 21875,
    app_flat_3: 0,
    char_4: 'rina',
    app_rate_4: 19.72,
    avg_round_4: 20627,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 17.08,
    avg_round_5: 24207,
    app_flat_5: 0,
    char_6: 'anby-demara-soldier-0',
    app_rate_6: 7.15,
    avg_round_6: 21969,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 4.75,
    avg_round_7: 20855,
    app_flat_7: 0,
    char_8: 'evelyn',
    app_rate_8: 4.29,
    avg_round_8: 19871,
    app_flat_8: 0,
    char_9: 'yanagi',
    app_rate_9: 3.93,
    avg_round_9: 18297,
    app_flat_9: 0,
    char_10: 'lucy',
    app_rate_10: 2.86,
    avg_round_10: 17460,
    app_flat_10: 0,
    char_11: 'ellen',
    app_rate_11: 1.39,
    avg_round_11: 15349,
    app_flat_11: 0,
    char_12: 'soukaku',
    app_rate_12: 1.32,
    avg_round_12: 16911,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 1.25,
    avg_round_13: 14208,
    app_flat_13: 0,
    char_14: 'miyabi',
    app_rate_14: 0.89,
    avg_round_14: 21905,
    app_flat_14: 0,
    char_15: 'anton',
    app_rate_15: 0.64,
    avg_round_15: 23065,
    app_flat_15: 0,
    char_16: 'soldier-11',
    app_rate_16: 0.64,
    avg_round_16: 13241,
    app_flat_16: 0,
    char_17: 'seth',
    app_rate_17: 0.57,
    avg_round_17: 14102,
    app_flat_17: 0,
    char_18: 'grace-howard',
    app_rate_18: 0.5,
    avg_round_18: 11212,
    app_flat_18: 0,
    char_19: 'corin',
    app_rate_19: 0.46,
    avg_round_19: 20215,
    app_flat_19: 0,
    char_20: 'nekomata',
    app_rate_20: 0.46,
    avg_round_20: 18802,
    app_flat_20: 0,
    char_21: 'burnice',
    app_rate_21: 0.46,
    avg_round_21: 13495,
    app_flat_21: 0,
    char_22: 'pulchra',
    app_rate_22: 0.18,
    avg_round_22: 20270,
    app_flat_22: 0,
    char_23: 'anby-demara',
    app_rate_23: 0.11,
    avg_round_23: 8170,
    app_flat_23: 0,
    char_24: 'billy-kid',
    app_rate_24: 0.11,
    avg_round_24: 20136,
    app_flat_24: 0,
    char_25: 'piper',
    app_rate_25: 0.07,
    avg_round_25: 9308,
    app_flat_25: 0,
    char_26: 'lycaon',
    app_rate_26: 0.04,
    avg_round_26: 20628,
    app_flat_26: 0,
    char_27: 'koleda',
    app_rate_27: 0.04,
    avg_round_27: 6466,
    app_flat_27: 0,
    char_28: 'lighter',
    app_rate_28: 0.04,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'evelyn',
    app: 46.93,
    char_1: 'astra-yao',
    app_rate_1: 76.45,
    avg_round_1: 24181,
    app_flat_1: 0,
    char_2: 'koleda',
    app_rate_2: 28.73,
    avg_round_2: 20421,
    app_flat_2: 0,
    char_3: 'lighter',
    app_rate_3: 25.83,
    avg_round_3: 26835,
    app_flat_3: 0,
    char_4: 'lucy',
    app_rate_4: 22.52,
    avg_round_4: 19610,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 18.21,
    avg_round_5: 23005,
    app_flat_5: 0,
    char_6: 'nicole-demara',
    app_rate_6: 9.27,
    avg_round_6: 23917,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 7.4,
    avg_round_7: 18394,
    app_flat_7: 0,
    char_8: 'qingyi',
    app_rate_8: 4.42,
    avg_round_8: 19871,
    app_flat_8: 0,
    char_9: 'soldier-11',
    app_rate_9: 1.36,
    avg_round_9: 16495,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 0.96,
    avg_round_10: 18330,
    app_flat_10: 0,
    char_11: 'lycaon',
    app_rate_11: 0.92,
    avg_round_11: 15930,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 0.66,
    avg_round_12: 13053,
    app_flat_12: 0,
    char_13: 'ben',
    app_rate_13: 0.63,
    avg_round_13: 15941,
    app_flat_13: 0,
    char_14: 'miyabi',
    app_rate_14: 0.55,
    avg_round_14: 20901,
    app_flat_14: 0,
    char_15: 'rina',
    app_rate_15: 0.52,
    avg_round_15: 17912,
    app_flat_15: 0,
    char_16: 'pulchra',
    app_rate_16: 0.4,
    avg_round_16: 17818,
    app_flat_16: 0,
    char_17: 'soukaku',
    app_rate_17: 0.37,
    avg_round_17: 13515,
    app_flat_17: 0,
    char_18: 'seth',
    app_rate_18: 0.29,
    avg_round_18: 16361,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.22,
    avg_round_19: 14502,
    app_flat_19: 0,
    char_20: 'yanagi',
    app_rate_20: 0.18,
    avg_round_20: 10615,
    app_flat_20: 0,
    char_21: 'harumasa',
    app_rate_21: 0.15,
    avg_round_21: 18414,
    app_flat_21: 0,
    char_22: 'piper',
    app_rate_22: 0.15,
    avg_round_22: 34867,
    app_flat_22: 0,
    char_23: 'nekomata',
    app_rate_23: 0.07,
    avg_round_23: 6455,
    app_flat_23: 0,
    char_24: 'billy-kid',
    app_rate_24: 0.04,
    avg_round_24: 13158,
    app_flat_24: 0,
    char_25: 'grace-howard',
    app_rate_25: 0.04,
    avg_round_25: 13325,
    app_flat_25: 0,
    char_26: 'ellen',
    app_rate_26: 0.04,
    avg_round_26: 11615,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'burnice',
    app: 46.36,
    char_1: 'lucy',
    app_rate_1: 45.14,
    avg_round_1: 21815,
    app_flat_1: 0,
    char_2: 'jane-doe',
    app_rate_2: 30.54,
    avg_round_2: 18297,
    app_flat_2: 0,
    char_3: 'miyabi',
    app_rate_3: 24.51,
    avg_round_3: 30133,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 19.93,
    avg_round_4: 19148,
    app_flat_4: 0,
    char_5: 'yanagi',
    app_rate_5: 16.76,
    avg_round_5: 23845,
    app_flat_5: 0,
    char_6: 'piper',
    app_rate_6: 14.19,
    avg_round_6: 18603,
    app_flat_6: 0,
    char_7: 'astra-yao',
    app_rate_7: 14.08,
    avg_round_7: 23558,
    app_flat_7: 0,
    char_8: 'evelyn',
    app_rate_8: 7.49,
    avg_round_8: 18394,
    app_flat_8: 0,
    char_9: 'rina',
    app_rate_9: 4.66,
    avg_round_9: 24812,
    app_flat_9: 0,
    char_10: 'soldier-11',
    app_rate_10: 4.62,
    avg_round_10: 17130,
    app_flat_10: 0,
    char_11: 'seth',
    app_rate_11: 4.25,
    avg_round_11: 15667,
    app_flat_11: 0,
    char_12: 'soukaku',
    app_rate_12: 4.17,
    avg_round_12: 26612,
    app_flat_12: 0,
    char_13: 'lighter',
    app_rate_13: 2.98,
    avg_round_13: 19011,
    app_flat_13: 0,
    char_14: 'nicole-demara',
    app_rate_14: 1.97,
    avg_round_14: 24340,
    app_flat_14: 0,
    char_15: 'koleda',
    app_rate_15: 0.78,
    avg_round_15: 12246,
    app_flat_15: 0,
    char_16: 'grace-howard',
    app_rate_16: 0.67,
    avg_round_16: 18186,
    app_flat_16: 0,
    char_17: 'pulchra',
    app_rate_17: 0.56,
    avg_round_17: 15556,
    app_flat_17: 0,
    char_18: 'lycaon',
    app_rate_18: 0.52,
    avg_round_18: 16850,
    app_flat_18: 0,
    char_19: 'qingyi',
    app_rate_19: 0.48,
    avg_round_19: 13495,
    app_flat_19: 0,
    char_20: 'harumasa',
    app_rate_20: 0.37,
    avg_round_20: 16406,
    app_flat_20: 0,
    char_21: 'ellen',
    app_rate_21: 0.37,
    avg_round_21: 16367,
    app_flat_21: 0,
    char_22: 'anby-demara-soldier-0',
    app_rate_22: 0.34,
    avg_round_22: 12035,
    app_flat_22: 0,
    char_23: 'ben',
    app_rate_23: 0.22,
    avg_round_23: 18908,
    app_flat_23: 0,
    char_24: 'corin',
    app_rate_24: 0.15,
    avg_round_24: 17602,
    app_flat_24: 0,
    char_25: 'nekomata',
    app_rate_25: 0.11,
    avg_round_25: 15270,
    app_flat_25: 0,
    char_26: 'zhu-yuan',
    app_rate_26: 0.11,
    avg_round_26: 13807,
    app_flat_26: 0,
    char_27: 'anby-demara',
    app_rate_27: 0.04,
    avg_round_27: 17789,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soukaku',
    app: 42.09,
    char_1: 'miyabi',
    app_rate_1: 85.19,
    avg_round_1: 24836,
    app_flat_1: 0,
    char_2: 'lycaon',
    app_rate_2: 48.61,
    avg_round_2: 23265,
    app_flat_2: 0,
    char_3: 'ellen',
    app_rate_3: 14.48,
    avg_round_3: 14094,
    app_flat_3: 0,
    char_4: 'yanagi',
    app_rate_4: 11.4,
    avg_round_4: 25797,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 6.11,
    avg_round_5: 27150,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 5.87,
    avg_round_6: 19673,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 4.59,
    avg_round_7: 26612,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 4.1,
    avg_round_8: 21369,
    app_flat_8: 0,
    char_9: 'lucy',
    app_rate_9: 2.3,
    avg_round_9: 23091,
    app_flat_9: 0,
    char_10: 'rina',
    app_rate_10: 2.17,
    avg_round_10: 20251,
    app_flat_10: 0,
    char_11: 'qingyi',
    app_rate_11: 1.52,
    avg_round_11: 16911,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 1.39,
    avg_round_12: 23580,
    app_flat_12: 0,
    char_13: 'lighter',
    app_rate_13: 1.31,
    avg_round_13: 17308,
    app_flat_13: 0,
    char_14: 'piper',
    app_rate_14: 1.27,
    avg_round_14: 19771,
    app_flat_14: 0,
    char_15: 'seth',
    app_rate_15: 1.15,
    avg_round_15: 18344,
    app_flat_15: 0,
    char_16: 'harumasa',
    app_rate_16: 1.11,
    avg_round_16: 13782,
    app_flat_16: 0,
    char_17: 'koleda',
    app_rate_17: 0.74,
    avg_round_17: 13815,
    app_flat_17: 0,
    char_18: 'anby-demara',
    app_rate_18: 0.53,
    avg_round_18: 12894,
    app_flat_18: 0,
    char_19: 'jane-doe',
    app_rate_19: 0.53,
    avg_round_19: 17253,
    app_flat_19: 0,
    char_20: 'evelyn',
    app_rate_20: 0.41,
    avg_round_20: 13515,
    app_flat_20: 0,
    char_21: 'ben',
    app_rate_21: 0.21,
    avg_round_21: 13712,
    app_flat_21: 0,
    char_22: 'soldier-11',
    app_rate_22: 0.21,
    avg_round_22: 10996,
    app_flat_22: 0,
    char_23: 'pulchra',
    app_rate_23: 0.08,
    avg_round_23: 14141,
    app_flat_23: 0,
    char_24: 'anby-demara-soldier-0',
    app_rate_24: 0.08,
    avg_round_24: 11928,
    app_flat_24: 0,
    char_25: 'zhu-yuan',
    app_rate_25: 0.04,
    avg_round_25: 13040,
    app_flat_25: 0,
    char_26: 'nekomata',
    app_rate_26: 0.04,
    avg_round_26: 8339,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'caesar',
    app: 41.63,
    char_1: 'burnice',
    app_rate_1: 22.19,
    avg_round_1: 19148,
    app_flat_1: 0,
    char_2: 'evelyn',
    app_rate_2: 20.53,
    avg_round_2: 23005,
    app_flat_2: 0,
    char_3: 'yanagi',
    app_rate_3: 19.66,
    avg_round_3: 25036,
    app_flat_3: 0,
    char_4: 'miyabi',
    app_rate_4: 19.33,
    avg_round_4: 25234,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 16.3,
    avg_round_5: 25262,
    app_flat_5: 0,
    char_6: 'lucy',
    app_rate_6: 14.68,
    avg_round_6: 18472,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 13.98,
    avg_round_7: 17775,
    app_flat_7: 0,
    char_8: 'soldier-11',
    app_rate_8: 9.0,
    avg_round_8: 18182,
    app_flat_8: 0,
    char_9: 'anby-demara-soldier-0',
    app_rate_9: 7.26,
    avg_round_9: 23160,
    app_flat_9: 0,
    char_10: 'rina',
    app_rate_10: 6.22,
    avg_round_10: 22702,
    app_flat_10: 0,
    char_11: 'soukaku',
    app_rate_11: 5.93,
    avg_round_11: 19673,
    app_flat_11: 0,
    char_12: 'piper',
    app_rate_12: 5.77,
    avg_round_12: 16724,
    app_flat_12: 0,
    char_13: 'qingyi',
    app_rate_13: 5.52,
    avg_round_13: 20855,
    app_flat_13: 0,
    char_14: 'ellen',
    app_rate_14: 5.02,
    avg_round_14: 14256,
    app_flat_14: 0,
    char_15: 'nicole-demara',
    app_rate_15: 4.94,
    avg_round_15: 22119,
    app_flat_15: 0,
    char_16: 'lighter',
    app_rate_16: 4.11,
    avg_round_16: 20817,
    app_flat_16: 0,
    char_17: 'harumasa',
    app_rate_17: 3.65,
    avg_round_17: 20378,
    app_flat_17: 0,
    char_18: 'zhu-yuan',
    app_rate_18: 3.57,
    avg_round_18: 20267,
    app_flat_18: 0,
    char_19: 'seth',
    app_rate_19: 2.65,
    avg_round_19: 14787,
    app_flat_19: 0,
    char_20: 'lycaon',
    app_rate_20: 2.49,
    avg_round_20: 15891,
    app_flat_20: 0,
    char_21: 'pulchra',
    app_rate_21: 1.95,
    avg_round_21: 22389,
    app_flat_21: 0,
    char_22: 'koleda',
    app_rate_22: 1.91,
    avg_round_22: 15098,
    app_flat_22: 0,
    char_23: 'anby-demara',
    app_rate_23: 0.87,
    avg_round_23: 16310,
    app_flat_23: 0,
    char_24: 'nekomata',
    app_rate_24: 0.58,
    avg_round_24: 18553,
    app_flat_24: 0,
    char_25: 'grace-howard',
    app_rate_25: 0.54,
    avg_round_25: 17315,
    app_flat_25: 0,
    char_26: 'corin',
    app_rate_26: 0.46,
    avg_round_26: 18878,
    app_flat_26: 0,
    char_27: 'billy-kid',
    app_rate_27: 0.33,
    avg_round_27: 17183,
    app_flat_27: 0,
    char_28: 'ben',
    app_rate_28: 0.25,
    avg_round_28: 12609,
    app_flat_28: 0,
    char_29: 'anton',
    app_rate_29: 0.08,
    avg_round_29: 27178,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'rina',
    app: 38.36,
    char_1: 'yanagi',
    app_rate_1: 47.75,
    avg_round_1: 26109,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 36.68,
    avg_round_2: 28672,
    app_flat_2: 0,
    char_3: 'harumasa',
    app_rate_3: 26.6,
    avg_round_3: 19325,
    app_flat_3: 0,
    char_4: 'qingyi',
    app_rate_4: 24.84,
    avg_round_4: 20627,
    app_flat_4: 0,
    char_5: 'seth',
    app_rate_5: 7.74,
    avg_round_5: 19468,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 6.75,
    avg_round_6: 22702,
    app_flat_6: 0,
    char_7: 'anby-demara-soldier-0',
    app_rate_7: 6.35,
    avg_round_7: 19443,
    app_flat_7: 0,
    char_8: 'grace-howard',
    app_rate_8: 6.3,
    avg_round_8: 21748,
    app_flat_8: 0,
    char_9: 'burnice',
    app_rate_9: 5.63,
    avg_round_9: 24812,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 5.13,
    avg_round_10: 14979,
    app_flat_10: 0,
    char_11: 'astra-yao',
    app_rate_11: 3.87,
    avg_round_11: 25328,
    app_flat_11: 0,
    char_12: 'nicole-demara',
    app_rate_12: 3.74,
    avg_round_12: 23814,
    app_flat_12: 0,
    char_13: 'lycaon',
    app_rate_13: 3.6,
    avg_round_13: 17321,
    app_flat_13: 0,
    char_14: 'ellen',
    app_rate_14: 2.61,
    avg_round_14: 11619,
    app_flat_14: 0,
    char_15: 'soukaku',
    app_rate_15: 2.39,
    avg_round_15: 20251,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 2.25,
    avg_round_16: 14439,
    app_flat_16: 0,
    char_17: 'lucy',
    app_rate_17: 1.58,
    avg_round_17: 23056,
    app_flat_17: 0,
    char_18: 'pulchra',
    app_rate_18: 1.58,
    avg_round_18: 15812,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.99,
    avg_round_19: 15215,
    app_flat_19: 0,
    char_20: 'anton',
    app_rate_20: 0.72,
    avg_round_20: 19768,
    app_flat_20: 0,
    char_21: 'evelyn',
    app_rate_21: 0.63,
    avg_round_21: 17912,
    app_flat_21: 0,
    char_22: 'corin',
    app_rate_22: 0.54,
    avg_round_22: 19902,
    app_flat_22: 0,
    char_23: 'piper',
    app_rate_23: 0.5,
    avg_round_23: 15261,
    app_flat_23: 0,
    char_24: 'lighter',
    app_rate_24: 0.41,
    avg_round_24: 17334,
    app_flat_24: 0,
    char_25: 'koleda',
    app_rate_25: 0.14,
    avg_round_25: 13351,
    app_flat_25: 0,
    char_26: 'soldier-11',
    app_rate_26: 0.14,
    avg_round_26: 7990,
    app_flat_26: 0,
    char_27: 'nekomata',
    app_rate_27: 0.05,
    avg_round_27: 22866,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'zhu-yuan',
    app: 29.92,
    char_1: 'nicole-demara',
    app_rate_1: 86.96,
    avg_round_1: 23331,
    app_flat_1: 0,
    char_2: 'qingyi',
    app_rate_2: 74.55,
    avg_round_2: 23414,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 19.22,
    avg_round_3: 27765,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 7.16,
    avg_round_4: 15861,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 4.96,
    avg_round_5: 20267,
    app_flat_5: 0,
    char_6: 'rina',
    app_rate_6: 1.27,
    avg_round_6: 15215,
    app_flat_6: 0,
    char_7: 'koleda',
    app_rate_7: 1.1,
    avg_round_7: 15450,
    app_flat_7: 0,
    char_8: 'yanagi',
    app_rate_8: 1.04,
    avg_round_8: 14952,
    app_flat_8: 0,
    char_9: 'lucy',
    app_rate_9: 0.92,
    avg_round_9: 20851,
    app_flat_9: 0,
    char_10: 'lycaon',
    app_rate_10: 0.63,
    avg_round_10: 18257,
    app_flat_10: 0,
    char_11: 'miyabi',
    app_rate_11: 0.46,
    avg_round_11: 17354,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 0.46,
    avg_round_12: 17971,
    app_flat_12: 0,
    char_13: 'evelyn',
    app_rate_13: 0.35,
    avg_round_13: 14502,
    app_flat_13: 0,
    char_14: 'harumasa',
    app_rate_14: 0.35,
    avg_round_14: 24746,
    app_flat_14: 0,
    char_15: 'seth',
    app_rate_15: 0.29,
    avg_round_15: 12507,
    app_flat_15: 0,
    char_16: 'lighter',
    app_rate_16: 0.23,
    avg_round_16: 12534,
    app_flat_16: 0,
    char_17: 'anby-demara-soldier-0',
    app_rate_17: 0.17,
    avg_round_17: 15488,
    app_flat_17: 0,
    char_18: 'burnice',
    app_rate_18: 0.17,
    avg_round_18: 13807,
    app_flat_18: 0,
    char_19: 'piper',
    app_rate_19: 0.12,
    avg_round_19: 8752,
    app_flat_19: 0,
    char_20: 'ellen',
    app_rate_20: 0.12,
    avg_round_20: 35544,
    app_flat_20: 0,
    char_21: 'nekomata',
    app_rate_21: 0.06,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'grace-howard',
    app_rate_22: 0.06,
    avg_round_22: 9148,
    app_flat_22: 0,
    char_23: 'soukaku',
    app_rate_23: 0.06,
    avg_round_23: 13040,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lycaon',
    app: 29.61,
    char_1: 'miyabi',
    app_rate_1: 76.62,
    avg_round_1: 25344,
    app_flat_1: 0,
    char_2: 'soukaku',
    app_rate_2: 69.1,
    avg_round_2: 23265,
    app_flat_2: 0,
    char_3: 'ellen',
    app_rate_3: 19.36,
    avg_round_3: 14074,
    app_flat_3: 0,
    char_4: 'astra-yao',
    app_rate_4: 12.13,
    avg_round_4: 24367,
    app_flat_4: 0,
    char_5: 'rina',
    app_rate_5: 4.66,
    avg_round_5: 17321,
    app_flat_5: 0,
    char_6: 'nicole-demara',
    app_rate_6: 3.73,
    avg_round_6: 21174,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 3.5,
    avg_round_7: 15891,
    app_flat_7: 0,
    char_8: 'yanagi',
    app_rate_8: 2.27,
    avg_round_8: 25554,
    app_flat_8: 0,
    char_9: 'lucy',
    app_rate_9: 2.04,
    avg_round_9: 17830,
    app_flat_9: 0,
    char_10: 'harumasa',
    app_rate_10: 1.69,
    avg_round_10: 13148,
    app_flat_10: 0,
    char_11: 'evelyn',
    app_rate_11: 1.46,
    avg_round_11: 15930,
    app_flat_11: 0,
    char_12: 'lighter',
    app_rate_12: 1.28,
    avg_round_12: 13952,
    app_flat_12: 0,
    char_13: 'burnice',
    app_rate_13: 0.82,
    avg_round_13: 16850,
    app_flat_13: 0,
    char_14: 'zhu-yuan',
    app_rate_14: 0.64,
    avg_round_14: 18257,
    app_flat_14: 0,
    char_15: 'soldier-11',
    app_rate_15: 0.29,
    avg_round_15: 8707,
    app_flat_15: 0,
    char_16: 'anby-demara-soldier-0',
    app_rate_16: 0.29,
    avg_round_16: 12491,
    app_flat_16: 0,
    char_17: 'jane-doe',
    app_rate_17: 0.23,
    avg_round_17: 8414,
    app_flat_17: 0,
    char_18: 'corin',
    app_rate_18: 0.17,
    avg_round_18: 11201,
    app_flat_18: 0,
    char_19: 'grace-howard',
    app_rate_19: 0.12,
    avg_round_19: 10083,
    app_flat_19: 0,
    char_20: 'seth',
    app_rate_20: 0.12,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'anton',
    app_rate_21: 0.06,
    avg_round_21: 14378,
    app_flat_21: 0,
    char_22: 'qingyi',
    app_rate_22: 0.06,
    avg_round_22: 20628,
    app_flat_22: 0,
    char_23: 'anby-demara',
    app_rate_23: 0.06,
    avg_round_23: 10168,
    app_flat_23: 0,
    char_24: 'nekomata',
    app_rate_24: 0.06,
    avg_round_24: 7853,
    app_flat_24: 0,
    char_25: 'piper',
    app_rate_25: 0.06,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'harumasa',
    app: 25.17,
    char_1: 'qingyi',
    app_rate_1: 64.06,
    avg_round_1: 21875,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 40.53,
    avg_round_2: 19325,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 24.69,
    avg_round_3: 20400,
    app_flat_3: 0,
    char_4: 'astra-yao',
    app_rate_4: 19.48,
    avg_round_4: 23614,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 16.67,
    avg_round_5: 16241,
    app_flat_5: 0,
    char_6: 'yanagi',
    app_rate_6: 6.79,
    avg_round_6: 17870,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 6.04,
    avg_round_7: 20378,
    app_flat_7: 0,
    char_8: 'grace-howard',
    app_rate_8: 5.35,
    avg_round_8: 15344,
    app_flat_8: 0,
    char_9: 'miyabi',
    app_rate_9: 4.25,
    avg_round_9: 19803,
    app_flat_9: 0,
    char_10: 'lucy',
    app_rate_10: 2.47,
    avg_round_10: 17504,
    app_flat_10: 0,
    char_11: 'lycaon',
    app_rate_11: 1.99,
    avg_round_11: 13148,
    app_flat_11: 0,
    char_12: 'soukaku',
    app_rate_12: 1.85,
    avg_round_12: 13782,
    app_flat_12: 0,
    char_13: 'seth',
    app_rate_13: 1.17,
    avg_round_13: 11790,
    app_flat_13: 0,
    char_14: 'lighter',
    app_rate_14: 0.69,
    avg_round_14: 15255,
    app_flat_14: 0,
    char_15: 'burnice',
    app_rate_15: 0.69,
    avg_round_15: 16406,
    app_flat_15: 0,
    char_16: 'koleda',
    app_rate_16: 0.48,
    avg_round_16: 15146,
    app_flat_16: 0,
    char_17: 'anby-demara-soldier-0',
    app_rate_17: 0.48,
    avg_round_17: 17389,
    app_flat_17: 0,
    char_18: 'zhu-yuan',
    app_rate_18: 0.41,
    avg_round_18: 24746,
    app_flat_18: 0,
    char_19: 'pulchra',
    app_rate_19: 0.34,
    avg_round_19: 15848,
    app_flat_19: 0,
    char_20: 'evelyn',
    app_rate_20: 0.27,
    avg_round_20: 18414,
    app_flat_20: 0,
    char_21: 'jane-doe',
    app_rate_21: 0.21,
    avg_round_21: 64800,
    app_flat_21: 0,
    char_22: 'ellen',
    app_rate_22: 0.21,
    avg_round_22: 11983,
    app_flat_22: 0,
    char_23: 'anton',
    app_rate_23: 0.14,
    avg_round_23: 13062,
    app_flat_23: 0,
    char_24: 'piper',
    app_rate_24: 0.14,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'nekomata',
    app_rate_25: 0.07,
    avg_round_25: 20445,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jane-doe',
    app: 21.48,
    char_1: 'burnice',
    app_rate_1: 65.92,
    avg_round_1: 18297,
    app_flat_1: 0,
    char_2: 'lucy',
    app_rate_2: 33.44,
    avg_round_2: 17434,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 27.09,
    avg_round_3: 17775,
    app_flat_3: 0,
    char_4: 'seth',
    app_rate_4: 26.05,
    avg_round_4: 13471,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 17.28,
    avg_round_5: 21631,
    app_flat_5: 0,
    char_6: 'miyabi',
    app_rate_6: 4.18,
    avg_round_6: 22218,
    app_flat_6: 0,
    char_7: 'rina',
    app_rate_7: 4.02,
    avg_round_7: 14439,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 3.7,
    avg_round_8: 15058,
    app_flat_8: 0,
    char_9: 'yanagi',
    app_rate_9: 3.54,
    avg_round_9: 18996,
    app_flat_9: 0,
    char_10: 'qingyi',
    app_rate_10: 2.81,
    avg_round_10: 14208,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 1.77,
    avg_round_11: 11119,
    app_flat_11: 0,
    char_12: 'piper',
    app_rate_12: 1.45,
    avg_round_12: 10651,
    app_flat_12: 0,
    char_13: 'evelyn',
    app_rate_13: 1.45,
    avg_round_13: 13053,
    app_flat_13: 0,
    char_14: 'koleda',
    app_rate_14: 1.29,
    avg_round_14: 10927,
    app_flat_14: 0,
    char_15: 'soukaku',
    app_rate_15: 1.05,
    avg_round_15: 17253,
    app_flat_15: 0,
    char_16: 'pulchra',
    app_rate_16: 0.96,
    avg_round_16: 13188,
    app_flat_16: 0,
    char_17: 'zhu-yuan',
    app_rate_17: 0.64,
    avg_round_17: 17971,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.56,
    avg_round_18: 9592,
    app_flat_18: 0,
    char_19: 'lighter',
    app_rate_19: 0.48,
    avg_round_19: 17485,
    app_flat_19: 0,
    char_20: 'anby-demara-soldier-0',
    app_rate_20: 0.4,
    avg_round_20: 11569,
    app_flat_20: 0,
    char_21: 'nekomata',
    app_rate_21: 0.32,
    avg_round_21: 11368,
    app_flat_21: 0,
    char_22: 'lycaon',
    app_rate_22: 0.32,
    avg_round_22: 8414,
    app_flat_22: 0,
    char_23: 'harumasa',
    app_rate_23: 0.24,
    avg_round_23: 64800,
    app_flat_23: 0,
    char_24: 'anby-demara',
    app_rate_24: 0.24,
    avg_round_24: 10901,
    app_flat_24: 0,
    char_25: 'ellen',
    app_rate_25: 0.16,
    avg_round_25: 6537,
    app_flat_25: 0,
    char_26: 'corin',
    app_rate_26: 0.08,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'billy-kid',
    app_rate_27: 0.08,
    avg_round_27: 11812,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lighter',
    app: 19.85,
    char_1: 'evelyn',
    app_rate_1: 61.04,
    avg_round_1: 26835,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 50.35,
    avg_round_2: 28424,
    app_flat_2: 0,
    char_3: 'lucy',
    app_rate_3: 29.65,
    avg_round_3: 21265,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 18.52,
    avg_round_4: 20338,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 8.61,
    avg_round_5: 20817,
    app_flat_5: 0,
    char_6: 'miyabi',
    app_rate_6: 8.35,
    avg_round_6: 24175,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 6.96,
    avg_round_7: 19011,
    app_flat_7: 0,
    char_8: 'ellen',
    app_rate_8: 5.22,
    avg_round_8: 16640,
    app_flat_8: 0,
    char_9: 'soukaku',
    app_rate_9: 2.78,
    avg_round_9: 17308,
    app_flat_9: 0,
    char_10: 'lycaon',
    app_rate_10: 1.91,
    avg_round_10: 13952,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 1.3,
    avg_round_11: 16667,
    app_flat_11: 0,
    char_12: 'ben',
    app_rate_12: 1.04,
    avg_round_12: 21856,
    app_flat_12: 0,
    char_13: 'harumasa',
    app_rate_13: 0.87,
    avg_round_13: 15255,
    app_flat_13: 0,
    char_14: 'rina',
    app_rate_14: 0.78,
    avg_round_14: 17334,
    app_flat_14: 0,
    char_15: 'piper',
    app_rate_15: 0.61,
    avg_round_15: 14464,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 0.52,
    avg_round_16: 17485,
    app_flat_16: 0,
    char_17: 'koleda',
    app_rate_17: 0.43,
    avg_round_17: 19619,
    app_flat_17: 0,
    char_18: 'zhu-yuan',
    app_rate_18: 0.35,
    avg_round_18: 12534,
    app_flat_18: 0,
    char_19: 'yanagi',
    app_rate_19: 0.17,
    avg_round_19: 11879,
    app_flat_19: 0,
    char_20: 'nekomata',
    app_rate_20: 0.17,
    avg_round_20: 9475,
    app_flat_20: 0,
    char_21: 'pulchra',
    app_rate_21: 0.17,
    avg_round_21: 16298,
    app_flat_21: 0,
    char_22: 'grace-howard',
    app_rate_22: 0.09,
    avg_round_22: 11879,
    app_flat_22: 0,
    char_23: 'corin',
    app_rate_23: 0.09,
    avg_round_23: 9008,
    app_flat_23: 0,
    char_24: 'billy-kid',
    app_rate_24: 0.09,
    avg_round_24: 11120,
    app_flat_24: 0,
    char_25: 'qingyi',
    app_rate_25: 0.09,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'seth',
    app_rate_26: 0.09,
    avg_round_26: 10275,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'koleda',
    app: 17.9,
    char_1: 'evelyn',
    app_rate_1: 75.31,
    avg_round_1: 20421,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 64.13,
    avg_round_2: 21487,
    app_flat_2: 0,
    char_3: 'lucy',
    app_rate_3: 22.28,
    avg_round_3: 15468,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 15.14,
    avg_round_4: 14954,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 4.44,
    avg_round_5: 15098,
    app_flat_5: 0,
    char_6: 'nicole-demara',
    app_rate_6: 2.51,
    avg_round_6: 16038,
    app_flat_6: 0,
    char_7: 'miyabi',
    app_rate_7: 2.12,
    avg_round_7: 20412,
    app_flat_7: 0,
    char_8: 'burnice',
    app_rate_8: 2.03,
    avg_round_8: 12246,
    app_flat_8: 0,
    char_9: 'zhu-yuan',
    app_rate_9: 1.83,
    avg_round_9: 15450,
    app_flat_9: 0,
    char_10: 'soukaku',
    app_rate_10: 1.74,
    avg_round_10: 13815,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 1.54,
    avg_round_11: 10927,
    app_flat_11: 0,
    char_12: 'ben',
    app_rate_12: 1.45,
    avg_round_12: 14677,
    app_flat_12: 0,
    char_13: 'piper',
    app_rate_13: 1.06,
    avg_round_13: 10654,
    app_flat_13: 0,
    char_14: 'ellen',
    app_rate_14: 0.87,
    avg_round_14: 9871,
    app_flat_14: 0,
    char_15: 'harumasa',
    app_rate_15: 0.68,
    avg_round_15: 15146,
    app_flat_15: 0,
    char_16: 'yanagi',
    app_rate_16: 0.58,
    avg_round_16: 11576,
    app_flat_16: 0,
    char_17: 'anby-demara-soldier-0',
    app_rate_17: 0.48,
    avg_round_17: 14596,
    app_flat_17: 0,
    char_18: 'lighter',
    app_rate_18: 0.48,
    avg_round_18: 19619,
    app_flat_18: 0,
    char_19: 'seth',
    app_rate_19: 0.48,
    avg_round_19: 10759,
    app_flat_19: 0,
    char_20: 'grace-howard',
    app_rate_20: 0.29,
    avg_round_20: 27365,
    app_flat_20: 0,
    char_21: 'rina',
    app_rate_21: 0.29,
    avg_round_21: 13351,
    app_flat_21: 0,
    char_22: 'nekomata',
    app_rate_22: 0.19,
    avg_round_22: 11750,
    app_flat_22: 0,
    char_23: 'corin',
    app_rate_23: 0.19,
    avg_round_23: 11832,
    app_flat_23: 0,
    char_24: 'billy-kid',
    app_rate_24: 0.1,
    avg_round_24: 6772,
    app_flat_24: 0,
    char_25: 'pulchra',
    app_rate_25: 0.1,
    avg_round_25: 6772,
    app_flat_25: 0,
    char_26: 'qingyi',
    app_rate_26: 0.1,
    avg_round_26: 6466,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soldier-11',
    app: 13.07,
    char_1: 'lucy',
    app_rate_1: 60.77,
    avg_round_1: 16931,
    app_flat_1: 0,
    char_2: 'caesar',
    app_rate_2: 28.67,
    avg_round_2: 18182,
    app_flat_2: 0,
    char_3: 'lighter',
    app_rate_3: 28.14,
    avg_round_3: 20338,
    app_flat_3: 0,
    char_4: 'astra-yao',
    app_rate_4: 25.5,
    avg_round_4: 20665,
    app_flat_4: 0,
    char_5: 'koleda',
    app_rate_5: 20.74,
    avg_round_5: 14954,
    app_flat_5: 0,
    char_6: 'burnice',
    app_rate_6: 16.38,
    avg_round_6: 17130,
    app_flat_6: 0,
    char_7: 'evelyn',
    app_rate_7: 4.89,
    avg_round_7: 16495,
    app_flat_7: 0,
    char_8: 'ben',
    app_rate_8: 2.77,
    avg_round_8: 12884,
    app_flat_8: 0,
    char_9: 'qingyi',
    app_rate_9: 2.38,
    avg_round_9: 13241,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 2.38,
    avg_round_10: 15819,
    app_flat_10: 0,
    char_11: 'piper',
    app_rate_11: 1.72,
    avg_round_11: 11012,
    app_flat_11: 0,
    char_12: 'miyabi',
    app_rate_12: 1.32,
    avg_round_12: 18228,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 0.92,
    avg_round_13: 9592,
    app_flat_13: 0,
    char_14: 'lycaon',
    app_rate_14: 0.66,
    avg_round_14: 8707,
    app_flat_14: 0,
    char_15: 'soukaku',
    app_rate_15: 0.66,
    avg_round_15: 10996,
    app_flat_15: 0,
    char_16: 'anby-demara',
    app_rate_16: 0.53,
    avg_round_16: 8949,
    app_flat_16: 0,
    char_17: 'ellen',
    app_rate_17: 0.53,
    avg_round_17: 9170,
    app_flat_17: 0,
    char_18: 'rina',
    app_rate_18: 0.4,
    avg_round_18: 7990,
    app_flat_18: 0,
    char_19: 'pulchra',
    app_rate_19: 0.26,
    avg_round_19: 7790,
    app_flat_19: 0,
    char_20: 'yanagi',
    app_rate_20: 0.26,
    avg_round_20: 10702,
    app_flat_20: 0,
    char_21: 'seth',
    app_rate_21: 0.26,
    avg_round_21: 10182,
    app_flat_21: 0,
    char_22: 'nekomata',
    app_rate_22: 0.13,
    avg_round_22: 14731,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara-soldier-0',
    app: 12.69,
    char_1: 'astra-yao',
    app_rate_1: 41.63,
    avg_round_1: 26170,
    app_flat_1: 0,
    char_2: 'pulchra',
    app_rate_2: 31.97,
    avg_round_2: 20838,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 29.8,
    avg_round_3: 23801,
    app_flat_3: 0,
    char_4: 'qingyi',
    app_rate_4: 27.21,
    avg_round_4: 21969,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 23.81,
    avg_round_5: 23160,
    app_flat_5: 0,
    char_6: 'rina',
    app_rate_6: 19.18,
    avg_round_6: 19443,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 11.56,
    avg_round_7: 19913,
    app_flat_7: 0,
    char_8: 'anby-demara',
    app_rate_8: 5.99,
    avg_round_8: 15746,
    app_flat_8: 0,
    char_9: 'yanagi',
    app_rate_9: 2.31,
    avg_round_9: 17516,
    app_flat_9: 0,
    char_10: 'burnice',
    app_rate_10: 1.22,
    avg_round_10: 12035,
    app_flat_10: 0,
    char_11: 'harumasa',
    app_rate_11: 0.95,
    avg_round_11: 17389,
    app_flat_11: 0,
    char_12: 'koleda',
    app_rate_12: 0.68,
    avg_round_12: 14596,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 0.68,
    avg_round_13: 11569,
    app_flat_13: 0,
    char_14: 'lycaon',
    app_rate_14: 0.68,
    avg_round_14: 12491,
    app_flat_14: 0,
    char_15: 'zhu-yuan',
    app_rate_15: 0.41,
    avg_round_15: 15488,
    app_flat_15: 0,
    char_16: 'seth',
    app_rate_16: 0.41,
    avg_round_16: 19614,
    app_flat_16: 0,
    char_17: 'miyabi',
    app_rate_17: 0.27,
    avg_round_17: 15324,
    app_flat_17: 0,
    char_18: 'soukaku',
    app_rate_18: 0.27,
    avg_round_18: 11928,
    app_flat_18: 0,
    char_19: 'grace-howard',
    app_rate_19: 0.14,
    avg_round_19: 7347,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'seth',
    app: 11.64,
    char_1: 'jane-doe',
    app_rate_1: 48.07,
    avg_round_1: 13471,
    app_flat_1: 0,
    char_2: 'yanagi',
    app_rate_2: 39.17,
    avg_round_2: 20489,
    app_flat_2: 0,
    char_3: 'rina',
    app_rate_3: 25.52,
    avg_round_3: 19468,
    app_flat_3: 0,
    char_4: 'burnice',
    app_rate_4: 16.91,
    avg_round_4: 15667,
    app_flat_4: 0,
    char_5: 'miyabi',
    app_rate_5: 14.39,
    avg_round_5: 22598,
    app_flat_5: 0,
    char_6: 'astra-yao',
    app_rate_6: 10.53,
    avg_round_6: 18780,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 9.5,
    avg_round_7: 14184,
    app_flat_7: 0,
    char_8: 'caesar',
    app_rate_8: 9.5,
    avg_round_8: 14787,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 4.9,
    avg_round_9: 16282,
    app_flat_9: 0,
    char_10: 'soukaku',
    app_rate_10: 4.15,
    avg_round_10: 18344,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 4.01,
    avg_round_11: 10812,
    app_flat_11: 0,
    char_12: 'harumasa',
    app_rate_12: 2.52,
    avg_round_12: 11790,
    app_flat_12: 0,
    char_13: 'qingyi',
    app_rate_13: 2.37,
    avg_round_13: 14102,
    app_flat_13: 0,
    char_14: 'anby-demara',
    app_rate_14: 1.63,
    avg_round_14: 11152,
    app_flat_14: 0,
    char_15: 'evelyn',
    app_rate_15: 1.19,
    avg_round_15: 16361,
    app_flat_15: 0,
    char_16: 'piper',
    app_rate_16: 1.04,
    avg_round_16: 7610,
    app_flat_16: 0,
    char_17: 'koleda',
    app_rate_17: 0.74,
    avg_round_17: 10759,
    app_flat_17: 0,
    char_18: 'zhu-yuan',
    app_rate_18: 0.74,
    avg_round_18: 12507,
    app_flat_18: 0,
    char_19: 'anby-demara-soldier-0',
    app_rate_19: 0.45,
    avg_round_19: 19614,
    app_flat_19: 0,
    char_20: 'soldier-11',
    app_rate_20: 0.3,
    avg_round_20: 10182,
    app_flat_20: 0,
    char_21: 'lycaon',
    app_rate_21: 0.3,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'corin',
    app_rate_22: 0.15,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'ellen',
    app_rate_23: 0.15,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'nekomata',
    app_rate_24: 0.15,
    avg_round_24: 9731,
    app_flat_24: 0,
    char_25: 'lighter',
    app_rate_25: 0.15,
    avg_round_25: 10275,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'piper',
    app: 11.48,
    char_1: 'burnice',
    app_rate_1: 57.29,
    avg_round_1: 18603,
    app_flat_1: 0,
    char_2: 'lucy',
    app_rate_2: 55.64,
    avg_round_2: 18527,
    app_flat_2: 0,
    char_3: 'miyabi',
    app_rate_3: 22.86,
    avg_round_3: 22971,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 20.9,
    avg_round_4: 16724,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 15.94,
    avg_round_5: 21992,
    app_flat_5: 0,
    char_6: 'yanagi',
    app_rate_6: 4.81,
    avg_round_6: 19616,
    app_flat_6: 0,
    char_7: 'soukaku',
    app_rate_7: 4.66,
    avg_round_7: 19771,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 3.16,
    avg_round_8: 18100,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 2.71,
    avg_round_9: 10651,
    app_flat_9: 0,
    char_10: 'soldier-11',
    app_rate_10: 1.95,
    avg_round_10: 11012,
    app_flat_10: 0,
    char_11: 'koleda',
    app_rate_11: 1.65,
    avg_round_11: 10654,
    app_flat_11: 0,
    char_12: 'rina',
    app_rate_12: 1.65,
    avg_round_12: 15261,
    app_flat_12: 0,
    char_13: 'nekomata',
    app_rate_13: 1.35,
    avg_round_13: 10660,
    app_flat_13: 0,
    char_14: 'lighter',
    app_rate_14: 1.05,
    avg_round_14: 14464,
    app_flat_14: 0,
    char_15: 'seth',
    app_rate_15: 1.05,
    avg_round_15: 7610,
    app_flat_15: 0,
    char_16: 'evelyn',
    app_rate_16: 0.6,
    avg_round_16: 34867,
    app_flat_16: 0,
    char_17: 'grace-howard',
    app_rate_17: 0.6,
    avg_round_17: 14340,
    app_flat_17: 0,
    char_18: 'corin',
    app_rate_18: 0.45,
    avg_round_18: 11080,
    app_flat_18: 0,
    char_19: 'anby-demara',
    app_rate_19: 0.45,
    avg_round_19: 8379,
    app_flat_19: 0,
    char_20: 'billy-kid',
    app_rate_20: 0.3,
    avg_round_20: 7860,
    app_flat_20: 0,
    char_21: 'pulchra',
    app_rate_21: 0.3,
    avg_round_21: 14243,
    app_flat_21: 0,
    char_22: 'harumasa',
    app_rate_22: 0.3,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'zhu-yuan',
    app_rate_23: 0.3,
    avg_round_23: 8752,
    app_flat_23: 0,
    char_24: 'qingyi',
    app_rate_24: 0.3,
    avg_round_24: 9308,
    app_flat_24: 0,
    char_25: 'ben',
    app_rate_25: 0.15,
    avg_round_25: 21821,
    app_flat_25: 0,
    char_26: 'lycaon',
    app_rate_26: 0.15,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'ellen',
    app: 11.33,
    char_1: 'soukaku',
    app_rate_1: 53.81,
    avg_round_1: 14094,
    app_flat_1: 0,
    char_2: 'lycaon',
    app_rate_2: 50.61,
    avg_round_2: 14074,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 25.61,
    avg_round_3: 19786,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 18.45,
    avg_round_4: 14256,
    app_flat_4: 0,
    char_5: 'miyabi',
    app_rate_5: 14.02,
    avg_round_5: 18502,
    app_flat_5: 0,
    char_6: 'lighter',
    app_rate_6: 9.15,
    avg_round_6: 16640,
    app_flat_6: 0,
    char_7: 'rina',
    app_rate_7: 8.84,
    avg_round_7: 11619,
    app_flat_7: 0,
    char_8: 'qingyi',
    app_rate_8: 5.95,
    avg_round_8: 15349,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 3.96,
    avg_round_9: 18271,
    app_flat_9: 0,
    char_10: 'lucy',
    app_rate_10: 2.59,
    avg_round_10: 13696,
    app_flat_10: 0,
    char_11: 'yanagi',
    app_rate_11: 1.52,
    avg_round_11: 18173,
    app_flat_11: 0,
    char_12: 'burnice',
    app_rate_12: 1.52,
    avg_round_12: 16367,
    app_flat_12: 0,
    char_13: 'koleda',
    app_rate_13: 1.37,
    avg_round_13: 9871,
    app_flat_13: 0,
    char_14: 'anby-demara',
    app_rate_14: 0.61,
    avg_round_14: 13466,
    app_flat_14: 0,
    char_15: 'soldier-11',
    app_rate_15: 0.61,
    avg_round_15: 9170,
    app_flat_15: 0,
    char_16: 'harumasa',
    app_rate_16: 0.46,
    avg_round_16: 11983,
    app_flat_16: 0,
    char_17: 'ben',
    app_rate_17: 0.3,
    avg_round_17: 9365,
    app_flat_17: 0,
    char_18: 'jane-doe',
    app_rate_18: 0.3,
    avg_round_18: 6537,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.3,
    avg_round_19: 35544,
    app_flat_19: 0,
    char_20: 'pulchra',
    app_rate_20: 0.15,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'seth',
    app_rate_21: 0.15,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'grace-howard',
    app_rate_22: 0.15,
    avg_round_22: 11675,
    app_flat_22: 0,
    char_23: 'corin',
    app_rate_23: 0.15,
    avg_round_23: 8025,
    app_flat_23: 0,
    char_24: 'evelyn',
    app_rate_24: 0.15,
    avg_round_24: 11615,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara',
    app: 9.19,
    char_1: 'nicole-demara',
    app_rate_1: 46.24,
    avg_round_1: 16049,
    app_flat_1: 0,
    char_2: 'harumasa',
    app_rate_2: 45.68,
    avg_round_2: 16241,
    app_flat_2: 0,
    char_3: 'zhu-yuan',
    app_rate_3: 23.31,
    avg_round_3: 15861,
    app_flat_3: 0,
    char_4: 'rina',
    app_rate_4: 21.43,
    avg_round_4: 14979,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 18.8,
    avg_round_5: 20551,
    app_flat_5: 0,
    char_6: 'yanagi',
    app_rate_6: 8.83,
    avg_round_6: 15964,
    app_flat_6: 0,
    char_7: 'anby-demara-soldier-0',
    app_rate_7: 8.27,
    avg_round_7: 15746,
    app_flat_7: 0,
    char_8: 'miyabi',
    app_rate_8: 5.08,
    avg_round_8: 21811,
    app_flat_8: 0,
    char_9: 'evelyn',
    app_rate_9: 4.89,
    avg_round_9: 18330,
    app_flat_9: 0,
    char_10: 'caesar',
    app_rate_10: 3.95,
    avg_round_10: 16310,
    app_flat_10: 0,
    char_11: 'soukaku',
    app_rate_11: 2.44,
    avg_round_11: 12894,
    app_flat_11: 0,
    char_12: 'seth',
    app_rate_12: 2.07,
    avg_round_12: 11152,
    app_flat_12: 0,
    char_13: 'lucy',
    app_rate_13: 2.07,
    avg_round_13: 10838,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 2.07,
    avg_round_14: 10133,
    app_flat_14: 0,
    char_15: 'nekomata',
    app_rate_15: 0.94,
    avg_round_15: 11364,
    app_flat_15: 0,
    char_16: 'anton',
    app_rate_16: 0.75,
    avg_round_16: 15346,
    app_flat_16: 0,
    char_17: 'billy-kid',
    app_rate_17: 0.75,
    avg_round_17: 9941,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.75,
    avg_round_18: 8949,
    app_flat_18: 0,
    char_19: 'ellen',
    app_rate_19: 0.75,
    avg_round_19: 13466,
    app_flat_19: 0,
    char_20: 'qingyi',
    app_rate_20: 0.56,
    avg_round_20: 8170,
    app_flat_20: 0,
    char_21: 'piper',
    app_rate_21: 0.56,
    avg_round_21: 8379,
    app_flat_21: 0,
    char_22: 'jane-doe',
    app_rate_22: 0.56,
    avg_round_22: 10901,
    app_flat_22: 0,
    char_23: 'burnice',
    app_rate_23: 0.19,
    avg_round_23: 17789,
    app_flat_23: 0,
    char_24: 'lycaon',
    app_rate_24: 0.19,
    avg_round_24: 10168,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'grace-howard',
    app: 5.59,
    char_1: 'miyabi',
    app_rate_1: 48.46,
    avg_round_1: 25286,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 43.21,
    avg_round_2: 21748,
    app_flat_2: 0,
    char_3: 'harumasa',
    app_rate_3: 24.07,
    avg_round_3: 15344,
    app_flat_3: 0,
    char_4: 'astra-yao',
    app_rate_4: 14.2,
    avg_round_4: 26470,
    app_flat_4: 0,
    char_5: 'yanagi',
    app_rate_5: 12.04,
    avg_round_5: 16664,
    app_flat_5: 0,
    char_6: 'soukaku',
    app_rate_6: 10.49,
    avg_round_6: 23580,
    app_flat_6: 0,
    char_7: 'seth',
    app_rate_7: 8.33,
    avg_round_7: 10812,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 6.79,
    avg_round_8: 11119,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 6.79,
    avg_round_9: 15956,
    app_flat_9: 0,
    char_10: 'burnice',
    app_rate_10: 5.56,
    avg_round_10: 18186,
    app_flat_10: 0,
    char_11: 'qingyi',
    app_rate_11: 4.32,
    avg_round_11: 11212,
    app_flat_11: 0,
    char_12: 'caesar',
    app_rate_12: 4.01,
    avg_round_12: 17315,
    app_flat_12: 0,
    char_13: 'anby-demara',
    app_rate_13: 3.4,
    avg_round_13: 10133,
    app_flat_13: 0,
    char_14: 'lucy',
    app_rate_14: 2.47,
    avg_round_14: 18654,
    app_flat_14: 0,
    char_15: 'piper',
    app_rate_15: 1.23,
    avg_round_15: 14340,
    app_flat_15: 0,
    char_16: 'koleda',
    app_rate_16: 0.93,
    avg_round_16: 27365,
    app_flat_16: 0,
    char_17: 'anton',
    app_rate_17: 0.93,
    avg_round_17: 19811,
    app_flat_17: 0,
    char_18: 'lycaon',
    app_rate_18: 0.62,
    avg_round_18: 10083,
    app_flat_18: 0,
    char_19: 'nekomata',
    app_rate_19: 0.62,
    avg_round_19: 15848,
    app_flat_19: 0,
    char_20: 'pulchra',
    app_rate_20: 0.31,
    avg_round_20: 10888,
    app_flat_20: 0,
    char_21: 'lighter',
    app_rate_21: 0.31,
    avg_round_21: 11879,
    app_flat_21: 0,
    char_22: 'ellen',
    app_rate_22: 0.31,
    avg_round_22: 11675,
    app_flat_22: 0,
    char_23: 'zhu-yuan',
    app_rate_23: 0.31,
    avg_round_23: 9148,
    app_flat_23: 0,
    char_24: 'evelyn',
    app_rate_24: 0.31,
    avg_round_24: 13325,
    app_flat_24: 0,
    char_25: 'anby-demara-soldier-0',
    app_rate_25: 0.31,
    avg_round_25: 7347,
    app_flat_25: 0,
    char_26: 'ben',
    app_rate_26: 0.31,
    avg_round_26: 61600,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'pulchra',
    app: 4.87,
    char_1: 'anby-demara-soldier-0',
    app_rate_1: 83.33,
    avg_round_1: 20838,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 30.85,
    avg_round_2: 23475,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 16.67,
    avg_round_3: 22389,
    app_flat_3: 0,
    char_4: 'lucy',
    app_rate_4: 16.67,
    avg_round_4: 17497,
    app_flat_4: 0,
    char_5: 'rina',
    app_rate_5: 12.41,
    avg_round_5: 15812,
    app_flat_5: 0,
    char_6: 'nicole-demara',
    app_rate_6: 12.06,
    avg_round_6: 20449,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 5.32,
    avg_round_7: 15556,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 4.26,
    avg_round_8: 13188,
    app_flat_8: 0,
    char_9: 'evelyn',
    app_rate_9: 3.9,
    avg_round_9: 17818,
    app_flat_9: 0,
    char_10: 'miyabi',
    app_rate_10: 2.48,
    avg_round_10: 18636,
    app_flat_10: 0,
    char_11: 'nekomata',
    app_rate_11: 2.13,
    avg_round_11: 17846,
    app_flat_11: 0,
    char_12: 'qingyi',
    app_rate_12: 1.77,
    avg_round_12: 20270,
    app_flat_12: 0,
    char_13: 'harumasa',
    app_rate_13: 1.77,
    avg_round_13: 15848,
    app_flat_13: 0,
    char_14: 'piper',
    app_rate_14: 0.71,
    avg_round_14: 14243,
    app_flat_14: 0,
    char_15: 'soldier-11',
    app_rate_15: 0.71,
    avg_round_15: 7790,
    app_flat_15: 0,
    char_16: 'billy-kid',
    app_rate_16: 0.71,
    avg_round_16: 15311,
    app_flat_16: 0,
    char_17: 'soukaku',
    app_rate_17: 0.71,
    avg_round_17: 14141,
    app_flat_17: 0,
    char_18: 'yanagi',
    app_rate_18: 0.71,
    avg_round_18: 11822,
    app_flat_18: 0,
    char_19: 'lighter',
    app_rate_19: 0.71,
    avg_round_19: 16298,
    app_flat_19: 0,
    char_20: 'grace-howard',
    app_rate_20: 0.35,
    avg_round_20: 10888,
    app_flat_20: 0,
    char_21: 'ellen',
    app_rate_21: 0.35,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'koleda',
    app_rate_22: 0.35,
    avg_round_22: 6772,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nekomata',
    app: 1.35,
    char_1: 'nicole-demara',
    app_rate_1: 46.15,
    avg_round_1: 19494,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 38.46,
    avg_round_2: 20145,
    app_flat_2: 0,
    char_3: 'lucy',
    app_rate_3: 21.79,
    avg_round_3: 14253,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 17.95,
    avg_round_4: 18553,
    app_flat_4: 0,
    char_5: 'qingyi',
    app_rate_5: 16.67,
    avg_round_5: 18802,
    app_flat_5: 0,
    char_6: 'piper',
    app_rate_6: 11.54,
    avg_round_6: 10660,
    app_flat_6: 0,
    char_7: 'pulchra',
    app_rate_7: 7.69,
    avg_round_7: 17846,
    app_flat_7: 0,
    char_8: 'anby-demara',
    app_rate_8: 6.41,
    avg_round_8: 11364,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 5.13,
    avg_round_9: 11368,
    app_flat_9: 0,
    char_10: 'burnice',
    app_rate_10: 3.85,
    avg_round_10: 15270,
    app_flat_10: 0,
    char_11: 'miyabi',
    app_rate_11: 2.56,
    avg_round_11: 23279,
    app_flat_11: 0,
    char_12: 'koleda',
    app_rate_12: 2.56,
    avg_round_12: 11750,
    app_flat_12: 0,
    char_13: 'evelyn',
    app_rate_13: 2.56,
    avg_round_13: 6455,
    app_flat_13: 0,
    char_14: 'lighter',
    app_rate_14: 2.56,
    avg_round_14: 9475,
    app_flat_14: 0,
    char_15: 'grace-howard',
    app_rate_15: 2.56,
    avg_round_15: 15848,
    app_flat_15: 0,
    char_16: 'rina',
    app_rate_16: 1.28,
    avg_round_16: 22866,
    app_flat_16: 0,
    char_17: 'soldier-11',
    app_rate_17: 1.28,
    avg_round_17: 14731,
    app_flat_17: 0,
    char_18: 'lycaon',
    app_rate_18: 1.28,
    avg_round_18: 7853,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 1.28,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'yanagi',
    app_rate_20: 1.28,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'harumasa',
    app_rate_21: 1.28,
    avg_round_21: 20445,
    app_flat_21: 0,
    char_22: 'seth',
    app_rate_22: 1.28,
    avg_round_22: 9731,
    app_flat_22: 0,
    char_23: 'soukaku',
    app_rate_23: 1.28,
    avg_round_23: 8339,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'ben',
    app: 1.23,
    char_1: 'lucy',
    app_rate_1: 52.11,
    avg_round_1: 16378,
    app_flat_1: 0,
    char_2: 'soldier-11',
    app_rate_2: 29.58,
    avg_round_2: 12884,
    app_flat_2: 0,
    char_3: 'evelyn',
    app_rate_3: 23.94,
    avg_round_3: 15941,
    app_flat_3: 0,
    char_4: 'koleda',
    app_rate_4: 21.13,
    avg_round_4: 14677,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 21.13,
    avg_round_5: 21611,
    app_flat_5: 0,
    char_6: 'lighter',
    app_rate_6: 16.9,
    avg_round_6: 21856,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 8.45,
    avg_round_7: 18908,
    app_flat_7: 0,
    char_8: 'caesar',
    app_rate_8: 8.45,
    avg_round_8: 12609,
    app_flat_8: 0,
    char_9: 'miyabi',
    app_rate_9: 7.04,
    avg_round_9: 19271,
    app_flat_9: 0,
    char_10: 'soukaku',
    app_rate_10: 7.04,
    avg_round_10: 13712,
    app_flat_10: 0,
    char_11: 'ellen',
    app_rate_11: 2.82,
    avg_round_11: 9365,
    app_flat_11: 0,
    char_12: 'nicole-demara',
    app_rate_12: 1.41,
    avg_round_12: 14522,
    app_flat_12: 0,
    char_13: 'piper',
    app_rate_13: 1.41,
    avg_round_13: 21821,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 1.41,
    avg_round_14: 61600,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anton',
    app: 0.62,
    char_1: 'qingyi',
    app_rate_1: 50.0,
    avg_round_1: 23065,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 44.44,
    avg_round_2: 19768,
    app_flat_2: 0,
    char_3: 'astra-yao',
    app_rate_3: 38.89,
    avg_round_3: 26270,
    app_flat_3: 0,
    char_4: 'nicole-demara',
    app_rate_4: 33.33,
    avg_round_4: 27480,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 11.11,
    avg_round_5: 15346,
    app_flat_5: 0,
    char_6: 'grace-howard',
    app_rate_6: 8.33,
    avg_round_6: 19811,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 5.56,
    avg_round_7: 27178,
    app_flat_7: 0,
    char_8: 'harumasa',
    app_rate_8: 5.56,
    avg_round_8: 13062,
    app_flat_8: 0,
    char_9: 'lycaon',
    app_rate_9: 2.78,
    avg_round_9: 14378,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'corin',
    app: 0.6,
    char_1: 'qingyi',
    app_rate_1: 37.14,
    avg_round_1: 20215,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 34.29,
    avg_round_2: 19902,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 31.43,
    avg_round_3: 18878,
    app_flat_3: 0,
    char_4: 'lucy',
    app_rate_4: 20.0,
    avg_round_4: 12581,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 14.29,
    avg_round_5: 24735,
    app_flat_5: 0,
    char_6: 'astra-yao',
    app_rate_6: 11.43,
    avg_round_6: 28822,
    app_flat_6: 0,
    char_7: 'burnice',
    app_rate_7: 11.43,
    avg_round_7: 17602,
    app_flat_7: 0,
    char_8: 'piper',
    app_rate_8: 8.57,
    avg_round_8: 11080,
    app_flat_8: 0,
    char_9: 'lycaon',
    app_rate_9: 8.57,
    avg_round_9: 11201,
    app_flat_9: 0,
    char_10: 'koleda',
    app_rate_10: 5.71,
    avg_round_10: 11832,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 2.86,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'seth',
    app_rate_12: 2.86,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'yanagi',
    app_rate_13: 2.86,
    avg_round_13: 16474,
    app_flat_13: 0,
    char_14: 'lighter',
    app_rate_14: 2.86,
    avg_round_14: 9008,
    app_flat_14: 0,
    char_15: 'miyabi',
    app_rate_15: 2.86,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'ellen',
    app_rate_16: 2.86,
    avg_round_16: 8025,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'billy-kid',
    app: 0.45,
    char_1: 'nicole-demara',
    app_rate_1: 53.85,
    avg_round_1: 14978,
    app_flat_1: 0,
    char_2: 'astra-yao',
    app_rate_2: 34.62,
    avg_round_2: 16926,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 30.77,
    avg_round_3: 17183,
    app_flat_3: 0,
    char_4: 'lucy',
    app_rate_4: 19.23,
    avg_round_4: 9806,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 15.38,
    avg_round_5: 9941,
    app_flat_5: 0,
    char_6: 'qingyi',
    app_rate_6: 11.54,
    avg_round_6: 20136,
    app_flat_6: 0,
    char_7: 'piper',
    app_rate_7: 7.69,
    avg_round_7: 7860,
    app_flat_7: 0,
    char_8: 'pulchra',
    app_rate_8: 7.69,
    avg_round_8: 15311,
    app_flat_8: 0,
    char_9: 'evelyn',
    app_rate_9: 3.85,
    avg_round_9: 13158,
    app_flat_9: 0,
    char_10: 'miyabi',
    app_rate_10: 3.85,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'koleda',
    app_rate_11: 3.85,
    avg_round_11: 6772,
    app_flat_11: 0,
    char_12: 'lighter',
    app_rate_12: 3.85,
    avg_round_12: 11120,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 3.85,
    avg_round_13: 11812,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
