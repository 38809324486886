export const ZZZBuilds = [
  {
    char: 'corin',
    app_rate_sd: 0.43,
    app_rate_sd_e1: 0.1,
    app_rate_sd_s0: 0.19,
    avg_round_sd: 167,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 600,
    sample_sd: 21,
    sample_size_players_sd: 0,
    app_rate_da: 0.6,
    app_rate_da_e1: 0.12,
    app_rate_da_s0: 0.26,
    avg_round_da: 18305,
    avg_round_da_e1: 0,
    avg_round_da_s0: 0,
    app_rate_da_boss_1: 0.35,
    avg_round_da_boss_1: 0,
    app_rate_da_boss_2: 0.04,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 0.27,
    avg_round_da_boss_3: 0,
    sample_da: 35,
    sample_size_players_da: 0,
    app_0: 4.76,
    round_0_sd: 377,
    round_0_da: 0,
    app_1: 1,
    round_1_sd: 600,
    round_1_da: 0,
    app_2: 1,
    round_2_sd: 600,
    round_2_da: 0,
    app_3: 2.86,
    round_3_sd: 600,
    round_3_da: 11915,
    app_4: 5.31,
    round_4_sd: 600,
    round_4_da: 0,
    app_5: 2.86,
    round_5_sd: 600,
    round_5_da: 25216,
    app_6: 89.37,
    round_6_sd: 153,
    round_6_da: 18285,
    cons_avg: 5.71,
    weapons_1: 'Housekeeper',
    weapons_1_app: 58.16,
    weapons_1_round_sd: 175,
    weapons_1_round_da: 16414,
    weapons_2: 'Steel Cushion',
    weapons_2_app: 27.23,
    weapons_2_round_sd: 126,
    weapons_2_round_da: 23492,
    weapons_3: 'Marcato Desire',
    weapons_3_app: 3.66,
    weapons_3_round_sd: 600,
    weapons_3_round_da: 14254,
    weapons_4: 'Cannon Rotor',
    weapons_4_app: 3.33,
    weapons_4_round_sd: 600,
    weapons_4_round_da: 22732,
    weapons_5: 'Starlight Engine',
    weapons_5_app: 1.99,
    weapons_5_round_sd: 377,
    weapons_5_round_da: 0.0,
    weapons_6: 'Deep Sea Visitor',
    weapons_6_app: 1.99,
    weapons_6_round_sd: 193,
    weapons_6_round_da: 0.0,
    weapons_7: 'Zanshin Herb Case',
    weapons_7_app: 1.99,
    weapons_7_round_sd: 122,
    weapons_7_round_da: 0.0,
    weapons_8: '',
    weapons_8_app: 0.0,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 0.0,
    weapons_9: '',
    weapons_9_app: 0.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0.0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Fanged Metal, Woodpecker Electro',
    artifacts_1_1: 'Fanged Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 26.91,
    artifacts_1_round_sd: 167,
    artifacts_1_round_da: 13545,
    artifacts_2: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_2_1: 'Woodpecker Electro',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 12.63,
    artifacts_2_round_sd: 120,
    artifacts_2_round_da: 23246,
    artifacts_3: 'Woodpecker Electro, Fanged Metal',
    artifacts_3_1: 'Woodpecker Electro',
    artifacts_3_2: 'Fanged Metal',
    artifacts_3_3: '',
    artifacts_3_app: 9.3,
    artifacts_3_round_sd: 192,
    artifacts_3_round_da: 11915,
    artifacts_4: 'Puffer Electro, Woodpecker Electro',
    artifacts_4_1: 'Puffer Electro',
    artifacts_4_2: 'Woodpecker Electro',
    artifacts_4_3: '',
    artifacts_4_app: 7.3,
    artifacts_4_round_sd: 262,
    artifacts_4_round_da: 18210,
    artifacts_5: 'Hormone Punk, Branch & Blade Song',
    artifacts_5_1: 'Hormone Punk',
    artifacts_5_2: 'Branch & Blade Song',
    artifacts_5_3: '',
    artifacts_5_app: 7.3,
    artifacts_5_round_sd: 142,
    artifacts_5_round_da: 30288,
    artifacts_6: 'Woodpecker Electro, Puffer Electro',
    artifacts_6_1: 'Woodpecker Electro',
    artifacts_6_2: 'Puffer Electro',
    artifacts_6_3: '',
    artifacts_6_app: 3.66,
    artifacts_6_round_sd: 600,
    artifacts_6_round_da: 0,
    artifacts_7: 'Branch & Blade Song, Puffer Electro, Woodpecker Electro',
    artifacts_7_1: 'Branch & Blade Song',
    artifacts_7_2: 'Puffer Electro',
    artifacts_7_3: 'Woodpecker Electro',
    artifacts_7_app: 3.33,
    artifacts_7_round_sd: 600,
    artifacts_7_round_da: 17966,
    artifacts_8: 'Fanged Metal, Puffer Electro, Woodpecker Electro',
    artifacts_8_1: 'Fanged Metal',
    artifacts_8_2: 'Puffer Electro',
    artifacts_8_3: 'Woodpecker Electro',
    artifacts_8_app: 1.99,
    artifacts_8_round_sd: 122,
    artifacts_8_round_da: 0.0,
    artifacts_9: 'Hormone Punk, Fanged Metal',
    artifacts_9_1: 'Hormone Punk',
    artifacts_9_2: 'Fanged Metal',
    artifacts_9_3: '',
    artifacts_9_app: 1.99,
    artifacts_9_round_sd: 164,
    artifacts_9_round_da: 0.0,
    artifacts_10: 'Freedom Blues, Polar Metal',
    artifacts_10_1: 'Freedom Blues',
    artifacts_10_2: 'Polar Metal',
    artifacts_10_3: '',
    artifacts_10_app: 1.99,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'soukaku',
    app_rate_sd: 17.62,
    app_rate_sd_e1: 8.59,
    app_rate_sd_s0: 0.8,
    avg_round_sd: 137,
    avg_round_sd_e1: 105,
    avg_round_sd_s0: 167,
    sample_sd: 856,
    sample_size_players_sd: 0,
    app_rate_da: 42.09,
    app_rate_da_e1: 16.37,
    app_rate_da_s0: 3.47,
    avg_round_da: 22838,
    avg_round_da_e1: 31366,
    avg_round_da_s0: 16883,
    app_rate_da_boss_1: 3.19,
    avg_round_da_boss_1: 15552,
    app_rate_da_boss_2: 39.86,
    avg_round_da_boss_2: 23923,
    app_rate_da_boss_3: 1.82,
    avg_round_da_boss_3: 12403,
    sample_da: 2438,
    sample_size_players_da: 0,
    app_0: 0.04,
    round_0_sd: 600,
    round_0_da: 26404,
    app_1: 0.08,
    round_1_sd: 600,
    round_1_da: 25896,
    app_2: 0.18,
    round_2_sd: 170,
    round_2_da: 14013,
    app_3: 0.27,
    round_3_sd: 151,
    round_3_da: 25388,
    app_4: 0.72,
    round_4_sd: 186,
    round_4_da: 20624,
    app_5: 1.35,
    round_5_sd: 118,
    round_5_da: 21131,
    app_6: 97.38,
    round_6_sd: 136,
    round_6_da: 22917,
    cons_avg: 5.95,
    weapons_1: 'Bashful Demon',
    weapons_1_app: 88.11,
    weapons_1_round_sd: 137,
    weapons_1_round_da: 22596,
    weapons_2: 'Kaboom the Cannon',
    weapons_2_app: 2.75,
    weapons_2_round_sd: 125,
    weapons_2_round_da: 26630,
    weapons_3: 'Slice of Time',
    weapons_3_app: 1.75,
    weapons_3_round_sd: 140,
    weapons_3_round_da: 23699,
    weapons_4: 'Weeping Cradle',
    weapons_4_app: 1.09,
    weapons_4_round_sd: 139,
    weapons_4_round_da: 23035,
    weapons_5: '[Reverb] Mark II',
    weapons_5_app: 0.84,
    weapons_5_round_sd: 130,
    weapons_5_round_da: 24942,
    weapons_6: 'The Vault',
    weapons_6_app: 0.75,
    weapons_6_round_sd: 150,
    weapons_6_round_da: 20680,
    weapons_7: 'Unfettered Game Ball',
    weapons_7_app: 0.45,
    weapons_7_round_sd: 94,
    weapons_7_round_da: 33787,
    weapons_8: 'Elegant Vanity',
    weapons_8_app: 0.15,
    weapons_8_round_sd: 171,
    weapons_8_round_da: 17801,
    weapons_9: '[Reverb] Mark I',
    weapons_9_app: 0.15,
    weapons_9_round_sd: 186,
    weapons_9_round_da: 35831,
    weapons_10: '[Reverb] Mark III',
    weapons_10_app: 0.15,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 21741,
    artifacts_1: 'Swing Jazz, Hormone Punk',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Hormone Punk',
    artifacts_1_3: '',
    artifacts_1_app: 38.06,
    artifacts_1_round_sd: 139,
    artifacts_1_round_da: 22413,
    artifacts_2: 'Astral Voice, Swing Jazz',
    artifacts_2_1: 'Astral Voice',
    artifacts_2_2: 'Swing Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 10.85,
    artifacts_2_round_sd: 131,
    artifacts_2_round_da: 24479,
    artifacts_3: 'Swing Jazz, Polar Metal',
    artifacts_3_1: 'Swing Jazz',
    artifacts_3_2: 'Polar Metal',
    artifacts_3_3: '',
    artifacts_3_app: 6.45,
    artifacts_3_round_sd: 135,
    artifacts_3_round_da: 22201,
    artifacts_4: 'Freedom Blues, Swing Jazz',
    artifacts_4_1: 'Freedom Blues',
    artifacts_4_2: 'Swing Jazz',
    artifacts_4_3: '',
    artifacts_4_app: 5.55,
    artifacts_4_round_sd: 117,
    artifacts_4_round_da: 29204,
    artifacts_5: 'Swing Jazz, Astral Voice',
    artifacts_5_1: 'Swing Jazz',
    artifacts_5_2: 'Astral Voice',
    artifacts_5_3: '',
    artifacts_5_app: 3.9,
    artifacts_5_round_sd: 134,
    artifacts_5_round_da: 23398,
    artifacts_6: 'ATK +10%, Swing Jazz, Flex',
    artifacts_6_1: 'ATK +10%',
    artifacts_6_2: 'Swing Jazz',
    artifacts_6_3: 'Flex',
    artifacts_6_app: 2.45,
    artifacts_6_round_sd: 141,
    artifacts_6_round_da: 20502,
    artifacts_7: 'Hormone Punk, Polar Metal',
    artifacts_7_1: 'Hormone Punk',
    artifacts_7_2: 'Polar Metal',
    artifacts_7_3: '',
    artifacts_7_app: 2.39,
    artifacts_7_round_sd: 161,
    artifacts_7_round_da: 19420,
    artifacts_8: 'Swing Jazz, Freedom Blues',
    artifacts_8_1: 'Swing Jazz',
    artifacts_8_2: 'Freedom Blues',
    artifacts_8_3: '',
    artifacts_8_app: 2.08,
    artifacts_8_round_sd: 113,
    artifacts_8_round_da: 23279,
    artifacts_9: 'Swing Jazz, Flex',
    artifacts_9_1: 'Swing Jazz',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 1.8,
    artifacts_9_round_sd: 148,
    artifacts_9_round_da: 21731,
    artifacts_10: 'Swing Jazz, Woodpecker Electro',
    artifacts_10_1: 'Swing Jazz',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: '',
    artifacts_10_app: 1.51,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 20874
  },
  {
    char: 'billy-kid',
    app_rate_sd: 0.27,
    app_rate_sd_e1: 0.04,
    app_rate_sd_s0: 0.06,
    avg_round_sd: 600,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 600,
    sample_sd: 13,
    sample_size_players_sd: 0,
    app_rate_da: 0.45,
    app_rate_da_e1: 0.09,
    app_rate_da_s0: 0.22,
    avg_round_da: 14215,
    avg_round_da_e1: 0,
    avg_round_da_s0: 0,
    app_rate_da_boss_1: 0.21,
    avg_round_da_boss_1: 0,
    app_rate_da_boss_2: 0.02,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 0.27,
    avg_round_da_boss_3: 0,
    sample_da: 26,
    sample_size_players_da: 0,
    app_0: 1,
    round_0_sd: 600,
    round_0_da: 0,
    app_1: 1,
    round_1_sd: 600,
    round_1_da: 0,
    app_2: 3.85,
    round_2_sd: 600,
    round_2_da: 9776,
    app_3: 1,
    round_3_sd: 600,
    round_3_da: 0,
    app_4: 7.69,
    round_4_sd: 600,
    round_4_da: 9292,
    app_5: 7.69,
    round_5_sd: 600,
    round_5_da: 9876,
    app_6: 85.1,
    round_6_sd: 154,
    round_6_da: 15310,
    cons_avg: 5.73,
    weapons_1: 'Starlight Engine Replica',
    weapons_1_app: 56.25,
    weapons_1_round_sd: 146,
    weapons_1_round_da: 13127,
    weapons_2: 'Steel Cushion',
    weapons_2_app: 15.38,
    weapons_2_round_sd: 217,
    weapons_2_round_da: 13074,
    weapons_3: 'Zanshin Herb Case',
    weapons_3_app: 10.58,
    weapons_3_round_sd: 128,
    weapons_3_round_da: 18867,
    weapons_4: 'The Brimstone',
    weapons_4_app: 7.69,
    weapons_4_round_sd: 600,
    weapons_4_round_da: 23850,
    weapons_5: 'Marcato Desire',
    weapons_5_app: 5.29,
    weapons_5_round_sd: 127,
    weapons_5_round_da: 20900,
    weapons_6: 'Starlight Engine',
    weapons_6_app: 2.41,
    weapons_6_round_sd: 600,
    weapons_6_round_da: 12630,
    weapons_7: 'Severed Innocence',
    weapons_7_app: 2.41,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 12382,
    weapons_8: '',
    weapons_8_app: 0.0,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 0.0,
    weapons_9: '',
    weapons_9_app: 0.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0.0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_1_1: 'Woodpecker Electro',
    artifacts_1_2: 'Branch & Blade Song',
    artifacts_1_3: '',
    artifacts_1_app: 23.09,
    artifacts_1_round_sd: 154,
    artifacts_1_round_da: 13554,
    artifacts_2: 'Woodpecker Electro, Fanged Metal',
    artifacts_2_1: 'Woodpecker Electro',
    artifacts_2_2: 'Fanged Metal',
    artifacts_2_3: '',
    artifacts_2_app: 17.79,
    artifacts_2_round_sd: 165,
    artifacts_2_round_da: 17629,
    artifacts_3: 'Fanged Metal, Woodpecker Electro',
    artifacts_3_1: 'Fanged Metal',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 12.49,
    artifacts_3_round_sd: 203,
    artifacts_3_round_da: 9575,
    artifacts_4: 'Woodpecker Electro, Puffer Electro',
    artifacts_4_1: 'Woodpecker Electro',
    artifacts_4_2: 'Puffer Electro',
    artifacts_4_3: '',
    artifacts_4_app: 11.07,
    artifacts_4_round_sd: 120,
    artifacts_4_round_da: 0,
    artifacts_5: 'Woodpecker Electro, Astral Voice',
    artifacts_5_1: 'Woodpecker Electro',
    artifacts_5_2: 'Astral Voice',
    artifacts_5_3: '',
    artifacts_5_app: 10.58,
    artifacts_5_round_sd: 139,
    artifacts_5_round_da: 26951,
    artifacts_6: 'Woodpecker Electro, Hormone Punk',
    artifacts_6_1: 'Woodpecker Electro',
    artifacts_6_2: 'Hormone Punk',
    artifacts_6_3: '',
    artifacts_6_app: 8.18,
    artifacts_6_round_sd: 180,
    artifacts_6_round_da: 20290,
    artifacts_7: 'Fanged Metal, Flex',
    artifacts_7_1: 'Fanged Metal',
    artifacts_7_2: 'Flex',
    artifacts_7_3: '',
    artifacts_7_app: 4.81,
    artifacts_7_round_sd: 600,
    artifacts_7_round_da: 9834,
    artifacts_8: 'Puffer Electro, Woodpecker Electro',
    artifacts_8_1: 'Puffer Electro',
    artifacts_8_2: 'Woodpecker Electro',
    artifacts_8_3: '',
    artifacts_8_app: 4.81,
    artifacts_8_round_sd: 600,
    artifacts_8_round_da: 11812,
    artifacts_9: 'Puffer Electro, Fanged Metal',
    artifacts_9_1: 'Puffer Electro',
    artifacts_9_2: 'Fanged Metal',
    artifacts_9_3: '',
    artifacts_9_app: 2.41,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 14253,
    artifacts_10: 'Hormone Punk, Woodpecker Electro',
    artifacts_10_1: 'Hormone Punk',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: '',
    artifacts_10_app: 2.41,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 9209
  },
  {
    char: 'grace-howard',
    app_rate_sd: 2.33,
    app_rate_sd_e1: 0.76,
    app_rate_sd_s0: 0.43,
    avg_round_sd: 154,
    avg_round_sd_e1: 116,
    avg_round_sd_s0: 186,
    sample_sd: 113,
    sample_size_players_sd: 0,
    app_rate_da: 5.59,
    app_rate_da_e1: 1.35,
    app_rate_da_s0: 1.55,
    avg_round_da: 20106,
    avg_round_da_e1: 27748,
    avg_round_da_s0: 15027,
    app_rate_da_boss_1: 2.93,
    avg_round_da_boss_1: 15529,
    app_rate_da_boss_2: 2.86,
    avg_round_da_boss_2: 24999,
    app_rate_da_boss_3: 0.21,
    avg_round_da_boss_3: 0,
    sample_da: 324,
    sample_size_players_da: 0,
    app_0: 46.32,
    round_0_sd: 161,
    round_0_da: 19852,
    app_1: 24.77,
    round_1_sd: 149,
    round_1_da: 19882,
    app_2: 21.54,
    round_2_sd: 146,
    round_2_da: 20952,
    app_3: 4.4,
    round_3_sd: 169,
    round_3_da: 16671,
    app_4: 1.79,
    round_4_sd: 70,
    round_4_da: 30259,
    app_5: 0.48,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 0.7,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.95,
    weapons_1: 'Fusion Compiler',
    weapons_1_app: 43.78,
    weapons_1_round_sd: 140,
    weapons_1_round_da: 20317,
    weapons_2: 'Weeping Gemini',
    weapons_2_app: 21.8,
    weapons_2_round_sd: 184,
    weapons_2_round_da: 21661,
    weapons_3: 'Rainforest Gourmet',
    weapons_3_app: 15.45,
    weapons_3_round_sd: 162,
    weapons_3_round_da: 17519,
    weapons_4: 'Electro-Lip Gloss',
    weapons_4_app: 6.89,
    weapons_4_round_sd: 131,
    weapons_4_round_da: 22611,
    weapons_5: 'Roaring Ride',
    weapons_5_app: 5.45,
    weapons_5_round_sd: 141,
    weapons_5_round_da: 17944,
    weapons_6: 'Timeweaver',
    weapons_6_app: 3.01,
    weapons_6_round_sd: 134,
    weapons_6_round_da: 27094,
    weapons_7: 'Sharpened Stinger',
    weapons_7_app: 0.26,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 0.0,
    weapons_8: 'Hailstorm Shrine',
    weapons_8_app: 0.26,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 0.0,
    weapons_9: 'Steel Cushion',
    weapons_9_app: 0.22,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 26960,
    weapons_10: 'Flamemaker Shaker',
    weapons_10_app: 0.22,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0,
    artifacts_1: 'Freedom Blues, Thunder Metal',
    artifacts_1_1: 'Freedom Blues',
    artifacts_1_2: 'Thunder Metal',
    artifacts_1_3: '',
    artifacts_1_app: 40.5,
    artifacts_1_round_sd: 160,
    artifacts_1_round_da: 19763,
    artifacts_2: 'Thunder Metal, Freedom Blues',
    artifacts_2_1: 'Thunder Metal',
    artifacts_2_2: 'Freedom Blues',
    artifacts_2_3: '',
    artifacts_2_app: 17.23,
    artifacts_2_round_sd: 170,
    artifacts_2_round_da: 18581,
    artifacts_3: 'Freedom Blues, Swing Jazz',
    artifacts_3_1: 'Freedom Blues',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 6.71,
    artifacts_3_round_sd: 154,
    artifacts_3_round_da: 19253,
    artifacts_4: 'Chaos Jazz, Freedom Blues',
    artifacts_4_1: 'Chaos Jazz',
    artifacts_4_2: 'Freedom Blues',
    artifacts_4_3: '',
    artifacts_4_app: 5.45,
    artifacts_4_round_sd: 112,
    artifacts_4_round_da: 20604,
    artifacts_5: 'Freedom Blues, Chaos Jazz',
    artifacts_5_1: 'Freedom Blues',
    artifacts_5_2: 'Chaos Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 3.41,
    artifacts_5_round_sd: 134,
    artifacts_5_round_da: 23885,
    artifacts_6: 'Thunder Metal, Chaos Jazz',
    artifacts_6_1: 'Thunder Metal',
    artifacts_6_2: 'Chaos Jazz',
    artifacts_6_3: '',
    artifacts_6_app: 2.97,
    artifacts_6_round_sd: 144,
    artifacts_6_round_da: 11932,
    artifacts_7: 'Chaos Jazz, Thunder Metal',
    artifacts_7_1: 'Chaos Jazz',
    artifacts_7_2: 'Thunder Metal',
    artifacts_7_3: '',
    artifacts_7_app: 2.19,
    artifacts_7_round_sd: 600,
    artifacts_7_round_da: 18779,
    artifacts_8: 'Freedom Blues, Flex',
    artifacts_8_1: 'Freedom Blues',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 2.09,
    artifacts_8_round_sd: 165,
    artifacts_8_round_da: 16368,
    artifacts_9: 'Chaos Jazz, Swing Jazz',
    artifacts_9_1: 'Chaos Jazz',
    artifacts_9_2: 'Swing Jazz',
    artifacts_9_3: '',
    artifacts_9_app: 1.31,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 22726,
    artifacts_10: 'AP +30, Thunder Metal, Flex',
    artifacts_10_1: 'AP +30',
    artifacts_10_2: 'Thunder Metal',
    artifacts_10_3: 'Flex',
    artifacts_10_app: 1.09,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 17770
  },
  {
    char: 'anby-demara',
    app_rate_sd: 3.11,
    app_rate_sd_e1: 0.72,
    app_rate_sd_s0: 0.91,
    avg_round_sd: 177,
    avg_round_sd_e1: 132,
    avg_round_sd_s0: 185,
    sample_sd: 151,
    sample_size_players_sd: 0,
    app_rate_da: 9.19,
    app_rate_da_e1: 1.43,
    app_rate_da_s0: 4.66,
    avg_round_da: 15796,
    avg_round_da_e1: 22159,
    avg_round_da_s0: 14352,
    app_rate_da_boss_1: 8.78,
    avg_round_da_boss_1: 16046,
    app_rate_da_boss_2: 0.46,
    avg_round_da_boss_2: 14608,
    app_rate_da_boss_3: 0.72,
    avg_round_da_boss_3: 13426,
    sample_da: 532,
    sample_size_players_da: 0,
    app_0: 1.33,
    round_0_sd: 208,
    round_0_da: 0,
    app_1: 0.95,
    round_1_sd: 600,
    round_1_da: 14624,
    app_2: 1.13,
    round_2_sd: 600,
    round_2_da: 10068,
    app_3: 1.95,
    round_3_sd: 180,
    round_3_da: 16282,
    app_4: 3.52,
    round_4_sd: 200,
    round_4_da: 13895,
    app_5: 2.2,
    round_5_sd: 147,
    round_5_da: 17348,
    app_6: 90.44,
    round_6_sd: 176,
    round_6_da: 15930,
    cons_avg: 5.76,
    weapons_1: 'Demara Battery Mark II',
    weapons_1_app: 66.91,
    weapons_1_round_sd: 178,
    weapons_1_round_da: 15455,
    weapons_2: 'Steam Oven',
    weapons_2_app: 12.3,
    weapons_2_round_sd: 176,
    weapons_2_round_da: 16890,
    weapons_3: 'Hellfire Gears',
    weapons_3_app: 9.27,
    weapons_3_round_sd: 172,
    weapons_3_round_da: 17345,
    weapons_4: 'The Restrained',
    weapons_4_app: 4.53,
    weapons_4_round_sd: 170,
    weapons_4_round_da: 16964,
    weapons_5: 'Precious Fossilized Core',
    weapons_5_app: 1.87,
    weapons_5_round_sd: 252,
    weapons_5_round_da: 13120,
    weapons_6: 'Six Shooter',
    weapons_6_app: 1.08,
    weapons_6_round_sd: 170,
    weapons_6_round_da: 18766,
    weapons_7: 'Marcato Desire',
    weapons_7_app: 0.28,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 21034,
    weapons_8: '[Vortex] Arrow',
    weapons_8_app: 0.28,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 14218,
    weapons_9: 'Hailstorm Shrine',
    weapons_9_app: 0.17,
    weapons_9_round_sd: 119,
    weapons_9_round_da: 0.0,
    weapons_10: 'Original Transmorpher',
    weapons_10_app: 0.17,
    weapons_10_round_sd: 209,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Shockstar Disco, Swing Jazz',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 42.06,
    artifacts_1_round_sd: 173,
    artifacts_1_round_da: 16809,
    artifacts_2: 'Shockstar Disco, Thunder Metal',
    artifacts_2_1: 'Shockstar Disco',
    artifacts_2_2: 'Thunder Metal',
    artifacts_2_3: '',
    artifacts_2_app: 31.76,
    artifacts_2_round_sd: 192,
    artifacts_2_round_da: 14682,
    artifacts_3: 'Shockstar Disco, Woodpecker Electro',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 5.5,
    artifacts_3_round_sd: 179,
    artifacts_3_round_da: 16694,
    artifacts_4: 'Astral Voice, Shockstar Disco',
    artifacts_4_1: 'Astral Voice',
    artifacts_4_2: 'Shockstar Disco',
    artifacts_4_3: '',
    artifacts_4_app: 1.81,
    artifacts_4_round_sd: 166,
    artifacts_4_round_da: 18811,
    artifacts_5: 'Shockstar Disco, Swing Jazz, Flex',
    artifacts_5_1: 'Shockstar Disco',
    artifacts_5_2: 'Swing Jazz',
    artifacts_5_3: 'Flex',
    artifacts_5_app: 1.7,
    artifacts_5_round_sd: 600,
    artifacts_5_round_da: 14695,
    artifacts_6: 'Shockstar Disco, Flex',
    artifacts_6_1: 'Shockstar Disco',
    artifacts_6_2: 'Flex',
    artifacts_6_3: '',
    artifacts_6_app: 1.7,
    artifacts_6_round_sd: 600,
    artifacts_6_round_da: 14421,
    artifacts_7: 'Shockstar Disco, Hormone Punk',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Hormone Punk',
    artifacts_7_3: '',
    artifacts_7_app: 1.33,
    artifacts_7_round_sd: 124,
    artifacts_7_round_da: 14728,
    artifacts_8: 'Shockstar Disco, Thunder Metal, Flex',
    artifacts_8_1: 'Shockstar Disco',
    artifacts_8_2: 'Thunder Metal',
    artifacts_8_3: 'Flex',
    artifacts_8_app: 1.27,
    artifacts_8_round_sd: 600,
    artifacts_8_round_da: 17904,
    artifacts_9: 'Swing Jazz, Shockstar Disco',
    artifacts_9_1: 'Swing Jazz',
    artifacts_9_2: 'Shockstar Disco',
    artifacts_9_3: '',
    artifacts_9_app: 0.74,
    artifacts_9_round_sd: 150,
    artifacts_9_round_da: 15995,
    artifacts_10: 'Shockstar Disco, Flex',
    artifacts_10_1: 'Shockstar Disco',
    artifacts_10_2: 'Flex',
    artifacts_10_3: '',
    artifacts_10_app: 0.71,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 12096
  },
  {
    char: 'nicole-demara',
    app_rate_sd: 28.39,
    app_rate_sd_e1: 8.3,
    app_rate_sd_s0: 6.73,
    avg_round_sd: 129,
    avg_round_sd_e1: 97,
    avg_round_sd_s0: 143,
    sample_sd: 1379,
    sample_size_players_sd: 0,
    app_rate_da: 54.61,
    app_rate_da_e1: 14.02,
    app_rate_da_s0: 19.53,
    avg_round_da: 23391,
    avg_round_da_e1: 32414,
    avg_round_da_s0: 21388,
    app_rate_da_boss_1: 42.52,
    avg_round_da_boss_1: 23051,
    app_rate_da_boss_2: 9.52,
    avg_round_da_boss_2: 27536,
    app_rate_da_boss_3: 7.14,
    avg_round_da_boss_3: 20975,
    sample_da: 3163,
    sample_size_players_da: 0,
    app_0: 0.15,
    round_0_sd: 208,
    round_0_da: 0,
    app_1: 0.09,
    round_1_sd: 115,
    round_1_da: 18570,
    app_2: 0.28,
    round_2_sd: 152,
    round_2_da: 19664,
    app_3: 0.71,
    round_3_sd: 178,
    round_3_da: 16690,
    app_4: 0.79,
    round_4_sd: 158,
    round_4_da: 18716,
    app_5: 1.26,
    round_5_sd: 115,
    round_5_da: 21032,
    app_6: 96.82,
    round_6_sd: 128,
    round_6_da: 23555,
    cons_avg: 5.93,
    weapons_1: 'The Vault',
    weapons_1_app: 91.44,
    weapons_1_round_sd: 128,
    weapons_1_round_da: 23546,
    weapons_2: 'Weeping Cradle',
    weapons_2_app: 3.01,
    weapons_2_round_sd: 125,
    weapons_2_round_da: 23433,
    weapons_3: 'Kaboom the Cannon',
    weapons_3_app: 1.91,
    weapons_3_round_sd: 124,
    weapons_3_round_da: 22927,
    weapons_4: 'Slice of Time',
    weapons_4_app: 1.43,
    weapons_4_round_sd: 156,
    weapons_4_round_da: 21242,
    weapons_5: '[Reverb] Mark II',
    weapons_5_app: 0.69,
    weapons_5_round_sd: 153,
    weapons_5_round_da: 20922,
    weapons_6: 'Unfettered Game Ball',
    weapons_6_app: 0.44,
    weapons_6_round_sd: 204,
    weapons_6_round_da: 21464,
    weapons_7: 'Bashful Demon',
    weapons_7_app: 0.13,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 23783,
    weapons_8: '[Reverb] Mark III',
    weapons_8_app: 0.09,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 17059,
    weapons_9: 'Elegant Vanity',
    weapons_9_app: 0.04,
    weapons_9_round_sd: 95,
    weapons_9_round_da: 20493,
    weapons_10: 'Rainforest Gourmet',
    weapons_10_app: 0.02,
    weapons_10_round_sd: 218,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Swing Jazz, Chaotic Metal',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Chaotic Metal',
    artifacts_1_3: '',
    artifacts_1_app: 35.45,
    artifacts_1_round_sd: 133,
    artifacts_1_round_da: 22601,
    artifacts_2: 'Swing Jazz, Freedom Blues',
    artifacts_2_1: 'Swing Jazz',
    artifacts_2_2: 'Freedom Blues',
    artifacts_2_3: '',
    artifacts_2_app: 18.63,
    artifacts_2_round_sd: 130,
    artifacts_2_round_da: 24041,
    artifacts_3: 'Astral Voice, Swing Jazz',
    artifacts_3_1: 'Astral Voice',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 10.72,
    artifacts_3_round_sd: 136,
    artifacts_3_round_da: 23448,
    artifacts_4: 'Freedom Blues, Swing Jazz',
    artifacts_4_1: 'Freedom Blues',
    artifacts_4_2: 'Swing Jazz',
    artifacts_4_3: '',
    artifacts_4_app: 5.32,
    artifacts_4_round_sd: 109,
    artifacts_4_round_da: 28319,
    artifacts_5: 'Swing Jazz, Astral Voice',
    artifacts_5_1: 'Swing Jazz',
    artifacts_5_2: 'Astral Voice',
    artifacts_5_3: '',
    artifacts_5_app: 3.16,
    artifacts_5_round_sd: 113,
    artifacts_5_round_da: 27091,
    artifacts_6: 'Chaotic Metal, Swing Jazz',
    artifacts_6_1: 'Chaotic Metal',
    artifacts_6_2: 'Swing Jazz',
    artifacts_6_3: '',
    artifacts_6_app: 2.93,
    artifacts_6_round_sd: 129,
    artifacts_6_round_da: 18961,
    artifacts_7: 'Swing Jazz, Woodpecker Electro',
    artifacts_7_1: 'Swing Jazz',
    artifacts_7_2: 'Woodpecker Electro',
    artifacts_7_3: '',
    artifacts_7_app: 2.87,
    artifacts_7_round_sd: 126,
    artifacts_7_round_da: 23637,
    artifacts_8: 'Swing Jazz, Flex',
    artifacts_8_1: 'Swing Jazz',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 2.38,
    artifacts_8_round_sd: 115,
    artifacts_8_round_da: 21379,
    artifacts_9: 'Swing Jazz, Chaos Jazz',
    artifacts_9_1: 'Swing Jazz',
    artifacts_9_2: 'Chaos Jazz',
    artifacts_9_3: '',
    artifacts_9_app: 2.17,
    artifacts_9_round_sd: 124,
    artifacts_9_round_da: 26194,
    artifacts_10: 'Swing Jazz, Hormone Punk',
    artifacts_10_1: 'Swing Jazz',
    artifacts_10_2: 'Hormone Punk',
    artifacts_10_3: '',
    artifacts_10_app: 1.85,
    artifacts_10_round_sd: 125,
    artifacts_10_round_da: 24696
  },
  {
    char: 'piper',
    app_rate_sd: 7.99,
    app_rate_sd_e1: 1.56,
    app_rate_sd_s0: 2.59,
    avg_round_sd: 142,
    avg_round_sd_e1: 109,
    avg_round_sd_s0: 150,
    sample_sd: 388,
    sample_size_players_sd: 0,
    app_rate_da: 11.48,
    app_rate_da_e1: 2.21,
    app_rate_da_s0: 4.18,
    avg_round_da: 18319,
    avg_round_da_e1: 24835,
    avg_round_da_s0: 16084,
    app_rate_da_boss_1: 1.14,
    avg_round_da_boss_1: 16963,
    app_rate_da_boss_2: 2.86,
    avg_round_da_boss_2: 22000,
    app_rate_da_boss_3: 8.69,
    avg_round_da_boss_3: 17410,
    sample_da: 665,
    sample_size_players_da: 0,
    app_0: 0.2,
    round_0_sd: 167,
    round_0_da: 11443,
    app_1: 0.52,
    round_1_sd: 185,
    round_1_da: 0,
    app_2: 0.78,
    round_2_sd: 141,
    round_2_da: 12815,
    app_3: 1.58,
    round_3_sd: 142,
    round_3_da: 16702,
    app_4: 2.68,
    round_4_sd: 160,
    round_4_da: 15200,
    app_5: 3.43,
    round_5_sd: 148,
    round_5_da: 17348,
    app_6: 91.11,
    round_6_sd: 141,
    round_6_da: 18566,
    cons_avg: 5.81,
    weapons_1: 'Roaring Ride',
    weapons_1_app: 89.23,
    weapons_1_round_sd: 142,
    weapons_1_round_da: 18371,
    weapons_2: 'Weeping Gemini',
    weapons_2_app: 3.07,
    weapons_2_round_sd: 152,
    weapons_2_round_da: 18027,
    weapons_3: 'Rainforest Gourmet',
    weapons_3_app: 2.58,
    weapons_3_round_sd: 158,
    weapons_3_round_da: 16025,
    weapons_4: 'Fusion Compiler',
    weapons_4_app: 1.71,
    weapons_4_round_sd: 152,
    weapons_4_round_da: 16532,
    weapons_5: 'Electro-Lip Gloss',
    weapons_5_app: 1.06,
    weapons_5_round_sd: 125,
    weapons_5_round_da: 21020,
    weapons_6: 'Sharpened Stinger',
    weapons_6_app: 1.06,
    weapons_6_round_sd: 129,
    weapons_6_round_da: 19867,
    weapons_7: 'Flamemaker Shaker',
    weapons_7_app: 0.18,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 17584,
    weapons_8: '[Magnetic Storm] Bravo',
    weapons_8_app: 0.09,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 23098,
    weapons_9: 'Timeweaver',
    weapons_9_app: 0.09,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Freedom Blues, Fanged Metal',
    artifacts_1_1: 'Freedom Blues',
    artifacts_1_2: 'Fanged Metal',
    artifacts_1_3: '',
    artifacts_1_app: 31.05,
    artifacts_1_round_sd: 150,
    artifacts_1_round_da: 17575,
    artifacts_2: 'Fanged Metal, Freedom Blues',
    artifacts_2_1: 'Fanged Metal',
    artifacts_2_2: 'Freedom Blues',
    artifacts_2_3: '',
    artifacts_2_app: 27.8,
    artifacts_2_round_sd: 140,
    artifacts_2_round_da: 18564,
    artifacts_3: 'Freedom Blues, Swing Jazz',
    artifacts_3_1: 'Freedom Blues',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 12.7,
    artifacts_3_round_sd: 134,
    artifacts_3_round_da: 19136,
    artifacts_4: 'Fanged Metal, Chaos Jazz',
    artifacts_4_1: 'Fanged Metal',
    artifacts_4_2: 'Chaos Jazz',
    artifacts_4_3: '',
    artifacts_4_app: 8.69,
    artifacts_4_round_sd: 125,
    artifacts_4_round_da: 19839,
    artifacts_5: 'Freedom Blues, Chaos Jazz',
    artifacts_5_1: 'Freedom Blues',
    artifacts_5_2: 'Chaos Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 4.54,
    artifacts_5_round_sd: 148,
    artifacts_5_round_da: 18362,
    artifacts_6: 'Freedom Blues, Hormone Punk',
    artifacts_6_1: 'Freedom Blues',
    artifacts_6_2: 'Hormone Punk',
    artifacts_6_3: '',
    artifacts_6_app: 1.81,
    artifacts_6_round_sd: 129,
    artifacts_6_round_da: 20367,
    artifacts_7: 'Freedom Blues, Flex',
    artifacts_7_1: 'Freedom Blues',
    artifacts_7_2: 'Flex',
    artifacts_7_3: '',
    artifacts_7_app: 1.48,
    artifacts_7_round_sd: 136,
    artifacts_7_round_da: 17083,
    artifacts_8: 'Fanged Metal, Hormone Punk',
    artifacts_8_1: 'Fanged Metal',
    artifacts_8_2: 'Hormone Punk',
    artifacts_8_3: '',
    artifacts_8_app: 1.33,
    artifacts_8_round_sd: 164,
    artifacts_8_round_da: 17618,
    artifacts_9: 'Freedom Blues, Astral Voice',
    artifacts_9_1: 'Freedom Blues',
    artifacts_9_2: 'Astral Voice',
    artifacts_9_3: '',
    artifacts_9_app: 1.03,
    artifacts_9_round_sd: 156,
    artifacts_9_round_da: 17033,
    artifacts_10: 'Fanged Metal, Flex',
    artifacts_10_1: 'Fanged Metal',
    artifacts_10_2: 'Flex',
    artifacts_10_3: '',
    artifacts_10_app: 0.54,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 15033
  },
  {
    char: 'nekomata',
    app_rate_sd: 0.49,
    app_rate_sd_e1: 0.16,
    app_rate_sd_s0: 0.21,
    avg_round_sd: 203,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 600,
    sample_sd: 24,
    sample_size_players_sd: 0,
    app_rate_da: 1.35,
    app_rate_da_e1: 0.26,
    app_rate_da_s0: 0.81,
    avg_round_da: 16803,
    avg_round_da_e1: 0,
    avg_round_da_s0: 15470,
    app_rate_da_boss_1: 0.75,
    avg_round_da_boss_1: 18805,
    app_rate_da_boss_2: 0.04,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 0.7,
    avg_round_da_boss_3: 14724,
    sample_da: 78,
    sample_size_players_da: 0,
    app_0: 23.65,
    round_0_sd: 212,
    round_0_da: 12438,
    app_1: 43.5,
    round_1_sd: 186,
    round_1_da: 17285,
    app_2: 17.78,
    round_2_sd: 223,
    round_2_da: 19642,
    app_3: 6.03,
    round_3_sd: 224,
    round_3_da: 16546,
    app_4: 3.02,
    round_4_sd: 600,
    round_4_da: 23905,
    app_5: 2.06,
    round_5_sd: 170,
    round_5_da: 0,
    app_6: 3.97,
    round_6_sd: 600,
    round_6_da: 41496,
    cons_avg: 1.43,
    weapons_1: 'Steel Cushion',
    weapons_1_app: 67.3,
    weapons_1_round_sd: 212,
    weapons_1_round_da: 17350,
    weapons_2: 'The Brimstone',
    weapons_2_app: 11.11,
    weapons_2_round_sd: 207,
    weapons_2_round_da: 17608,
    weapons_3: 'Starlight Engine',
    weapons_3_app: 10.8,
    weapons_3_round_sd: 165,
    weapons_3_round_da: 16627,
    weapons_4: 'Marcato Desire',
    weapons_4_app: 5.87,
    weapons_4_round_sd: 172,
    weapons_4_round_da: 14066,
    weapons_5: 'Heartstring Nocturne',
    weapons_5_app: 1.11,
    weapons_5_round_sd: 170,
    weapons_5_round_da: 0.0,
    weapons_6: 'Gilded Blossom',
    weapons_6_app: 0.95,
    weapons_6_round_sd: 600,
    weapons_6_round_da: 12513,
    weapons_7: 'Street Superstar',
    weapons_7_app: 0.95,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 14731,
    weapons_8: 'Housekeeper',
    weapons_8_app: 0.95,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 6455,
    weapons_9: '',
    weapons_9_app: 0.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0.0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Fanged Metal, Woodpecker Electro',
    artifacts_1_1: 'Fanged Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 23.49,
    artifacts_1_round_sd: 218,
    artifacts_1_round_da: 14891,
    artifacts_2: 'Woodpecker Electro, Fanged Metal',
    artifacts_2_1: 'Woodpecker Electro',
    artifacts_2_2: 'Fanged Metal',
    artifacts_2_3: '',
    artifacts_2_app: 23.02,
    artifacts_2_round_sd: 227,
    artifacts_2_round_da: 20297,
    artifacts_3: 'Branch & Blade Song, Fanged Metal, Woodpecker Electro',
    artifacts_3_1: 'Branch & Blade Song',
    artifacts_3_2: 'Fanged Metal',
    artifacts_3_3: 'Woodpecker Electro',
    artifacts_3_app: 9.05,
    artifacts_3_round_sd: 178,
    artifacts_3_round_da: 18644,
    artifacts_4: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_4_1: 'Woodpecker Electro',
    artifacts_4_2: 'Branch & Blade Song',
    artifacts_4_3: '',
    artifacts_4_app: 8.73,
    artifacts_4_round_sd: 600,
    artifacts_4_round_da: 23161,
    artifacts_5: 'Fanged Metal, Hormone Punk',
    artifacts_5_1: 'Fanged Metal',
    artifacts_5_2: 'Hormone Punk',
    artifacts_5_3: '',
    artifacts_5_app: 7.78,
    artifacts_5_round_sd: 222,
    artifacts_5_round_da: 15003,
    artifacts_6: 'Fanged Metal, Branch & Blade Song',
    artifacts_6_1: 'Fanged Metal',
    artifacts_6_2: 'Branch & Blade Song',
    artifacts_6_3: '',
    artifacts_6_app: 7.14,
    artifacts_6_round_sd: 167,
    artifacts_6_round_da: 17048,
    artifacts_7: 'Fanged Metal, Flex',
    artifacts_7_1: 'Fanged Metal',
    artifacts_7_2: 'Flex',
    artifacts_7_3: '',
    artifacts_7_app: 3.02,
    artifacts_7_round_sd: 186,
    artifacts_7_round_da: 10504,
    artifacts_8: 'Woodpecker Electro, Astral Voice',
    artifacts_8_1: 'Woodpecker Electro',
    artifacts_8_2: 'Astral Voice',
    artifacts_8_3: '',
    artifacts_8_app: 2.06,
    artifacts_8_round_sd: 168,
    artifacts_8_round_da: 20077,
    artifacts_9: 'Fanged Metal, Woodpecker Electro, Flex',
    artifacts_9_1: 'Fanged Metal',
    artifacts_9_2: 'Woodpecker Electro',
    artifacts_9_3: 'Flex',
    artifacts_9_app: 1.91,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 8854,
    artifacts_10: 'Woodpecker Electro, Puffer Electro',
    artifacts_10_1: 'Woodpecker Electro',
    artifacts_10_2: 'Puffer Electro',
    artifacts_10_3: '',
    artifacts_10_app: 1.11,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'zhu-yuan',
    app_rate_sd: 5.37,
    app_rate_sd_e1: 1.81,
    app_rate_sd_s0: 1.59,
    avg_round_sd: 155,
    avg_round_sd_e1: 129,
    avg_round_sd_s0: 158,
    sample_sd: 261,
    sample_size_players_sd: 0,
    app_rate_da: 29.92,
    app_rate_da_e1: 7.79,
    app_rate_da_s0: 12.0,
    avg_round_da: 22959,
    avg_round_da_e1: 28807,
    avg_round_da_s0: 22251,
    app_rate_da_boss_1: 30.6,
    avg_round_da_boss_1: 23398,
    app_rate_da_boss_2: 0.44,
    avg_round_da_boss_2: 15422,
    app_rate_da_boss_3: 1.38,
    avg_round_da_boss_3: 15781,
    sample_da: 1733,
    sample_size_players_da: 0,
    app_0: 80.75,
    round_0_sd: 155,
    round_0_da: 22959,
    app_1: 15.13,
    round_1_sd: 135,
    round_1_da: 26464,
    app_2: 2.09,
    round_2_sd: 108,
    round_2_da: 29763,
    app_3: 0.23,
    round_3_sd: 600,
    round_3_da: 33844,
    app_4: 0.35,
    round_4_sd: 600,
    round_4_da: 37952,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.53,
    round_6_sd: 104,
    round_6_da: 43418,
    cons_avg: 0.3,
    weapons_1: 'Riot Suppressor Mark VI',
    weapons_1_app: 43.49,
    weapons_1_round_sd: 156,
    weapons_1_round_da: 23586,
    weapons_2: 'Starlight Engine',
    weapons_2_app: 24.97,
    weapons_2_round_sd: 155,
    weapons_2_round_da: 22890,
    weapons_3: 'The Brimstone',
    weapons_3_app: 13.99,
    weapons_3_round_sd: 157,
    weapons_3_round_da: 24364,
    weapons_4: 'Marcato Desire',
    weapons_4_app: 5.98,
    weapons_4_round_sd: 160,
    weapons_4_round_da: 23014,
    weapons_5: 'Gilded Blossom',
    weapons_5_app: 3.33,
    weapons_5_round_sd: 142,
    weapons_5_round_da: 19989,
    weapons_6: 'Cannon Rotor',
    weapons_6_app: 3.22,
    weapons_6_round_sd: 158,
    weapons_6_round_da: 20371,
    weapons_7: 'Steel Cushion',
    weapons_7_app: 1.17,
    weapons_7_round_sd: 155,
    weapons_7_round_da: 19886,
    weapons_8: 'Street Superstar',
    weapons_8_app: 0.66,
    weapons_8_round_sd: 160,
    weapons_8_round_da: 20684,
    weapons_9: 'Severed Innocence',
    weapons_9_app: 0.32,
    weapons_9_round_sd: 147,
    weapons_9_round_da: 18381,
    weapons_10: 'Heartstring Nocturne',
    weapons_10_app: 0.25,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 21749,
    artifacts_1: 'Chaotic Metal, Woodpecker Electro',
    artifacts_1_1: 'Chaotic Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 72.81,
    artifacts_1_round_sd: 156,
    artifacts_1_round_da: 22667,
    artifacts_2: 'Chaotic Metal, Branch & Blade Song',
    artifacts_2_1: 'Chaotic Metal',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 7.79,
    artifacts_2_round_sd: 140,
    artifacts_2_round_da: 26188,
    artifacts_3: 'Chaotic Metal, Hormone Punk',
    artifacts_3_1: 'Chaotic Metal',
    artifacts_3_2: 'Hormone Punk',
    artifacts_3_3: '',
    artifacts_3_app: 3.69,
    artifacts_3_round_sd: 141,
    artifacts_3_round_da: 23532,
    artifacts_4: 'Chaotic Metal, Flex',
    artifacts_4_1: 'Chaotic Metal',
    artifacts_4_2: 'Flex',
    artifacts_4_3: '',
    artifacts_4_app: 2.91,
    artifacts_4_round_sd: 165,
    artifacts_4_round_da: 23087,
    artifacts_5: 'Chaotic Metal, Swing Jazz',
    artifacts_5_1: 'Chaotic Metal',
    artifacts_5_2: 'Swing Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 2.64,
    artifacts_5_round_sd: 188,
    artifacts_5_round_da: 20353,
    artifacts_6: 'Chaotic Metal, Astral Voice',
    artifacts_6_1: 'Chaotic Metal',
    artifacts_6_2: 'Astral Voice',
    artifacts_6_3: '',
    artifacts_6_app: 1.77,
    artifacts_6_round_sd: 168,
    artifacts_6_round_da: 25200,
    artifacts_7: 'Woodpecker Electro, Chaotic Metal',
    artifacts_7_1: 'Woodpecker Electro',
    artifacts_7_2: 'Chaotic Metal',
    artifacts_7_3: '',
    artifacts_7_app: 1.71,
    artifacts_7_round_sd: 144,
    artifacts_7_round_da: 24606,
    artifacts_8: 'Chaotic Metal, Puffer Electro',
    artifacts_8_1: 'Chaotic Metal',
    artifacts_8_2: 'Puffer Electro',
    artifacts_8_3: '',
    artifacts_8_app: 1.01,
    artifacts_8_round_sd: 150,
    artifacts_8_round_da: 21628,
    artifacts_9: 'Hormone Punk, Woodpecker Electro',
    artifacts_9_1: 'Hormone Punk',
    artifacts_9_2: 'Woodpecker Electro',
    artifacts_9_3: '',
    artifacts_9_app: 0.64,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 27112,
    artifacts_10: 'Hormone Punk, Chaotic Metal',
    artifacts_10_1: 'Hormone Punk',
    artifacts_10_2: 'Chaotic Metal',
    artifacts_10_3: '',
    artifacts_10_app: 0.59,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 22828
  },
  {
    char: 'lycaon',
    app_rate_sd: 12.27,
    app_rate_sd_e1: 6.09,
    app_rate_sd_s0: 0.66,
    avg_round_sd: 142,
    avg_round_sd_e1: 107,
    avg_round_sd_s0: 185,
    sample_sd: 596,
    sample_size_players_sd: 0,
    app_rate_da: 29.61,
    app_rate_da_e1: 11.6,
    app_rate_da_s0: 2.71,
    avg_round_da: 22314,
    avg_round_da_e1: 30758,
    avg_round_da_s0: 16044,
    app_rate_da_boss_1: 2.73,
    avg_round_da_boss_1: 14608,
    app_rate_da_boss_2: 26.77,
    avg_round_da_boss_2: 23903,
    app_rate_da_boss_3: 2.13,
    avg_round_da_boss_3: 12897,
    sample_da: 1715,
    sample_size_players_da: 0,
    app_0: 46.95,
    round_0_sd: 141,
    round_0_da: 22895,
    app_1: 35.51,
    round_1_sd: 141,
    round_1_da: 21887,
    app_2: 12.07,
    round_2_sd: 143,
    round_2_da: 21610,
    app_3: 4.2,
    round_3_sd: 174,
    round_3_da: 21319,
    app_4: 0.83,
    round_4_sd: 139,
    round_4_da: 19392,
    app_5: 0.32,
    round_5_sd: 600,
    round_5_da: 20227,
    app_6: 0.18,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.78,
    weapons_1: 'The Restrained',
    weapons_1_app: 37.35,
    weapons_1_round_sd: 146,
    weapons_1_round_da: 21217,
    weapons_2: 'Steam Oven',
    weapons_2_app: 26.19,
    weapons_2_round_sd: 142,
    weapons_2_round_da: 23928,
    weapons_3: 'Precious Fossilized Core',
    weapons_3_app: 14.64,
    weapons_3_round_sd: 140,
    weapons_3_round_da: 21815,
    weapons_4: 'Hellfire Gears',
    weapons_4_app: 13.49,
    weapons_4_round_sd: 134,
    weapons_4_round_da: 23466,
    weapons_5: 'Six Shooter',
    weapons_5_app: 2.61,
    weapons_5_round_sd: 154,
    weapons_5_round_da: 20045,
    weapons_6: 'Demara Battery Mark II',
    weapons_6_app: 1.26,
    weapons_6_round_sd: 160,
    weapons_6_round_da: 20262,
    weapons_7: '[Vortex] Arrow',
    weapons_7_app: 0.79,
    weapons_7_round_sd: 134,
    weapons_7_round_da: 19155,
    weapons_8: 'Blazing Laurel',
    weapons_8_app: 0.46,
    weapons_8_round_sd: 108,
    weapons_8_round_da: 25105,
    weapons_9: 'Ice-Jade Teapot',
    weapons_9_app: 0.18,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 6018,
    weapons_10: 'Box Cutter',
    weapons_10_app: 0.08,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 23481,
    artifacts_1: 'Shockstar Disco, Swing Jazz',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 37.2,
    artifacts_1_round_sd: 139,
    artifacts_1_round_da: 22263,
    artifacts_2: 'Shockstar Disco, Polar Metal',
    artifacts_2_1: 'Shockstar Disco',
    artifacts_2_2: 'Polar Metal',
    artifacts_2_3: '',
    artifacts_2_app: 32.62,
    artifacts_2_round_sd: 148,
    artifacts_2_round_da: 20229,
    artifacts_3: 'Shockstar Disco, Woodpecker Electro',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 4.96,
    artifacts_3_round_sd: 148,
    artifacts_3_round_da: 23278,
    artifacts_4: 'Shockstar Disco, Flex',
    artifacts_4_1: 'Shockstar Disco',
    artifacts_4_2: 'Flex',
    artifacts_4_3: '',
    artifacts_4_app: 2.41,
    artifacts_4_round_sd: 172,
    artifacts_4_round_da: 19679,
    artifacts_5: 'Freedom Blues, Shockstar Disco',
    artifacts_5_1: 'Freedom Blues',
    artifacts_5_2: 'Shockstar Disco',
    artifacts_5_3: '',
    artifacts_5_app: 2.28,
    artifacts_5_round_sd: 126,
    artifacts_5_round_da: 28584,
    artifacts_6: 'Swing Jazz, Shockstar Disco',
    artifacts_6_1: 'Swing Jazz',
    artifacts_6_2: 'Shockstar Disco',
    artifacts_6_3: '',
    artifacts_6_app: 2.24,
    artifacts_6_round_sd: 138,
    artifacts_6_round_da: 26284,
    artifacts_7: 'Astral Voice, Shockstar Disco',
    artifacts_7_1: 'Astral Voice',
    artifacts_7_2: 'Shockstar Disco',
    artifacts_7_3: '',
    artifacts_7_app: 2.1,
    artifacts_7_round_sd: 91,
    artifacts_7_round_da: 35718,
    artifacts_8: 'Shockstar Disco, Branch & Blade Song',
    artifacts_8_1: 'Shockstar Disco',
    artifacts_8_2: 'Branch & Blade Song',
    artifacts_8_3: '',
    artifacts_8_app: 1.5,
    artifacts_8_round_sd: 130,
    artifacts_8_round_da: 25744,
    artifacts_9: 'Shockstar Disco, Freedom Blues',
    artifacts_9_1: 'Shockstar Disco',
    artifacts_9_2: 'Freedom Blues',
    artifacts_9_3: '',
    artifacts_9_app: 1.5,
    artifacts_9_round_sd: 163,
    artifacts_9_round_da: 22749,
    artifacts_10: 'Proto Punk, Shockstar Disco',
    artifacts_10_1: 'Proto Punk',
    artifacts_10_2: 'Shockstar Disco',
    artifacts_10_3: '',
    artifacts_10_app: 1.2,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 26289
  },
  {
    char: 'anton',
    app_rate_sd: 0.41,
    app_rate_sd_e1: 0.06,
    app_rate_sd_s0: 0.21,
    avg_round_sd: 154,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 600,
    sample_sd: 20,
    sample_size_players_sd: 0,
    app_rate_da: 0.62,
    app_rate_da_e1: 0.12,
    app_rate_da_s0: 0.38,
    avg_round_da: 22911,
    avg_round_da_e1: 0,
    avg_round_da_s0: 23671,
    app_rate_da_boss_1: 0.65,
    avg_round_da_boss_1: 23279,
    app_rate_da_boss_2: 0.0,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 0.02,
    avg_round_da_boss_3: 0,
    sample_da: 36,
    sample_size_players_da: 0,
    app_0: 1,
    round_0_sd: 600,
    round_0_da: 0,
    app_1: 1,
    round_1_sd: 600,
    round_1_da: 0,
    app_2: 1,
    round_2_sd: 600,
    round_2_da: 0,
    app_3: 3.66,
    round_3_sd: 106,
    round_3_da: 31339,
    app_4: 1,
    round_4_sd: 600,
    round_4_da: 0,
    app_5: 5.0,
    round_5_sd: 211,
    round_5_da: 0,
    app_6: 94.35,
    round_6_sd: 154,
    round_6_da: 22610,
    cons_avg: 5.87,
    weapons_1: 'Drill Rig - Red Axis',
    weapons_1_app: 37.02,
    weapons_1_round_sd: 185,
    weapons_1_round_da: 18500,
    weapons_2: 'Starlight Engine',
    weapons_2_app: 19.66,
    weapons_2_round_sd: 147,
    weapons_2_round_da: 27907,
    weapons_3: 'The Brimstone',
    weapons_3_app: 14.65,
    weapons_3_round_sd: 141,
    weapons_3_round_da: 22464,
    weapons_4: 'Marcato Desire',
    weapons_4_app: 7.0,
    weapons_4_round_sd: 600,
    weapons_4_round_da: 21456,
    weapons_5: 'Street Superstar',
    weapons_5_app: 5.65,
    weapons_5_round_sd: 150,
    weapons_5_round_da: 30795,
    weapons_6: 'Steel Cushion',
    weapons_6_app: 3.66,
    weapons_6_round_sd: 184,
    weapons_6_round_da: 16078,
    weapons_7: 'Heartstring Nocturne',
    weapons_7_app: 3.66,
    weapons_7_round_sd: 75,
    weapons_7_round_da: 47614,
    weapons_8: 'Zanshin Herb Case',
    weapons_8_app: 3.66,
    weapons_8_round_sd: 134,
    weapons_8_round_da: 23753,
    weapons_9: 'Severed Innocence',
    weapons_9_app: 1.67,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 25590,
    weapons_10: 'Cannon Rotor',
    weapons_10_app: 1.67,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 22768,
    artifacts_1: 'Thunder Metal, Woodpecker Electro',
    artifacts_1_1: 'Thunder Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 41.32,
    artifacts_1_round_sd: 159,
    artifacts_1_round_da: 21778,
    artifacts_2: 'Thunder Metal, Hormone Punk',
    artifacts_2_1: 'Thunder Metal',
    artifacts_2_2: 'Hormone Punk',
    artifacts_2_3: '',
    artifacts_2_app: 9.32,
    artifacts_2_round_sd: 156,
    artifacts_2_round_da: 19916,
    artifacts_3: 'Branch & Blade Song, Thunder Metal, Woodpecker Electro',
    artifacts_3_1: 'Branch & Blade Song',
    artifacts_3_2: 'Thunder Metal',
    artifacts_3_3: 'Woodpecker Electro',
    artifacts_3_app: 7.0,
    artifacts_3_round_sd: 180,
    artifacts_3_round_da: 17130,
    artifacts_4: 'Thunder Metal, Branch & Blade Song',
    artifacts_4_1: 'Thunder Metal',
    artifacts_4_2: 'Branch & Blade Song',
    artifacts_4_3: '',
    artifacts_4_app: 5.34,
    artifacts_4_round_sd: 600,
    artifacts_4_round_da: 27178,
    artifacts_5: 'Hormone Punk, Woodpecker Electro',
    artifacts_5_1: 'Hormone Punk',
    artifacts_5_2: 'Woodpecker Electro',
    artifacts_5_3: '',
    artifacts_5_app: 5.34,
    artifacts_5_round_sd: 96,
    artifacts_5_round_da: 33121,
    artifacts_6: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_6_1: 'Woodpecker Electro',
    artifacts_6_2: 'Branch & Blade Song',
    artifacts_6_3: '',
    artifacts_6_app: 3.66,
    artifacts_6_round_sd: 600,
    artifacts_6_round_da: 0,
    artifacts_7: 'Hormone Punk, Astral Voice',
    artifacts_7_1: 'Hormone Punk',
    artifacts_7_2: 'Astral Voice',
    artifacts_7_3: '',
    artifacts_7_app: 3.66,
    artifacts_7_round_sd: 75,
    artifacts_7_round_da: 47614,
    artifacts_8: 'Thunder Metal, Flex',
    artifacts_8_1: 'Thunder Metal',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 3.35,
    artifacts_8_round_sd: 600,
    artifacts_8_round_da: 17299,
    artifacts_9: 'Thunder Metal, Flex',
    artifacts_9_1: 'Thunder Metal',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 3.35,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 20718,
    artifacts_10: 'Thunder Metal, Woodpecker Electro, Flex',
    artifacts_10_1: 'Thunder Metal',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: 'Flex',
    artifacts_10_app: 3.35,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 16890
  },
  {
    char: 'soldier-11',
    app_rate_sd: 7.35,
    app_rate_sd_e1: 1.26,
    app_rate_sd_s0: 3.5,
    avg_round_sd: 146,
    avg_round_sd_e1: 115,
    avg_round_sd_s0: 156,
    sample_sd: 357,
    sample_size_players_sd: 0,
    app_rate_da: 13.07,
    app_rate_da_e1: 1.54,
    app_rate_da_s0: 7.08,
    avg_round_da: 17370,
    avg_round_da_e1: 23773,
    avg_round_da_s0: 15938,
    app_rate_da_boss_1: 0.19,
    avg_round_da_boss_1: 0,
    app_rate_da_boss_2: 0.17,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 14.32,
    avg_round_da_boss_3: 17364,
    sample_da: 757,
    sample_size_players_da: 0,
    app_0: 41.07,
    round_0_sd: 148,
    round_0_da: 16745,
    app_1: 32.87,
    round_1_sd: 150,
    round_1_da: 17409,
    app_2: 15.42,
    round_2_sd: 142,
    round_2_da: 17677,
    app_3: 8.54,
    round_3_sd: 132,
    round_3_da: 19691,
    app_4: 1.56,
    round_4_sd: 146,
    round_4_da: 19871,
    app_5: 0.54,
    round_5_sd: 128,
    round_5_da: 23616,
    app_6: 1,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.98,
    weapons_1: 'The Brimstone',
    weapons_1_app: 47.78,
    weapons_1_round_sd: 144,
    weapons_1_round_da: 17872,
    weapons_2: 'Starlight Engine',
    weapons_2_app: 14.72,
    weapons_2_round_sd: 152,
    weapons_2_round_da: 16475,
    weapons_3: 'Marcato Desire',
    weapons_3_app: 10.76,
    weapons_3_round_sd: 156,
    weapons_3_round_da: 16869,
    weapons_4: 'Heartstring Nocturne',
    weapons_4_app: 7.33,
    weapons_4_round_sd: 124,
    weapons_4_round_da: 21593,
    weapons_5: 'Cannon Rotor',
    weapons_5_app: 6.77,
    weapons_5_round_sd: 166,
    weapons_5_round_da: 15871,
    weapons_6: 'Gilded Blossom',
    weapons_6_app: 5.86,
    weapons_6_round_sd: 159,
    weapons_6_round_da: 15543,
    weapons_7: 'Street Superstar',
    weapons_7_app: 1.32,
    weapons_7_round_sd: 126,
    weapons_7_round_da: 16975,
    weapons_8: 'Steel Cushion',
    weapons_8_app: 1.25,
    weapons_8_round_sd: 143,
    weapons_8_round_da: 15207,
    weapons_9: 'Severed Innocence',
    weapons_9_app: 0.54,
    weapons_9_round_sd: 146,
    weapons_9_round_da: 23481,
    weapons_10: 'Housekeeper',
    weapons_10_app: 0.3,
    weapons_10_round_sd: 196,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Inferno Metal, Woodpecker Electro',
    artifacts_1_1: 'Inferno Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 59.55,
    artifacts_1_round_sd: 149,
    artifacts_1_round_da: 16715,
    artifacts_2: 'Inferno Metal, Branch & Blade Song',
    artifacts_2_1: 'Inferno Metal',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 13.55,
    artifacts_2_round_sd: 145,
    artifacts_2_round_da: 18795,
    artifacts_3: 'Inferno Metal, Puffer Electro',
    artifacts_3_1: 'Inferno Metal',
    artifacts_3_2: 'Puffer Electro',
    artifacts_3_3: '',
    artifacts_3_app: 6.01,
    artifacts_3_round_sd: 131,
    artifacts_3_round_da: 19856,
    artifacts_4: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_4_1: 'Woodpecker Electro',
    artifacts_4_2: 'Branch & Blade Song',
    artifacts_4_3: '',
    artifacts_4_app: 3.77,
    artifacts_4_round_sd: 123,
    artifacts_4_round_da: 20475,
    artifacts_5: 'Woodpecker Electro, Inferno Metal',
    artifacts_5_1: 'Woodpecker Electro',
    artifacts_5_2: 'Inferno Metal',
    artifacts_5_3: '',
    artifacts_5_app: 3.33,
    artifacts_5_round_sd: 170,
    artifacts_5_round_da: 18130,
    artifacts_6: 'Inferno Metal, Flex',
    artifacts_6_1: 'Inferno Metal',
    artifacts_6_2: 'Flex',
    artifacts_6_3: '',
    artifacts_6_app: 1.95,
    artifacts_6_round_sd: 600,
    artifacts_6_round_da: 14582,
    artifacts_7: 'Inferno Metal, Hormone Punk',
    artifacts_7_1: 'Inferno Metal',
    artifacts_7_2: 'Hormone Punk',
    artifacts_7_3: '',
    artifacts_7_app: 1.29,
    artifacts_7_round_sd: 139,
    artifacts_7_round_da: 20143,
    artifacts_8: 'Inferno Metal, Swing Jazz',
    artifacts_8_1: 'Inferno Metal',
    artifacts_8_2: 'Swing Jazz',
    artifacts_8_3: '',
    artifacts_8_app: 0.91,
    artifacts_8_round_sd: 151,
    artifacts_8_round_da: 11477,
    artifacts_9: 'Woodpecker Electro, Puffer Electro',
    artifacts_9_1: 'Woodpecker Electro',
    artifacts_9_2: 'Puffer Electro',
    artifacts_9_3: '',
    artifacts_9_app: 0.91,
    artifacts_9_round_sd: 105,
    artifacts_9_round_da: 21438,
    artifacts_10: 'Inferno Metal, Woodpecker Electro, Flex',
    artifacts_10_1: 'Inferno Metal',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: 'Flex',
    artifacts_10_app: 0.6,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 18929
  },
  {
    char: 'ben',
    app_rate_sd: 0.56,
    app_rate_sd_e1: 0.04,
    app_rate_sd_s0: 0.19,
    avg_round_sd: 140,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 600,
    sample_sd: 27,
    sample_size_players_sd: 0,
    app_rate_da: 1.23,
    app_rate_da_e1: 0.14,
    app_rate_da_s0: 0.64,
    avg_round_da: 15932,
    avg_round_da_e1: 0,
    avg_round_da_s0: 14213,
    app_rate_da_boss_1: 0.02,
    avg_round_da_boss_1: 0,
    app_rate_da_boss_2: 0.11,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 1.24,
    avg_round_da_boss_3: 15862,
    sample_da: 71,
    sample_size_players_da: 0,
    app_0: 3.7,
    round_0_sd: 288,
    round_0_da: 0,
    app_1: 2.14,
    round_1_sd: 180,
    round_1_da: 17006,
    app_2: 2.14,
    round_2_sd: 165,
    round_2_da: 14522,
    app_3: 4.29,
    round_3_sd: 600,
    round_3_da: 15421,
    app_4: 1.43,
    round_4_sd: 600,
    round_4_da: 11425,
    app_5: 4.29,
    round_5_sd: 600,
    round_5_da: 12308,
    app_6: 87.69,
    round_6_sd: 130,
    round_6_da: 16314,
    cons_avg: 5.6,
    weapons_1: 'Big Cylinder',
    weapons_1_app: 54.32,
    weapons_1_round_sd: 147,
    weapons_1_round_da: 14594,
    weapons_2: 'Tusks of Fury',
    weapons_2_app: 10.52,
    weapons_2_round_sd: 127,
    weapons_2_round_da: 20346,
    weapons_3: 'Bunny Band',
    weapons_3_app: 10.35,
    weapons_3_round_sd: 175,
    weapons_3_round_da: 18237,
    weapons_4: 'Zanshin Herb Case',
    weapons_4_app: 5.27,
    weapons_4_round_sd: 90,
    weapons_4_round_da: 24458,
    weapons_5: 'Steel Cushion',
    weapons_5_app: 5.27,
    weapons_5_round_sd: 118,
    weapons_5_round_da: 16963,
    weapons_6: 'Hailstorm Shrine',
    weapons_6_app: 4.29,
    weapons_6_round_sd: 167,
    weapons_6_round_da: 21226,
    weapons_7: 'Original Transmorpher',
    weapons_7_app: 3.92,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 12770,
    weapons_8: 'Peacekeeper - Specialized',
    weapons_8_app: 3.13,
    weapons_8_round_sd: 96,
    weapons_8_round_da: 18405,
    weapons_9: 'Starlight Engine',
    weapons_9_app: 0.98,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 14522,
    weapons_10: 'Demara Battery Mark II',
    weapons_10_app: 0.98,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 14429,
    artifacts_1: 'Swing Jazz, Soul Rock',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Soul Rock',
    artifacts_1_3: '',
    artifacts_1_app: 17.05,
    artifacts_1_round_sd: 209,
    artifacts_1_round_da: 13122,
    artifacts_2: 'Woodpecker Electro, Inferno Metal',
    artifacts_2_1: 'Woodpecker Electro',
    artifacts_2_2: 'Inferno Metal',
    artifacts_2_3: '',
    artifacts_2_app: 9.37,
    artifacts_2_round_sd: 116,
    artifacts_2_round_da: 18059,
    artifacts_3: 'Woodpecker Electro, Puffer Electro',
    artifacts_3_1: 'Woodpecker Electro',
    artifacts_3_2: 'Puffer Electro',
    artifacts_3_3: '',
    artifacts_3_app: 7.4,
    artifacts_3_round_sd: 130,
    artifacts_3_round_da: 23431,
    artifacts_4: 'Proto Punk, Swing Jazz',
    artifacts_4_1: 'Proto Punk',
    artifacts_4_2: 'Swing Jazz',
    artifacts_4_3: '',
    artifacts_4_app: 6.24,
    artifacts_4_round_sd: 138,
    artifacts_4_round_da: 18717,
    artifacts_5: 'Proto Punk, Soul Rock',
    artifacts_5_1: 'Proto Punk',
    artifacts_5_2: 'Soul Rock',
    artifacts_5_3: '',
    artifacts_5_app: 5.89,
    artifacts_5_round_sd: 600,
    artifacts_5_round_da: 14497,
    artifacts_6: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_6_1: 'Woodpecker Electro',
    artifacts_6_2: 'Branch & Blade Song',
    artifacts_6_3: '',
    artifacts_6_app: 4.29,
    artifacts_6_round_sd: 106,
    artifacts_6_round_da: 24452,
    artifacts_7: 'Shockstar Disco, Soul Rock',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Soul Rock',
    artifacts_7_3: '',
    artifacts_7_app: 3.13,
    artifacts_7_round_sd: 127,
    artifacts_7_round_da: 18372,
    artifacts_8: 'Proto Punk, Shockstar Disco',
    artifacts_8_1: 'Proto Punk',
    artifacts_8_2: 'Shockstar Disco',
    artifacts_8_3: '',
    artifacts_8_app: 2.95,
    artifacts_8_round_sd: 600,
    artifacts_8_round_da: 16948,
    artifacts_9: 'Inferno Metal, Woodpecker Electro',
    artifacts_9_1: 'Inferno Metal',
    artifacts_9_2: 'Woodpecker Electro',
    artifacts_9_3: '',
    artifacts_9_app: 2.32,
    artifacts_9_round_sd: 129,
    artifacts_9_round_da: 0.0,
    artifacts_10: 'Swing Jazz, Woodpecker Electro',
    artifacts_10_1: 'Swing Jazz',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: '',
    artifacts_10_app: 2.32,
    artifacts_10_round_sd: 154,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'rina',
    app_rate_sd: 25.78,
    app_rate_sd_e1: 8.44,
    app_rate_sd_s0: 4.49,
    avg_round_sd: 136,
    avg_round_sd_e1: 106,
    avg_round_sd_s0: 153,
    sample_sd: 1252,
    sample_size_players_sd: 0,
    app_rate_da: 38.36,
    app_rate_da_e1: 10.46,
    app_rate_da_s0: 10.72,
    avg_round_da: 22880,
    avg_round_da_e1: 32664,
    avg_round_da_s0: 18997,
    app_rate_da_boss_1: 25.03,
    avg_round_da_boss_1: 20575,
    app_rate_da_boss_2: 15.17,
    avg_round_da_boss_2: 28062,
    app_rate_da_boss_3: 1.07,
    avg_round_da_boss_3: 12001,
    sample_da: 2222,
    sample_size_players_da: 0,
    app_0: 31.11,
    round_0_sd: 146,
    round_0_da: 20889,
    app_1: 44.0,
    round_1_sd: 131,
    round_1_da: 24332,
    app_2: 15.82,
    round_2_sd: 135,
    round_2_da: 23210,
    app_3: 5.76,
    round_3_sd: 134,
    round_3_da: 23053,
    app_4: 2.06,
    round_4_sd: 129,
    round_4_da: 25577,
    app_5: 0.58,
    round_5_sd: 126,
    round_5_da: 31606,
    app_6: 0.67,
    round_6_sd: 63,
    round_6_da: 22723,
    cons_avg: 1.08,
    weapons_1: 'Weeping Cradle',
    weapons_1_app: 52.86,
    weapons_1_round_sd: 134,
    weapons_1_round_da: 23249,
    weapons_2: 'Slice of Time',
    weapons_2_app: 41.24,
    weapons_2_round_sd: 138,
    weapons_2_round_da: 22623,
    weapons_3: 'Kaboom the Cannon',
    weapons_3_app: 3.32,
    weapons_3_round_sd: 134,
    weapons_3_round_da: 22481,
    weapons_4: 'Unfettered Game Ball',
    weapons_4_app: 0.64,
    weapons_4_round_sd: 145,
    weapons_4_round_da: 17144,
    weapons_5: '[Reverb] Mark II',
    weapons_5_app: 0.37,
    weapons_5_round_sd: 95,
    weapons_5_round_da: 20044,
    weapons_6: 'The Vault',
    weapons_6_app: 0.21,
    weapons_6_round_sd: 179,
    weapons_6_round_da: 27499,
    weapons_7: 'Fusion Compiler',
    weapons_7_app: 0.21,
    weapons_7_round_sd: 145,
    weapons_7_round_da: 34456,
    weapons_8: 'Bashful Demon',
    weapons_8_app: 0.17,
    weapons_8_round_sd: 149,
    weapons_8_round_da: 19685,
    weapons_9: '[Reverb] Mark III',
    weapons_9_app: 0.08,
    weapons_9_round_sd: 120,
    weapons_9_round_da: 15953,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Swing Jazz, Puffer Electro',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Puffer Electro',
    artifacts_1_3: '',
    artifacts_1_app: 49.53,
    artifacts_1_round_sd: 137,
    artifacts_1_round_da: 22878,
    artifacts_2: 'Freedom Blues, Puffer Electro',
    artifacts_2_1: 'Freedom Blues',
    artifacts_2_2: 'Puffer Electro',
    artifacts_2_3: '',
    artifacts_2_app: 11.54,
    artifacts_2_round_sd: 124,
    artifacts_2_round_da: 25161,
    artifacts_3: 'Astral Voice, Puffer Electro',
    artifacts_3_1: 'Astral Voice',
    artifacts_3_2: 'Puffer Electro',
    artifacts_3_3: '',
    artifacts_3_app: 11.05,
    artifacts_3_round_sd: 129,
    artifacts_3_round_da: 25336,
    artifacts_4: 'Astral Voice, Swing Jazz',
    artifacts_4_1: 'Astral Voice',
    artifacts_4_2: 'Swing Jazz',
    artifacts_4_3: '',
    artifacts_4_app: 10.32,
    artifacts_4_round_sd: 134,
    artifacts_4_round_da: 22596,
    artifacts_5: 'Freedom Blues, Swing Jazz',
    artifacts_5_1: 'Freedom Blues',
    artifacts_5_2: 'Swing Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 2.22,
    artifacts_5_round_sd: 131,
    artifacts_5_round_da: 19709,
    artifacts_6: 'Swing Jazz, Freedom Blues',
    artifacts_6_1: 'Swing Jazz',
    artifacts_6_2: 'Freedom Blues',
    artifacts_6_3: '',
    artifacts_6_app: 2.03,
    artifacts_6_round_sd: 145,
    artifacts_6_round_da: 21519,
    artifacts_7: 'Puffer Electro, Swing Jazz, Flex',
    artifacts_7_1: 'Puffer Electro',
    artifacts_7_2: 'Swing Jazz',
    artifacts_7_3: 'Flex',
    artifacts_7_app: 1.26,
    artifacts_7_round_sd: 124,
    artifacts_7_round_da: 20201,
    artifacts_8: 'Puffer Electro, Swing Jazz',
    artifacts_8_1: 'Puffer Electro',
    artifacts_8_2: 'Swing Jazz',
    artifacts_8_3: '',
    artifacts_8_app: 0.86,
    artifacts_8_round_sd: 156,
    artifacts_8_round_da: 19982,
    artifacts_9: 'Swing Jazz, Flex',
    artifacts_9_1: 'Swing Jazz',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 0.67,
    artifacts_9_round_sd: 136,
    artifacts_9_round_da: 16202,
    artifacts_10: 'AP +30, Puffer Electro, Swing Jazz',
    artifacts_10_1: 'AP +30',
    artifacts_10_2: 'Puffer Electro',
    artifacts_10_3: 'Swing Jazz',
    artifacts_10_app: 0.35,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 19179
  },
  {
    char: 'lucy',
    app_rate_sd: 38.67,
    app_rate_sd_e1: 9.02,
    app_rate_sd_s0: 9.74,
    avg_round_sd: 131,
    avg_round_sd_e1: 96,
    avg_round_sd_s0: 152,
    sample_sd: 1878,
    sample_size_players_sd: 0,
    app_rate_da: 52.18,
    app_rate_da_e1: 11.38,
    app_rate_da_s0: 15.69,
    avg_round_da: 20541,
    avg_round_da_e1: 29970,
    avg_round_da_s0: 17118,
    app_rate_da_boss_1: 6.16,
    avg_round_da_boss_1: 20266,
    app_rate_da_boss_2: 15.94,
    avg_round_da_boss_2: 27728,
    app_rate_da_boss_3: 35.36,
    avg_round_da_boss_3: 17944,
    sample_da: 3022,
    sample_size_players_da: 0,
    app_0: 0.18,
    round_0_sd: 269,
    round_0_da: 11203,
    app_1: 0.71,
    round_1_sd: 180,
    round_1_da: 16243,
    app_2: 1.95,
    round_2_sd: 152,
    round_2_da: 15770,
    app_3: 3.56,
    round_3_sd: 146,
    round_3_da: 17203,
    app_4: 5.24,
    round_4_sd: 141,
    round_4_da: 17948,
    app_5: 6.16,
    round_5_sd: 138,
    round_5_da: 18977,
    app_6: 82.2,
    round_6_sd: 128,
    round_6_da: 21270,
    cons_avg: 5.6,
    weapons_1: 'Kaboom the Cannon',
    weapons_1_app: 86.8,
    weapons_1_round_sd: 130,
    weapons_1_round_da: 20711,
    weapons_2: 'Slice of Time',
    weapons_2_app: 3.68,
    weapons_2_round_sd: 151,
    weapons_2_round_da: 18234,
    weapons_3: 'Weeping Cradle',
    weapons_3_app: 1.85,
    weapons_3_round_sd: 128,
    weapons_3_round_da: 20895,
    weapons_4: 'Bashful Demon',
    weapons_4_app: 1.63,
    weapons_4_round_sd: 141,
    weapons_4_round_da: 22928,
    weapons_5: '[Reverb] Mark II',
    weapons_5_app: 1.58,
    weapons_5_round_sd: 127,
    weapons_5_round_da: 18606,
    weapons_6: 'Unfettered Game Ball',
    weapons_6_app: 0.86,
    weapons_6_round_sd: 119,
    weapons_6_round_da: 20591,
    weapons_7: 'The Vault',
    weapons_7_app: 0.75,
    weapons_7_round_sd: 128,
    weapons_7_round_da: 20889,
    weapons_8: '[Reverb] Mark III',
    weapons_8_app: 0.39,
    weapons_8_round_sd: 185,
    weapons_8_round_da: 14897,
    weapons_9: '[Reverb] Mark I',
    weapons_9_app: 0.39,
    weapons_9_round_sd: 140,
    weapons_9_round_da: 20628,
    weapons_10: 'Elegant Vanity',
    weapons_10_app: 0.11,
    weapons_10_round_sd: 134,
    weapons_10_round_da: 35222,
    artifacts_1: 'Swing Jazz, Hormone Punk',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Hormone Punk',
    artifacts_1_3: '',
    artifacts_1_app: 42.1,
    artifacts_1_round_sd: 133,
    artifacts_1_round_da: 19744,
    artifacts_2: 'Astral Voice, Swing Jazz',
    artifacts_2_1: 'Astral Voice',
    artifacts_2_2: 'Swing Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 18.23,
    artifacts_2_round_sd: 126,
    artifacts_2_round_da: 22633,
    artifacts_3: 'Swing Jazz, Astral Voice',
    artifacts_3_1: 'Swing Jazz',
    artifacts_3_2: 'Astral Voice',
    artifacts_3_3: '',
    artifacts_3_app: 6.02,
    artifacts_3_round_sd: 138,
    artifacts_3_round_da: 20464,
    artifacts_4: 'Swing Jazz, Woodpecker Electro',
    artifacts_4_1: 'Swing Jazz',
    artifacts_4_2: 'Woodpecker Electro',
    artifacts_4_3: '',
    artifacts_4_app: 4.35,
    artifacts_4_round_sd: 133,
    artifacts_4_round_da: 21434,
    artifacts_5: 'Swing Jazz, Freedom Blues',
    artifacts_5_1: 'Swing Jazz',
    artifacts_5_2: 'Freedom Blues',
    artifacts_5_3: '',
    artifacts_5_app: 2.95,
    artifacts_5_round_sd: 132,
    artifacts_5_round_da: 20739,
    artifacts_6: 'Astral Voice, Hormone Punk',
    artifacts_6_1: 'Astral Voice',
    artifacts_6_2: 'Hormone Punk',
    artifacts_6_3: '',
    artifacts_6_app: 2.39,
    artifacts_6_round_sd: 128,
    artifacts_6_round_da: 20475,
    artifacts_7: 'Hormone Punk, Swing Jazz',
    artifacts_7_1: 'Hormone Punk',
    artifacts_7_2: 'Swing Jazz',
    artifacts_7_3: '',
    artifacts_7_app: 2.08,
    artifacts_7_round_sd: 148,
    artifacts_7_round_da: 17495,
    artifacts_8: 'Swing Jazz, Flex',
    artifacts_8_1: 'Swing Jazz',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 1.65,
    artifacts_8_round_sd: 137,
    artifacts_8_round_da: 21087,
    artifacts_9: 'Astral Voice, Woodpecker Electro',
    artifacts_9_1: 'Astral Voice',
    artifacts_9_2: 'Woodpecker Electro',
    artifacts_9_3: '',
    artifacts_9_app: 1.57,
    artifacts_9_round_sd: 116,
    artifacts_9_round_da: 24597,
    artifacts_10: 'Freedom Blues, Swing Jazz',
    artifacts_10_1: 'Freedom Blues',
    artifacts_10_2: 'Swing Jazz',
    artifacts_10_3: '',
    artifacts_10_app: 0.91,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 21087
  },
  {
    char: 'koleda',
    app_rate_sd: 13.65,
    app_rate_sd_e1: 2.06,
    app_rate_sd_s0: 4.3,
    avg_round_sd: 130,
    avg_round_sd_e1: 104,
    avg_round_sd_s0: 149,
    sample_sd: 663,
    sample_size_players_sd: 0,
    app_rate_da: 17.9,
    app_rate_da_e1: 2.14,
    app_rate_da_s0: 7.13,
    avg_round_da: 18937,
    avg_round_da_e1: 26051,
    avg_round_da_s0: 16378,
    app_rate_da_boss_1: 0.82,
    avg_round_da_boss_1: 14799,
    app_rate_da_boss_2: 0.4,
    avg_round_da_boss_2: 20716,
    app_rate_da_boss_3: 18.84,
    avg_round_da_boss_3: 19106,
    sample_da: 1037,
    sample_size_players_da: 0,
    app_0: 46.84,
    round_0_sd: 130,
    round_0_da: 18942,
    app_1: 31.67,
    round_1_sd: 134,
    round_1_da: 18544,
    app_2: 14.8,
    round_2_sd: 120,
    round_2_da: 20047,
    app_3: 5.25,
    round_3_sd: 118,
    round_3_da: 19047,
    app_4: 1.03,
    round_4_sd: 152,
    round_4_da: 16935,
    app_5: 0.29,
    round_5_sd: 92,
    round_5_da: 25856,
    app_6: 0.12,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.84,
    weapons_1: 'Hellfire Gears',
    weapons_1_app: 41.07,
    weapons_1_round_sd: 130,
    weapons_1_round_da: 19120,
    weapons_2: 'Steam Oven',
    weapons_2_app: 32.71,
    weapons_2_round_sd: 127,
    weapons_2_round_da: 19532,
    weapons_3: 'Precious Fossilized Core',
    weapons_3_app: 13.58,
    weapons_3_round_sd: 138,
    weapons_3_round_da: 17805,
    weapons_4: 'The Restrained',
    weapons_4_app: 3.62,
    weapons_4_round_sd: 136,
    weapons_4_round_da: 17679,
    weapons_5: 'Six Shooter',
    weapons_5_app: 2.62,
    weapons_5_round_sd: 118,
    weapons_5_round_da: 19290,
    weapons_6: 'Demara Battery Mark II',
    weapons_6_app: 1.95,
    weapons_6_round_sd: 127,
    weapons_6_round_da: 19410,
    weapons_7: '[Vortex] Arrow',
    weapons_7_app: 0.52,
    weapons_7_round_sd: 161,
    weapons_7_round_da: 12604,
    weapons_8: 'Box Cutter',
    weapons_8_app: 0.28,
    weapons_8_round_sd: 129,
    weapons_8_round_da: 15624,
    weapons_9: 'Blazing Laurel',
    weapons_9_app: 0.22,
    weapons_9_round_sd: 104,
    weapons_9_round_da: 21966,
    weapons_10: 'Marcato Desire',
    weapons_10_app: 0.13,
    weapons_10_round_sd: 104,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Shockstar Disco, Swing Jazz',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 70.2,
    artifacts_1_round_sd: 129,
    artifacts_1_round_da: 19121,
    artifacts_2: 'Swing Jazz, Shockstar Disco',
    artifacts_2_1: 'Swing Jazz',
    artifacts_2_2: 'Shockstar Disco',
    artifacts_2_3: '',
    artifacts_2_app: 5.11,
    artifacts_2_round_sd: 115,
    artifacts_2_round_da: 20941,
    artifacts_3: 'Shockstar Disco, Inferno Metal',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Inferno Metal',
    artifacts_3_3: '',
    artifacts_3_app: 4.12,
    artifacts_3_round_sd: 145,
    artifacts_3_round_da: 18049,
    artifacts_4: 'Shockstar Disco, Freedom Blues',
    artifacts_4_1: 'Shockstar Disco',
    artifacts_4_2: 'Freedom Blues',
    artifacts_4_3: '',
    artifacts_4_app: 2.98,
    artifacts_4_round_sd: 128,
    artifacts_4_round_da: 15708,
    artifacts_5: 'Shockstar Disco, Flex',
    artifacts_5_1: 'Shockstar Disco',
    artifacts_5_2: 'Flex',
    artifacts_5_3: '',
    artifacts_5_app: 2.7,
    artifacts_5_round_sd: 141,
    artifacts_5_round_da: 18234,
    artifacts_6: 'Proto Punk, Shockstar Disco',
    artifacts_6_1: 'Proto Punk',
    artifacts_6_2: 'Shockstar Disco',
    artifacts_6_3: '',
    artifacts_6_app: 1.86,
    artifacts_6_round_sd: 130,
    artifacts_6_round_da: 22291,
    artifacts_7: 'Shockstar Disco, Woodpecker Electro',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Woodpecker Electro',
    artifacts_7_3: '',
    artifacts_7_app: 1.64,
    artifacts_7_round_sd: 139,
    artifacts_7_round_da: 19637,
    artifacts_8: 'Shockstar Disco, Swing Jazz, Flex',
    artifacts_8_1: 'Shockstar Disco',
    artifacts_8_2: 'Swing Jazz',
    artifacts_8_3: 'Flex',
    artifacts_8_app: 1.58,
    artifacts_8_round_sd: 126,
    artifacts_8_round_da: 18733,
    artifacts_9: 'Shockstar Disco, Flex',
    artifacts_9_1: 'Shockstar Disco',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 0.44,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 14390,
    artifacts_10: 'Shockstar Disco, Puffer Electro',
    artifacts_10_1: 'Shockstar Disco',
    artifacts_10_2: 'Puffer Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.39,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 16856
  },
  {
    char: 'ellen',
    app_rate_sd: 2.72,
    app_rate_sd_e1: 1.52,
    app_rate_sd_s0: 0.25,
    avg_round_sd: 190,
    avg_round_sd_e1: 149,
    avg_round_sd_s0: 600,
    sample_sd: 132,
    sample_size_players_sd: 0,
    app_rate_da: 11.33,
    app_rate_da_e1: 4.78,
    app_rate_da_s0: 1.12,
    avg_round_da: 14855,
    avg_round_da_e1: 19481,
    avg_round_da_s0: 12436,
    app_rate_da_boss_1: 4.22,
    avg_round_da_boss_1: 15643,
    app_rate_da_boss_2: 6.15,
    avg_round_da_boss_2: 15395,
    app_rate_da_boss_3: 1.84,
    avg_round_da_boss_3: 11769,
    sample_da: 656,
    sample_size_players_da: 0,
    app_0: 59.47,
    round_0_sd: 190,
    round_0_da: 14855,
    app_1: 19.3,
    round_1_sd: 168,
    round_1_da: 16710,
    app_2: 9.95,
    round_2_sd: 163,
    round_2_da: 19924,
    app_3: 3.32,
    round_3_sd: 149,
    round_3_da: 19227,
    app_4: 0.4,
    round_4_sd: 154,
    round_4_da: 18823,
    app_5: 0.15,
    round_5_sd: 600,
    round_5_da: 9327,
    app_6: 7.44,
    round_6_sd: 104,
    round_6_da: 26792,
    cons_avg: 0.96,
    weapons_1: 'Deep Sea Visitor',
    weapons_1_app: 79.32,
    weapons_1_round_sd: 185,
    weapons_1_round_da: 14958,
    weapons_2: 'Starlight Engine',
    weapons_2_app: 4.79,
    weapons_2_round_sd: 196,
    weapons_2_round_da: 13126,
    weapons_3: 'Marcato Desire',
    weapons_3_app: 4.57,
    weapons_3_round_sd: 192,
    weapons_3_round_da: 15967,
    weapons_4: 'The Brimstone',
    weapons_4_app: 3.18,
    weapons_4_round_sd: 212,
    weapons_4_round_da: 14534,
    weapons_5: 'Gilded Blossom',
    weapons_5_app: 2.49,
    weapons_5_round_sd: 283,
    weapons_5_round_da: 13510,
    weapons_6: 'Cannon Rotor',
    weapons_6_app: 1.77,
    weapons_6_round_sd: 258,
    weapons_6_round_da: 16478,
    weapons_7: 'Steel Cushion',
    weapons_7_app: 1.52,
    weapons_7_round_sd: 177,
    weapons_7_round_da: 12932,
    weapons_8: 'Street Superstar',
    weapons_8_app: 1.01,
    weapons_8_round_sd: 186,
    weapons_8_round_da: 12683,
    weapons_9: 'Riot Suppressor Mark VI',
    weapons_9_app: 0.25,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 17128,
    weapons_10: 'Severed Innocence',
    weapons_10_app: 0.12,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 10905,
    artifacts_1: 'Polar Metal, Woodpecker Electro',
    artifacts_1_1: 'Polar Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 42.94,
    artifacts_1_round_sd: 195,
    artifacts_1_round_da: 13893,
    artifacts_2: 'Polar Metal, Branch & Blade Song',
    artifacts_2_1: 'Polar Metal',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 14.62,
    artifacts_2_round_sd: 206,
    artifacts_2_round_da: 15701,
    artifacts_3: 'Polar Metal, Puffer Electro',
    artifacts_3_1: 'Polar Metal',
    artifacts_3_2: 'Puffer Electro',
    artifacts_3_3: '',
    artifacts_3_app: 8.48,
    artifacts_3_round_sd: 187,
    artifacts_3_round_da: 15829,
    artifacts_4: 'Woodpecker Electro, Polar Metal',
    artifacts_4_1: 'Woodpecker Electro',
    artifacts_4_2: 'Polar Metal',
    artifacts_4_3: '',
    artifacts_4_app: 7.31,
    artifacts_4_round_sd: 155,
    artifacts_4_round_da: 15378,
    artifacts_5: 'Woodpecker Electro, Puffer Electro',
    artifacts_5_1: 'Woodpecker Electro',
    artifacts_5_2: 'Puffer Electro',
    artifacts_5_3: '',
    artifacts_5_app: 5.59,
    artifacts_5_round_sd: 128,
    artifacts_5_round_da: 15904,
    artifacts_6: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_6_1: 'Woodpecker Electro',
    artifacts_6_2: 'Branch & Blade Song',
    artifacts_6_3: '',
    artifacts_6_app: 4.98,
    artifacts_6_round_sd: 174,
    artifacts_6_round_da: 16697,
    artifacts_7: 'Polar Metal, Hormone Punk',
    artifacts_7_1: 'Polar Metal',
    artifacts_7_2: 'Hormone Punk',
    artifacts_7_3: '',
    artifacts_7_app: 3.34,
    artifacts_7_round_sd: 216,
    artifacts_7_round_da: 15281,
    artifacts_8: 'Branch & Blade Song, Polar Metal',
    artifacts_8_1: 'Branch & Blade Song',
    artifacts_8_2: 'Polar Metal',
    artifacts_8_3: '',
    artifacts_8_app: 1.54,
    artifacts_8_round_sd: 166,
    artifacts_8_round_da: 13665,
    artifacts_9: 'Polar Metal, Flex',
    artifacts_9_1: 'Polar Metal',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 1.23,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 11576,
    artifacts_10: 'Polar Metal, Woodpecker Electro, Flex',
    artifacts_10_1: 'Polar Metal',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: 'Flex',
    artifacts_10_app: 0.86,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 12446
  },
  {
    char: 'qingyi',
    app_rate_sd: 29.46,
    app_rate_sd_e1: 8.34,
    app_rate_sd_s0: 7.89,
    avg_round_sd: 146,
    avg_round_sd_e1: 117,
    avg_round_sd_s0: 153,
    sample_sd: 1431,
    sample_size_players_sd: 0,
    app_rate_da: 48.33,
    app_rate_da_e1: 12.67,
    app_rate_da_s0: 17.61,
    avg_round_da: 22117,
    avg_round_da_e1: 28789,
    avg_round_da_s0: 21210,
    app_rate_da_boss_1: 48.7,
    avg_round_da_boss_1: 22502,
    app_rate_da_boss_2: 0.7,
    avg_round_da_boss_2: 17539,
    app_rate_da_boss_3: 2.99,
    avg_round_da_boss_3: 16683,
    sample_da: 2799,
    sample_size_players_da: 0,
    app_0: 85.31,
    round_0_sd: 146,
    round_0_da: 22117,
    app_1: 10.01,
    round_1_sd: 119,
    round_1_da: 28160,
    app_2: 2.81,
    round_2_sd: 109,
    round_2_da: 33951,
    app_3: 0.47,
    round_3_sd: 110,
    round_3_da: 33096,
    app_4: 0.19,
    round_4_sd: 67,
    round_4_da: 48541,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.2,
    round_6_sd: 66,
    round_6_da: 45527,
    cons_avg: 0.25,
    weapons_1: 'Ice-Jade Teapot',
    weapons_1_app: 36.57,
    weapons_1_round_sd: 144,
    weapons_1_round_da: 23525,
    weapons_2: 'The Restrained',
    weapons_2_app: 16.63,
    weapons_2_round_sd: 141,
    weapons_2_round_da: 22301,
    weapons_3: 'Steam Oven',
    weapons_3_app: 16.38,
    weapons_3_round_sd: 146,
    weapons_3_round_da: 21611,
    weapons_4: 'Demara Battery Mark II',
    weapons_4_app: 12.47,
    weapons_4_round_sd: 152,
    weapons_4_round_da: 20238,
    weapons_5: 'Hellfire Gears',
    weapons_5_app: 8.76,
    weapons_5_round_sd: 143,
    weapons_5_round_da: 21735,
    weapons_6: 'Precious Fossilized Core',
    weapons_6_app: 6.97,
    weapons_6_round_sd: 156,
    weapons_6_round_da: 22161,
    weapons_7: 'Six Shooter',
    weapons_7_app: 0.9,
    weapons_7_round_sd: 156,
    weapons_7_round_da: 18262,
    weapons_8: '[Vortex] Arrow',
    weapons_8_app: 0.32,
    weapons_8_round_sd: 149,
    weapons_8_round_da: 17520,
    weapons_9: 'Marcato Desire',
    weapons_9_app: 0.05,
    weapons_9_round_sd: 137,
    weapons_9_round_da: 25554,
    weapons_10: 'Steel Cushion',
    weapons_10_app: 0.03,
    weapons_10_round_sd: 101,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Shockstar Disco, Thunder Metal',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Thunder Metal',
    artifacts_1_3: '',
    artifacts_1_app: 41.29,
    artifacts_1_round_sd: 150,
    artifacts_1_round_da: 20808,
    artifacts_2: 'Shockstar Disco, Swing Jazz',
    artifacts_2_1: 'Shockstar Disco',
    artifacts_2_2: 'Swing Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 35.47,
    artifacts_2_round_sd: 143,
    artifacts_2_round_da: 22885,
    artifacts_3: 'Shockstar Disco, Woodpecker Electro',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 9.73,
    artifacts_3_round_sd: 142,
    artifacts_3_round_da: 22989,
    artifacts_4: 'Astral Voice, Shockstar Disco',
    artifacts_4_1: 'Astral Voice',
    artifacts_4_2: 'Shockstar Disco',
    artifacts_4_3: '',
    artifacts_4_app: 2.18,
    artifacts_4_round_sd: 123,
    artifacts_4_round_da: 30507,
    artifacts_5: 'Shockstar Disco, Flex',
    artifacts_5_1: 'Shockstar Disco',
    artifacts_5_2: 'Flex',
    artifacts_5_3: '',
    artifacts_5_app: 1.39,
    artifacts_5_round_sd: 153,
    artifacts_5_round_da: 21138,
    artifacts_6: 'Shockstar Disco, Branch & Blade Song',
    artifacts_6_1: 'Shockstar Disco',
    artifacts_6_2: 'Branch & Blade Song',
    artifacts_6_3: '',
    artifacts_6_app: 1.21,
    artifacts_6_round_sd: 142,
    artifacts_6_round_da: 24279,
    artifacts_7: 'Shockstar Disco, Hormone Punk',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Hormone Punk',
    artifacts_7_3: '',
    artifacts_7_app: 1.06,
    artifacts_7_round_sd: 132,
    artifacts_7_round_da: 23260,
    artifacts_8: 'Swing Jazz, Shockstar Disco',
    artifacts_8_1: 'Swing Jazz',
    artifacts_8_2: 'Shockstar Disco',
    artifacts_8_3: '',
    artifacts_8_app: 0.95,
    artifacts_8_round_sd: 143,
    artifacts_8_round_da: 24810,
    artifacts_9: 'Shockstar Disco, Astral Voice',
    artifacts_9_1: 'Shockstar Disco',
    artifacts_9_2: 'Astral Voice',
    artifacts_9_3: '',
    artifacts_9_app: 0.79,
    artifacts_9_round_sd: 131,
    artifacts_9_round_da: 23166,
    artifacts_10: 'Shockstar Disco, Puffer Electro',
    artifacts_10_1: 'Shockstar Disco',
    artifacts_10_2: 'Puffer Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.77,
    artifacts_10_round_sd: 151,
    artifacts_10_round_da: 21827
  },
  {
    char: 'jane-doe',
    app_rate_sd: 14.95,
    app_rate_sd_e1: 4.34,
    app_rate_sd_s0: 3.89,
    avg_round_sd: 146,
    avg_round_sd_e1: 120,
    avg_round_sd_s0: 153,
    sample_sd: 726,
    sample_size_players_sd: 0,
    app_rate_da: 21.48,
    app_rate_da_e1: 5.89,
    app_rate_da_s0: 5.78,
    avg_round_da: 17147,
    avg_round_da_e1: 22595,
    avg_round_da_s0: 15878,
    app_rate_da_boss_1: 4.32,
    avg_round_da_boss_1: 16224,
    app_rate_da_boss_2: 1.38,
    avg_round_da_boss_2: 18172,
    app_rate_da_boss_3: 18.2,
    avg_round_da_boss_3: 17280,
    sample_da: 1244,
    sample_size_players_da: 0,
    app_0: 80.6,
    round_0_sd: 146,
    round_0_da: 17147,
    app_1: 9.43,
    round_1_sd: 133,
    round_1_da: 19443,
    app_2: 6.95,
    round_2_sd: 116,
    round_2_da: 24077,
    app_3: 0.78,
    round_3_sd: 125,
    round_3_da: 20006,
    app_4: 0.26,
    round_4_sd: 120,
    round_4_da: 19258,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.99,
    round_6_sd: 75,
    round_6_da: 32211,
    cons_avg: 0.39,
    weapons_1: 'Sharpened Stinger',
    weapons_1_app: 55.6,
    weapons_1_round_sd: 144,
    weapons_1_round_da: 17724,
    weapons_2: 'Weeping Gemini',
    weapons_2_app: 17.05,
    weapons_2_round_sd: 139,
    weapons_2_round_da: 16864,
    weapons_3: 'Rainforest Gourmet',
    weapons_3_app: 12.67,
    weapons_3_round_sd: 156,
    weapons_3_round_da: 15850,
    weapons_4: 'Electro-Lip Gloss',
    weapons_4_app: 10.84,
    weapons_4_round_sd: 145,
    weapons_4_round_da: 18225,
    weapons_5: 'Fusion Compiler',
    weapons_5_app: 2.81,
    weapons_5_round_sd: 173,
    weapons_5_round_da: 13585,
    weapons_6: 'Roaring Ride',
    weapons_6_app: 0.74,
    weapons_6_round_sd: 149,
    weapons_6_round_da: 14492,
    weapons_7: 'Timeweaver',
    weapons_7_app: 0.11,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 0,
    weapons_8: '',
    weapons_8_app: 0.0,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 0.0,
    weapons_9: '',
    weapons_9_app: 0.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0.0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Fanged Metal, Freedom Blues',
    artifacts_1_1: 'Fanged Metal',
    artifacts_1_2: 'Freedom Blues',
    artifacts_1_3: '',
    artifacts_1_app: 83.84,
    artifacts_1_round_sd: 149,
    artifacts_1_round_da: 16931,
    artifacts_2: 'Fanged Metal, Chaos Jazz',
    artifacts_2_1: 'Fanged Metal',
    artifacts_2_2: 'Chaos Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 8.05,
    artifacts_2_round_sd: 128,
    artifacts_2_round_da: 19701,
    artifacts_3: 'Fanged Metal, Hormone Punk',
    artifacts_3_1: 'Fanged Metal',
    artifacts_3_2: 'Hormone Punk',
    artifacts_3_3: '',
    artifacts_3_app: 2.59,
    artifacts_3_round_sd: 135,
    artifacts_3_round_da: 16787,
    artifacts_4: "Fanged Metal, Phaethon's Melody",
    artifacts_4_1: 'Fanged Metal',
    artifacts_4_2: "Phaethon's Melody",
    artifacts_4_3: '',
    artifacts_4_app: 0.97,
    artifacts_4_round_sd: 110,
    artifacts_4_round_da: 19174,
    artifacts_5: 'Fanged Metal, Puffer Electro',
    artifacts_5_1: 'Fanged Metal',
    artifacts_5_2: 'Puffer Electro',
    artifacts_5_3: '',
    artifacts_5_app: 0.89,
    artifacts_5_round_sd: 153,
    artifacts_5_round_da: 20251,
    artifacts_6: 'Freedom Blues, Fanged Metal',
    artifacts_6_1: 'Freedom Blues',
    artifacts_6_2: 'Fanged Metal',
    artifacts_6_3: '',
    artifacts_6_app: 0.77,
    artifacts_6_round_sd: 133,
    artifacts_6_round_da: 15782,
    artifacts_7: 'Fanged Metal, Astral Voice',
    artifacts_7_1: 'Fanged Metal',
    artifacts_7_2: 'Astral Voice',
    artifacts_7_3: '',
    artifacts_7_app: 0.57,
    artifacts_7_round_sd: 116,
    artifacts_7_round_da: 22049,
    artifacts_8: 'Fanged Metal, Flex',
    artifacts_8_1: 'Fanged Metal',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 0.4,
    artifacts_8_round_sd: 132,
    artifacts_8_round_da: 16905,
    artifacts_9: 'Fanged Metal, Woodpecker Electro',
    artifacts_9_1: 'Fanged Metal',
    artifacts_9_2: 'Woodpecker Electro',
    artifacts_9_3: '',
    artifacts_9_app: 0.35,
    artifacts_9_round_sd: 123,
    artifacts_9_round_da: 15159,
    artifacts_10: 'Fanged Metal, Swing Jazz',
    artifacts_10_1: 'Fanged Metal',
    artifacts_10_2: 'Swing Jazz',
    artifacts_10_3: '',
    artifacts_10_app: 0.11,
    artifacts_10_round_sd: 134,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'seth',
    app_rate_sd: 4.71,
    app_rate_sd_e1: 1.81,
    app_rate_sd_s0: 1.15,
    avg_round_sd: 174,
    avg_round_sd_e1: 137,
    avg_round_sd_s0: 185,
    sample_sd: 229,
    sample_size_players_sd: 0,
    app_rate_da: 11.64,
    app_rate_da_e1: 3.12,
    app_rate_da_s0: 3.42,
    avg_round_da: 16990,
    avg_round_da_e1: 22402,
    avg_round_da_s0: 15547,
    app_rate_da_boss_1: 6.2,
    avg_round_da_boss_1: 17994,
    app_rate_da_boss_2: 2.2,
    avg_round_da_boss_2: 20697,
    app_rate_da_boss_3: 4.31,
    avg_round_da_boss_3: 13179,
    sample_da: 674,
    sample_size_players_da: 0,
    app_0: 0.68,
    round_0_sd: 165,
    round_0_da: 18646,
    app_1: 3.77,
    round_1_sd: 168,
    round_1_da: 17907,
    app_2: 5.48,
    round_2_sd: 163,
    round_2_da: 17316,
    app_3: 11.91,
    round_3_sd: 162,
    round_3_da: 15587,
    app_4: 11.91,
    round_4_sd: 180,
    round_4_da: 16115,
    app_5: 10.92,
    round_5_sd: 166,
    round_5_da: 16509,
    app_6: 55.35,
    round_6_sd: 178,
    round_6_da: 17561,
    cons_avg: 4.85,
    weapons_1: 'Peacekeeper - Specialized',
    weapons_1_app: 87.75,
    weapons_1_round_sd: 176,
    weapons_1_round_da: 17011,
    weapons_2: 'Spring Embrace',
    weapons_2_app: 3.3,
    weapons_2_round_sd: 146,
    weapons_2_round_da: 17801,
    weapons_3: 'Bunny Band',
    weapons_3_app: 3.13,
    weapons_3_round_sd: 161,
    weapons_3_round_da: 13804,
    weapons_4: 'Original Transmorpher',
    weapons_4_app: 1.93,
    weapons_4_round_sd: 197,
    weapons_4_round_da: 14385,
    weapons_5: 'Big Cylinder',
    weapons_5_app: 0.99,
    weapons_5_round_sd: 184,
    weapons_5_round_da: 19658,
    weapons_6: 'Starlight Engine',
    weapons_6_app: 0.76,
    weapons_6_round_sd: 169,
    weapons_6_round_da: 19177,
    weapons_7: 'Tusks of Fury',
    weapons_7_app: 0.7,
    weapons_7_round_sd: 144,
    weapons_7_round_da: 22806,
    weapons_8: 'Steam Oven',
    weapons_8_app: 0.34,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 30464,
    weapons_9: 'Precious Fossilized Core',
    weapons_9_app: 0.24,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0,
    weapons_10: 'Hailstorm Shrine',
    weapons_10_app: 0.13,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Swing Jazz, Hormone Punk',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Hormone Punk',
    artifacts_1_3: '',
    artifacts_1_app: 27.56,
    artifacts_1_round_sd: 188,
    artifacts_1_round_da: 16244,
    artifacts_2: 'Proto Punk, Hormone Punk',
    artifacts_2_1: 'Proto Punk',
    artifacts_2_2: 'Hormone Punk',
    artifacts_2_3: '',
    artifacts_2_app: 12.01,
    artifacts_2_round_sd: 162,
    artifacts_2_round_da: 18109,
    artifacts_3: 'Proto Punk, Swing Jazz',
    artifacts_3_1: 'Proto Punk',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 9.37,
    artifacts_3_round_sd: 180,
    artifacts_3_round_da: 17998,
    artifacts_4: 'Swing Jazz, Freedom Blues',
    artifacts_4_1: 'Swing Jazz',
    artifacts_4_2: 'Freedom Blues',
    artifacts_4_3: '',
    artifacts_4_app: 4.92,
    artifacts_4_round_sd: 212,
    artifacts_4_round_da: 14393,
    artifacts_5: 'Astral Voice, Swing Jazz',
    artifacts_5_1: 'Astral Voice',
    artifacts_5_2: 'Swing Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 4.32,
    artifacts_5_round_sd: 158,
    artifacts_5_round_da: 15769,
    artifacts_6: 'Proto Punk, Shockstar Disco',
    artifacts_6_1: 'Proto Punk',
    artifacts_6_2: 'Shockstar Disco',
    artifacts_6_3: '',
    artifacts_6_app: 3.39,
    artifacts_6_round_sd: 130,
    artifacts_6_round_da: 20486,
    artifacts_7: 'Freedom Blues, Swing Jazz',
    artifacts_7_1: 'Freedom Blues',
    artifacts_7_2: 'Swing Jazz',
    artifacts_7_3: '',
    artifacts_7_app: 2.98,
    artifacts_7_round_sd: 179,
    artifacts_7_round_da: 18207,
    artifacts_8: 'Proto Punk, Freedom Blues',
    artifacts_8_1: 'Proto Punk',
    artifacts_8_2: 'Freedom Blues',
    artifacts_8_3: '',
    artifacts_8_app: 2.72,
    artifacts_8_round_sd: 150,
    artifacts_8_round_da: 18276,
    artifacts_9: 'Freedom Blues, Hormone Punk',
    artifacts_9_1: 'Freedom Blues',
    artifacts_9_2: 'Hormone Punk',
    artifacts_9_3: '',
    artifacts_9_app: 2.43,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 16264,
    artifacts_10: 'ATK +10%, Swing Jazz, Flex',
    artifacts_10_1: 'ATK +10%',
    artifacts_10_2: 'Swing Jazz',
    artifacts_10_3: 'Flex',
    artifacts_10_app: 1.98,
    artifacts_10_round_sd: 143,
    artifacts_10_round_da: 17508
  },
  {
    char: 'caesar',
    app_rate_sd: 36.59,
    app_rate_sd_e1: 11.45,
    app_rate_sd_s0: 6.51,
    avg_round_sd: 135,
    avg_round_sd_e1: 100,
    avg_round_sd_s0: 154,
    sample_sd: 1777,
    sample_size_players_sd: 0,
    app_rate_da: 41.63,
    app_rate_da_e1: 11.03,
    app_rate_da_s0: 9.12,
    avg_round_da: 21152,
    avg_round_da_e1: 29548,
    avg_round_da_s0: 18254,
    app_rate_da_boss_1: 12.91,
    avg_round_da_boss_1: 21784,
    app_rate_da_boss_2: 9.52,
    avg_round_da_boss_2: 24067,
    app_rate_da_boss_3: 23.3,
    avg_round_da_boss_3: 19887,
    sample_da: 2411,
    sample_size_players_da: 0,
    app_0: 89.84,
    round_0_sd: 135,
    round_0_da: 21152,
    app_1: 5.81,
    round_1_sd: 107,
    round_1_da: 27525,
    app_2: 3.11,
    round_2_sd: 87,
    round_2_da: 35071,
    app_3: 0.32,
    round_3_sd: 74,
    round_3_da: 34406,
    app_4: 0.07,
    round_4_sd: 36,
    round_4_da: 42210,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 0.84,
    round_6_sd: 55,
    round_6_da: 51695,
    cons_avg: 0.18,
    weapons_1: 'Tusks of Fury',
    weapons_1_app: 47.84,
    weapons_1_round_sd: 129,
    weapons_1_round_da: 22260,
    weapons_2: 'Original Transmorpher',
    weapons_2_app: 21.29,
    weapons_2_round_sd: 142,
    weapons_2_round_da: 19474,
    weapons_3: 'Demara Battery Mark II',
    weapons_3_app: 8.15,
    weapons_3_round_sd: 130,
    weapons_3_round_da: 22275,
    weapons_4: 'Precious Fossilized Core',
    weapons_4_app: 6.15,
    weapons_4_round_sd: 141,
    weapons_4_round_da: 20915,
    weapons_5: 'Hellfire Gears',
    weapons_5_app: 4.91,
    weapons_5_round_sd: 135,
    weapons_5_round_da: 20866,
    weapons_6: 'The Restrained',
    weapons_6_app: 3.6,
    weapons_6_round_sd: 133,
    weapons_6_round_da: 21801,
    weapons_7: 'Spring Embrace',
    weapons_7_app: 2.92,
    weapons_7_round_sd: 138,
    weapons_7_round_da: 20161,
    weapons_8: 'Peacekeeper - Specialized',
    weapons_8_app: 1.77,
    weapons_8_round_sd: 148,
    weapons_8_round_da: 19323,
    weapons_9: 'Steam Oven',
    weapons_9_app: 0.69,
    weapons_9_round_sd: 135,
    weapons_9_round_da: 22563,
    weapons_10: 'Big Cylinder',
    weapons_10_app: 0.4,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 16133,
    artifacts_1: 'Proto Punk, Shockstar Disco',
    artifacts_1_1: 'Proto Punk',
    artifacts_1_2: 'Shockstar Disco',
    artifacts_1_3: '',
    artifacts_1_app: 85.56,
    artifacts_1_round_sd: 134,
    artifacts_1_round_da: 21183,
    artifacts_2: 'Proto Punk, Swing Jazz',
    artifacts_2_1: 'Proto Punk',
    artifacts_2_2: 'Swing Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 7.07,
    artifacts_2_round_sd: 134,
    artifacts_2_round_da: 21790,
    artifacts_3: 'Proto Punk, Flex',
    artifacts_3_1: 'Proto Punk',
    artifacts_3_2: 'Flex',
    artifacts_3_3: '',
    artifacts_3_app: 1.03,
    artifacts_3_round_sd: 151,
    artifacts_3_round_da: 19489,
    artifacts_4: 'Freedom Blues, Shockstar Disco',
    artifacts_4_1: 'Freedom Blues',
    artifacts_4_2: 'Shockstar Disco',
    artifacts_4_3: '',
    artifacts_4_app: 0.76,
    artifacts_4_round_sd: 155,
    artifacts_4_round_da: 22969,
    artifacts_5: 'Swing Jazz, Shockstar Disco',
    artifacts_5_1: 'Swing Jazz',
    artifacts_5_2: 'Shockstar Disco',
    artifacts_5_3: '',
    artifacts_5_app: 0.7,
    artifacts_5_round_sd: 136,
    artifacts_5_round_da: 21014,
    artifacts_6: 'Proto Punk, Woodpecker Electro',
    artifacts_6_1: 'Proto Punk',
    artifacts_6_2: 'Woodpecker Electro',
    artifacts_6_3: '',
    artifacts_6_app: 0.51,
    artifacts_6_round_sd: 147,
    artifacts_6_round_da: 22334,
    artifacts_7: 'Proto Punk, Fanged Metal',
    artifacts_7_1: 'Proto Punk',
    artifacts_7_2: 'Fanged Metal',
    artifacts_7_3: '',
    artifacts_7_app: 0.45,
    artifacts_7_round_sd: 139,
    artifacts_7_round_da: 19226,
    artifacts_8: 'Proto Punk, Freedom Blues',
    artifacts_8_1: 'Proto Punk',
    artifacts_8_2: 'Freedom Blues',
    artifacts_8_3: '',
    artifacts_8_app: 0.43,
    artifacts_8_round_sd: 117,
    artifacts_8_round_da: 18244,
    artifacts_9: 'Shockstar Disco, Proto Punk',
    artifacts_9_1: 'Shockstar Disco',
    artifacts_9_2: 'Proto Punk',
    artifacts_9_3: '',
    artifacts_9_app: 0.39,
    artifacts_9_round_sd: 166,
    artifacts_9_round_da: 16017,
    artifacts_10: 'Freedom Blues, Swing Jazz',
    artifacts_10_1: 'Freedom Blues',
    artifacts_10_2: 'Swing Jazz',
    artifacts_10_3: '',
    artifacts_10_app: 0.18,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 18651
  },
  {
    char: 'burnice',
    app_rate_sd: 36.87,
    app_rate_sd_e1: 10.34,
    app_rate_sd_s0: 8.36,
    avg_round_sd: 128,
    avg_round_sd_e1: 97,
    avg_round_sd_s0: 145,
    sample_sd: 1791,
    sample_size_players_sd: 0,
    app_rate_da: 46.36,
    app_rate_da_e1: 10.7,
    app_rate_da_s0: 12.43,
    avg_round_da: 21538,
    avg_round_da_e1: 29241,
    avg_round_da_s0: 18649,
    app_rate_da_boss_1: 6.74,
    avg_round_da_boss_1: 22417,
    app_rate_da_boss_2: 13.69,
    avg_round_da_boss_2: 28554,
    app_rate_da_boss_3: 30.61,
    avg_round_da_boss_3: 18332,
    sample_da: 2685,
    sample_size_players_da: 0,
    app_0: 88.53,
    round_0_sd: 128,
    round_0_da: 21538,
    app_1: 7.39,
    round_1_sd: 110,
    round_1_da: 24606,
    app_2: 2.65,
    round_2_sd: 93,
    round_2_da: 30991,
    app_3: 0.51,
    round_3_sd: 79,
    round_3_da: 35833,
    app_4: 0.09,
    round_4_sd: 118,
    round_4_da: 23447,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 0.83,
    round_6_sd: 72,
    round_6_da: 45156,
    cons_avg: 0.19,
    weapons_1: 'Flamemaker Shaker',
    weapons_1_app: 40.5,
    weapons_1_round_sd: 120,
    weapons_1_round_da: 22186,
    weapons_2: 'Weeping Gemini',
    weapons_2_app: 23.66,
    weapons_2_round_sd: 129,
    weapons_2_round_da: 21112,
    weapons_3: 'Electro-Lip Gloss',
    weapons_3_app: 14.83,
    weapons_3_round_sd: 131,
    weapons_3_round_da: 21902,
    weapons_4: 'Roaring Ride',
    weapons_4_app: 8.16,
    weapons_4_round_sd: 140,
    weapons_4_round_da: 20544,
    weapons_5: 'Fusion Compiler',
    weapons_5_app: 7.37,
    weapons_5_round_sd: 123,
    weapons_5_round_da: 22605,
    weapons_6: 'Rainforest Gourmet',
    weapons_6_app: 5.25,
    weapons_6_round_sd: 141,
    weapons_6_round_da: 18768,
    weapons_7: 'Hailstorm Shrine',
    weapons_7_app: 0.05,
    weapons_7_round_sd: 89,
    weapons_7_round_da: 29140,
    weapons_8: '[Magnetic Storm] Bravo',
    weapons_8_app: 0.03,
    weapons_8_round_sd: 186,
    weapons_8_round_da: 0.0,
    weapons_9: 'Timeweaver',
    weapons_9_app: 0.02,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 16643,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Chaos Jazz, Freedom Blues',
    artifacts_1_1: 'Chaos Jazz',
    artifacts_1_2: 'Freedom Blues',
    artifacts_1_3: '',
    artifacts_1_app: 55.14,
    artifacts_1_round_sd: 129,
    artifacts_1_round_da: 21397,
    artifacts_2: 'Chaos Jazz, Inferno Metal',
    artifacts_2_1: 'Chaos Jazz',
    artifacts_2_2: 'Inferno Metal',
    artifacts_2_3: '',
    artifacts_2_app: 18.74,
    artifacts_2_round_sd: 127,
    artifacts_2_round_da: 21330,
    artifacts_3: 'Chaos Jazz, Swing Jazz',
    artifacts_3_1: 'Chaos Jazz',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 17.12,
    artifacts_3_round_sd: 121,
    artifacts_3_round_da: 22207,
    artifacts_4: 'Chaos Jazz, Hormone Punk',
    artifacts_4_1: 'Chaos Jazz',
    artifacts_4_2: 'Hormone Punk',
    artifacts_4_3: '',
    artifacts_4_app: 2.02,
    artifacts_4_round_sd: 124,
    artifacts_4_round_da: 21605,
    artifacts_5: "Chaos Jazz, Phaethon's Melody",
    artifacts_5_1: 'Chaos Jazz',
    artifacts_5_2: "Phaethon's Melody",
    artifacts_5_3: '',
    artifacts_5_app: 1.41,
    artifacts_5_round_sd: 114,
    artifacts_5_round_da: 24410,
    artifacts_6: 'Chaos Jazz, Puffer Electro',
    artifacts_6_1: 'Chaos Jazz',
    artifacts_6_2: 'Puffer Electro',
    artifacts_6_3: '',
    artifacts_6_app: 1.14,
    artifacts_6_round_sd: 135,
    artifacts_6_round_da: 25814,
    artifacts_7: 'Chaos Jazz, Astral Voice',
    artifacts_7_1: 'Chaos Jazz',
    artifacts_7_2: 'Astral Voice',
    artifacts_7_3: '',
    artifacts_7_app: 0.9,
    artifacts_7_round_sd: 119,
    artifacts_7_round_da: 22811,
    artifacts_8: 'Chaos Jazz, Flex',
    artifacts_8_1: 'Chaos Jazz',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 0.66,
    artifacts_8_round_sd: 137,
    artifacts_8_round_da: 17804,
    artifacts_9: 'Freedom Blues, Chaos Jazz',
    artifacts_9_1: 'Freedom Blues',
    artifacts_9_2: 'Chaos Jazz',
    artifacts_9_3: '',
    artifacts_9_app: 0.43,
    artifacts_9_round_sd: 122,
    artifacts_9_round_da: 23550,
    artifacts_10: 'Chaos Jazz, Woodpecker Electro',
    artifacts_10_1: 'Chaos Jazz',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.17,
    artifacts_10_round_sd: 135,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'yanagi',
    app_rate_sd: 41.05,
    app_rate_sd_e1: 14.95,
    app_rate_sd_s0: 4.34,
    avg_round_sd: 118,
    avg_round_sd_e1: 90,
    avg_round_sd_s0: 144,
    sample_sd: 1994,
    sample_size_players_sd: 0,
    app_rate_da: 48.98,
    app_rate_da_e1: 13.19,
    app_rate_da_s0: 9.56,
    avg_round_da: 25903,
    avg_round_da_e1: 36608,
    avg_round_da_s0: 21354,
    app_rate_da_boss_1: 19.86,
    avg_round_da_boss_1: 22174,
    app_rate_da_boss_2: 31.1,
    avg_round_da_boss_2: 28978,
    app_rate_da_boss_3: 1.53,
    avg_round_da_boss_3: 17776,
    sample_da: 2837,
    sample_size_players_da: 0,
    app_0: 90.42,
    round_0_sd: 118,
    round_0_da: 25903,
    app_1: 5.0,
    round_1_sd: 100,
    round_1_da: 32119,
    app_2: 3.2,
    round_2_sd: 86,
    round_2_da: 36230,
    app_3: 0.31,
    round_3_sd: 100,
    round_3_da: 38439,
    app_4: 0.04,
    round_4_sd: 85,
    round_4_da: 39017,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.03,
    round_6_sd: 57,
    round_6_da: 48575,
    cons_avg: 0.18,
    weapons_1: 'Timeweaver',
    weapons_1_app: 42.69,
    weapons_1_round_sd: 112,
    weapons_1_round_da: 27134,
    weapons_2: 'Weeping Gemini',
    weapons_2_app: 24.64,
    weapons_2_round_sd: 125,
    weapons_2_round_da: 25216,
    weapons_3: 'Fusion Compiler',
    weapons_3_app: 18.05,
    weapons_3_round_sd: 115,
    weapons_3_round_da: 26461,
    weapons_4: 'Electro-Lip Gloss',
    weapons_4_app: 9.78,
    weapons_4_round_sd: 122,
    weapons_4_round_da: 24780,
    weapons_5: 'Rainforest Gourmet',
    weapons_5_app: 3.63,
    weapons_5_round_sd: 133,
    weapons_5_round_da: 21813,
    weapons_6: 'Roaring Ride',
    weapons_6_app: 0.94,
    weapons_6_round_sd: 118,
    weapons_6_round_da: 24734,
    weapons_7: 'Sharpened Stinger',
    weapons_7_app: 0.09,
    weapons_7_round_sd: 89,
    weapons_7_round_da: 14486,
    weapons_8: 'Kaboom the Cannon',
    weapons_8_app: 0.04,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 0,
    weapons_9: 'Drill Rig - Red Axis',
    weapons_9_app: 0.04,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0,
    weapons_10: 'Flamemaker Shaker',
    weapons_10_app: 0.02,
    weapons_10_round_sd: 91,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Chaos Jazz, Freedom Blues',
    artifacts_1_1: 'Chaos Jazz',
    artifacts_1_2: 'Freedom Blues',
    artifacts_1_3: '',
    artifacts_1_app: 70.29,
    artifacts_1_round_sd: 117,
    artifacts_1_round_da: 26181,
    artifacts_2: 'Thunder Metal, Freedom Blues',
    artifacts_2_1: 'Thunder Metal',
    artifacts_2_2: 'Freedom Blues',
    artifacts_2_3: '',
    artifacts_2_app: 7.03,
    artifacts_2_round_sd: 130,
    artifacts_2_round_da: 23284,
    artifacts_3: 'Thunder Metal, Chaos Jazz',
    artifacts_3_1: 'Thunder Metal',
    artifacts_3_2: 'Chaos Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 5.83,
    artifacts_3_round_sd: 126,
    artifacts_3_round_da: 24346,
    artifacts_4: 'Chaos Jazz, Thunder Metal',
    artifacts_4_1: 'Chaos Jazz',
    artifacts_4_2: 'Thunder Metal',
    artifacts_4_3: '',
    artifacts_4_app: 5.03,
    artifacts_4_round_sd: 119,
    artifacts_4_round_da: 25066,
    artifacts_5: 'Freedom Blues, Chaos Jazz',
    artifacts_5_1: 'Freedom Blues',
    artifacts_5_2: 'Chaos Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 2.08,
    artifacts_5_round_sd: 121,
    artifacts_5_round_da: 26839,
    artifacts_6: 'Chaos Jazz, Puffer Electro',
    artifacts_6_1: 'Chaos Jazz',
    artifacts_6_2: 'Puffer Electro',
    artifacts_6_3: '',
    artifacts_6_app: 1.72,
    artifacts_6_round_sd: 98,
    artifacts_6_round_da: 31321,
    artifacts_7: 'Chaos Jazz, Swing Jazz',
    artifacts_7_1: 'Chaos Jazz',
    artifacts_7_2: 'Swing Jazz',
    artifacts_7_3: '',
    artifacts_7_app: 1.26,
    artifacts_7_round_sd: 105,
    artifacts_7_round_da: 27743,
    artifacts_8: 'Freedom Blues, Thunder Metal',
    artifacts_8_1: 'Freedom Blues',
    artifacts_8_2: 'Thunder Metal',
    artifacts_8_3: '',
    artifacts_8_app: 0.72,
    artifacts_8_round_sd: 138,
    artifacts_8_round_da: 23738,
    artifacts_9: 'AP +30, AP +30, Thunder Metal',
    artifacts_9_1: 'AP +30',
    artifacts_9_2: 'AP +30',
    artifacts_9_3: 'Thunder Metal',
    artifacts_9_app: 0.7,
    artifacts_9_round_sd: 128,
    artifacts_9_round_da: 21724,
    artifacts_10: 'Thunder Metal, Puffer Electro',
    artifacts_10_1: 'Thunder Metal',
    artifacts_10_2: 'Puffer Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.35,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 26595
  },
  {
    char: 'lighter',
    app_rate_sd: 18.24,
    app_rate_sd_e1: 4.78,
    app_rate_sd_s0: 3.66,
    avg_round_sd: 107,
    avg_round_sd_e1: 81,
    avg_round_sd_s0: 126,
    sample_sd: 886,
    sample_size_players_sd: 0,
    app_rate_da: 19.85,
    app_rate_da_e1: 5.08,
    app_rate_da_s0: 4.49,
    avg_round_da: 24154,
    avg_round_da_e1: 34526,
    avg_round_da_s0: 20374,
    app_rate_da_boss_1: 0.62,
    avg_round_da_boss_1: 20448,
    app_rate_da_boss_2: 2.33,
    avg_round_da_boss_2: 22385,
    app_rate_da_boss_3: 19.2,
    avg_round_da_boss_3: 24469,
    sample_da: 1150,
    sample_size_players_da: 0,
    app_0: 91.3,
    round_0_sd: 107,
    round_0_da: 24154,
    app_1: 3.97,
    round_1_sd: 89,
    round_1_da: 28976,
    app_2: 2.53,
    round_2_sd: 65,
    round_2_da: 41676,
    app_3: 0.79,
    round_3_sd: 78,
    round_3_da: 37529,
    app_4: 0.18,
    round_4_sd: 600,
    round_4_da: 42306,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.31,
    round_6_sd: 53,
    round_6_da: 50086,
    cons_avg: 0.19,
    weapons_1: 'Blazing Laurel',
    weapons_1_app: 46.53,
    weapons_1_round_sd: 100,
    weapons_1_round_da: 25772,
    weapons_2: 'Hellfire Gears',
    weapons_2_app: 17.22,
    weapons_2_round_sd: 104,
    weapons_2_round_da: 23777,
    weapons_3: 'Precious Fossilized Core',
    weapons_3_app: 10.9,
    weapons_3_round_sd: 118,
    weapons_3_round_da: 21761,
    weapons_4: 'Steam Oven',
    weapons_4_app: 10.66,
    weapons_4_round_sd: 120,
    weapons_4_round_da: 22471,
    weapons_5: 'The Restrained',
    weapons_5_app: 7.72,
    weapons_5_round_sd: 113,
    weapons_5_round_da: 23949,
    weapons_6: 'Ice-Jade Teapot',
    weapons_6_app: 2.99,
    weapons_6_round_sd: 92,
    weapons_6_round_da: 27357,
    weapons_7: 'Six Shooter',
    weapons_7_app: 1.95,
    weapons_7_round_sd: 116,
    weapons_7_round_da: 24842,
    weapons_8: 'Demara Battery Mark II',
    weapons_8_app: 0.45,
    weapons_8_round_sd: 127,
    weapons_8_round_da: 19149,
    weapons_9: '[Vortex] Arrow',
    weapons_9_app: 0.25,
    weapons_9_round_sd: 148,
    weapons_9_round_da: 15785,
    weapons_10: 'Box Cutter',
    weapons_10_app: 0.14,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 20855,
    artifacts_1: 'Shockstar Disco, Swing Jazz',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 55.94,
    artifacts_1_round_sd: 110,
    artifacts_1_round_da: 23718,
    artifacts_2: 'Shockstar Disco, Inferno Metal',
    artifacts_2_1: 'Shockstar Disco',
    artifacts_2_2: 'Inferno Metal',
    artifacts_2_3: '',
    artifacts_2_app: 13.74,
    artifacts_2_round_sd: 112,
    artifacts_2_round_da: 23485,
    artifacts_3: 'Shockstar Disco, Woodpecker Electro',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 8.14,
    artifacts_3_round_sd: 102,
    artifacts_3_round_da: 25354,
    artifacts_4: 'Proto Punk, Shockstar Disco',
    artifacts_4_1: 'Proto Punk',
    artifacts_4_2: 'Shockstar Disco',
    artifacts_4_3: '',
    artifacts_4_app: 4.25,
    artifacts_4_round_sd: 83,
    artifacts_4_round_da: 29343,
    artifacts_5: 'Swing Jazz, Shockstar Disco',
    artifacts_5_1: 'Swing Jazz',
    artifacts_5_2: 'Shockstar Disco',
    artifacts_5_3: '',
    artifacts_5_app: 2.81,
    artifacts_5_round_sd: 102,
    artifacts_5_round_da: 28577,
    artifacts_6: 'Inferno Metal, Shockstar Disco',
    artifacts_6_1: 'Inferno Metal',
    artifacts_6_2: 'Shockstar Disco',
    artifacts_6_3: '',
    artifacts_6_app: 2.35,
    artifacts_6_round_sd: 97,
    artifacts_6_round_da: 25293,
    artifacts_7: 'Shockstar Disco, Hormone Punk',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Hormone Punk',
    artifacts_7_3: '',
    artifacts_7_app: 1.24,
    artifacts_7_round_sd: 102,
    artifacts_7_round_da: 24577,
    artifacts_8: 'Shockstar Disco, Freedom Blues',
    artifacts_8_1: 'Shockstar Disco',
    artifacts_8_2: 'Freedom Blues',
    artifacts_8_3: '',
    artifacts_8_app: 1.15,
    artifacts_8_round_sd: 106,
    artifacts_8_round_da: 26247,
    artifacts_9: 'Shockstar Disco, Branch & Blade Song',
    artifacts_9_1: 'Shockstar Disco',
    artifacts_9_2: 'Branch & Blade Song',
    artifacts_9_3: '',
    artifacts_9_app: 1.08,
    artifacts_9_round_sd: 116,
    artifacts_9_round_da: 22843,
    artifacts_10: 'Shockstar Disco, Flex',
    artifacts_10_1: 'Shockstar Disco',
    artifacts_10_2: 'Flex',
    artifacts_10_3: '',
    artifacts_10_app: 0.59,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 20745
  },
  {
    char: 'harumasa',
    app_rate_sd: 16.08,
    app_rate_sd_e1: 4.98,
    app_rate_sd_s0: 4.65,
    avg_round_sd: 152,
    avg_round_sd_e1: 123,
    avg_round_sd_s0: 157,
    sample_sd: 781,
    sample_size_players_sd: 0,
    app_rate_da: 25.17,
    app_rate_da_e1: 5.9,
    app_rate_da_s0: 10.53,
    avg_round_da: 19966,
    avg_round_da_e1: 27497,
    avg_round_da_s0: 18388,
    app_rate_da_boss_1: 25.97,
    avg_round_da_boss_1: 20054,
    app_rate_da_boss_2: 1.12,
    avg_round_da_boss_2: 16938,
    app_rate_da_boss_3: 0.14,
    avg_round_da_boss_3: 0,
    sample_da: 1458,
    sample_size_players_da: 0,
    app_0: 86.87,
    round_0_sd: 152,
    round_0_da: 19966,
    app_1: 7.39,
    round_1_sd: 134,
    round_1_da: 25842,
    app_2: 2.92,
    round_2_sd: 131,
    round_2_da: 28148,
    app_3: 1.0,
    round_3_sd: 93,
    round_3_da: 30997,
    app_4: 0.19,
    round_4_sd: 150,
    round_4_da: 23571,
    app_5: 0.33,
    round_5_sd: 118,
    round_5_da: 28486,
    app_6: 1.32,
    round_6_sd: 77,
    round_6_da: 40897,
    cons_avg: 0.27,
    weapons_1: 'Zanshin Herb Case',
    weapons_1_app: 37.63,
    weapons_1_round_sd: 149,
    weapons_1_round_da: 21794,
    weapons_2: 'Marcato Desire',
    weapons_2_app: 30.77,
    weapons_2_round_sd: 158,
    weapons_2_round_da: 19131,
    weapons_3: 'The Brimstone',
    weapons_3_app: 10.5,
    weapons_3_round_sd: 145,
    weapons_3_round_da: 20787,
    weapons_4: 'Starlight Engine',
    weapons_4_app: 5.42,
    weapons_4_round_sd: 141,
    weapons_4_round_da: 20631,
    weapons_5: 'Drill Rig - Red Axis',
    weapons_5_app: 2.94,
    weapons_5_round_sd: 175,
    weapons_5_round_da: 15207,
    weapons_6: 'Gilded Blossom',
    weapons_6_app: 2.05,
    weapons_6_round_sd: 176,
    weapons_6_round_da: 16841,
    weapons_7: 'Cannon Rotor',
    weapons_7_app: 1.95,
    weapons_7_round_sd: 167,
    weapons_7_round_da: 18765,
    weapons_8: 'Steel Cushion',
    weapons_8_app: 1.13,
    weapons_8_round_sd: 138,
    weapons_8_round_da: 20258,
    weapons_9: 'Heartstring Nocturne',
    weapons_9_app: 0.91,
    weapons_9_round_sd: 130,
    weapons_9_round_da: 23942,
    weapons_10: 'Severed Innocence',
    weapons_10_app: 0.77,
    weapons_10_round_sd: 174,
    weapons_10_round_da: 19715,
    artifacts_1: 'Thunder Metal, Woodpecker Electro',
    artifacts_1_1: 'Thunder Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 44.36,
    artifacts_1_round_sd: 157,
    artifacts_1_round_da: 19313,
    artifacts_2: 'Thunder Metal, Branch & Blade Song',
    artifacts_2_1: 'Thunder Metal',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 13.11,
    artifacts_2_round_sd: 148,
    artifacts_2_round_da: 21715,
    artifacts_3: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_3_1: 'Woodpecker Electro',
    artifacts_3_2: 'Branch & Blade Song',
    artifacts_3_3: '',
    artifacts_3_app: 4.37,
    artifacts_3_round_sd: 148,
    artifacts_3_round_da: 19912,
    artifacts_4: 'Woodpecker Electro, Thunder Metal',
    artifacts_4_1: 'Woodpecker Electro',
    artifacts_4_2: 'Thunder Metal',
    artifacts_4_3: '',
    artifacts_4_app: 4.19,
    artifacts_4_round_sd: 142,
    artifacts_4_round_da: 20893,
    artifacts_5: 'Thunder Metal, Hormone Punk',
    artifacts_5_1: 'Thunder Metal',
    artifacts_5_2: 'Hormone Punk',
    artifacts_5_3: '',
    artifacts_5_app: 3.08,
    artifacts_5_round_sd: 150,
    artifacts_5_round_da: 20546,
    artifacts_6: 'Hormone Punk, Thunder Metal',
    artifacts_6_1: 'Hormone Punk',
    artifacts_6_2: 'Thunder Metal',
    artifacts_6_3: '',
    artifacts_6_app: 3.05,
    artifacts_6_round_sd: 150,
    artifacts_6_round_da: 17899,
    artifacts_7: 'Shadow Harmony, Woodpecker Electro',
    artifacts_7_1: 'Shadow Harmony',
    artifacts_7_2: 'Woodpecker Electro',
    artifacts_7_3: '',
    artifacts_7_app: 2.07,
    artifacts_7_round_sd: 142,
    artifacts_7_round_da: 23144,
    artifacts_8: 'Shadow Harmony, Branch & Blade Song',
    artifacts_8_1: 'Shadow Harmony',
    artifacts_8_2: 'Branch & Blade Song',
    artifacts_8_3: '',
    artifacts_8_app: 1.94,
    artifacts_8_round_sd: 145,
    artifacts_8_round_da: 21830,
    artifacts_9: 'Thunder Metal, Flex',
    artifacts_9_1: 'Thunder Metal',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 1.56,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 18335,
    artifacts_10: 'Hormone Punk, Branch & Blade Song',
    artifacts_10_1: 'Hormone Punk',
    artifacts_10_2: 'Branch & Blade Song',
    artifacts_10_3: '',
    artifacts_10_app: 0.84,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 22156
  },
  {
    char: 'miyabi',
    app_rate_sd: 71.03,
    app_rate_sd_e1: 30.68,
    app_rate_sd_s0: 3.48,
    avg_round_sd: 114,
    avg_round_sd_e1: 87,
    avg_round_sd_s0: 134,
    sample_sd: 3450,
    sample_size_players_sd: 0,
    app_rate_da: 90.37,
    app_rate_da_e1: 34.72,
    app_rate_da_s0: 5.97,
    avg_round_da: 27083,
    avg_round_da_e1: 36253,
    avg_round_da_s0: 22249,
    app_rate_da_boss_1: 2.3,
    avg_round_da_boss_1: 24248,
    app_rate_da_boss_2: 91.78,
    avg_round_da_boss_2: 27240,
    app_rate_da_boss_3: 2.1,
    avg_round_da_boss_3: 20386,
    sample_da: 5234,
    sample_size_players_da: 0,
    app_0: 62.25,
    round_0_sd: 114,
    round_0_da: 27083,
    app_1: 8.73,
    round_1_sd: 104,
    round_1_da: 30104,
    app_2: 18.99,
    round_2_sd: 87,
    round_2_da: 35706,
    app_3: 3.62,
    round_3_sd: 87,
    round_3_da: 36076,
    app_4: 1.02,
    round_4_sd: 83,
    round_4_da: 35721,
    app_5: 0.28,
    round_5_sd: 82,
    round_5_da: 36544,
    app_6: 5.11,
    round_6_sd: 61,
    round_6_da: 50784,
    cons_avg: 0.94,
    weapons_1: 'Hailstorm Shrine',
    weapons_1_app: 93.2,
    weapons_1_round_sd: 112,
    weapons_1_round_da: 27723,
    weapons_2: 'Rainforest Gourmet',
    weapons_2_app: 1.89,
    weapons_2_round_sd: 138,
    weapons_2_round_da: 20914,
    weapons_3: 'Fusion Compiler',
    weapons_3_app: 1.74,
    weapons_3_round_sd: 129,
    weapons_3_round_da: 23209,
    weapons_4: 'Weeping Gemini',
    weapons_4_app: 1.23,
    weapons_4_round_sd: 132,
    weapons_4_round_da: 22983,
    weapons_5: 'Roaring Ride',
    weapons_5_app: 0.81,
    weapons_5_round_sd: 143,
    weapons_5_round_da: 20598,
    weapons_6: 'Electro-Lip Gloss',
    weapons_6_app: 0.51,
    weapons_6_round_sd: 115,
    weapons_6_round_da: 20701,
    weapons_7: 'Steel Cushion',
    weapons_7_app: 0.25,
    weapons_7_round_sd: 120,
    weapons_7_round_da: 24482,
    weapons_8: 'Marcato Desire',
    weapons_8_app: 0.15,
    weapons_8_round_sd: 130,
    weapons_8_round_da: 21176,
    weapons_9: 'Deep Sea Visitor',
    weapons_9_app: 0.04,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 17538,
    weapons_10: 'Cannon Rotor',
    weapons_10_app: 0.02,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 35785,
    artifacts_1: 'Branch & Blade Song, Woodpecker Electro',
    artifacts_1_1: 'Branch & Blade Song',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 74.58,
    artifacts_1_round_sd: 115,
    artifacts_1_round_da: 26927,
    artifacts_2: 'Branch & Blade Song, Polar Metal',
    artifacts_2_1: 'Branch & Blade Song',
    artifacts_2_2: 'Polar Metal',
    artifacts_2_3: '',
    artifacts_2_app: 14.0,
    artifacts_2_round_sd: 114,
    artifacts_2_round_da: 26540,
    artifacts_3: 'Branch & Blade Song, Astral Voice',
    artifacts_3_1: 'Branch & Blade Song',
    artifacts_3_2: 'Astral Voice',
    artifacts_3_3: '',
    artifacts_3_app: 6.45,
    artifacts_3_round_sd: 104,
    artifacts_3_round_da: 30798,
    artifacts_4: 'Branch & Blade Song, Puffer Electro',
    artifacts_4_1: 'Branch & Blade Song',
    artifacts_4_2: 'Puffer Electro',
    artifacts_4_3: '',
    artifacts_4_app: 1.53,
    artifacts_4_round_sd: 102,
    artifacts_4_round_da: 30727,
    artifacts_5: 'Branch & Blade Song, Hormone Punk',
    artifacts_5_1: 'Branch & Blade Song',
    artifacts_5_2: 'Hormone Punk',
    artifacts_5_3: '',
    artifacts_5_app: 1.47,
    artifacts_5_round_sd: 94,
    artifacts_5_round_da: 32774,
    artifacts_6: 'Branch & Blade Song, Flex',
    artifacts_6_1: 'Branch & Blade Song',
    artifacts_6_2: 'Flex',
    artifacts_6_3: '',
    artifacts_6_app: 0.7,
    artifacts_6_round_sd: 120,
    artifacts_6_round_da: 23559,
    artifacts_7: 'Branch & Blade Song, Freedom Blues',
    artifacts_7_1: 'Branch & Blade Song',
    artifacts_7_2: 'Freedom Blues',
    artifacts_7_3: '',
    artifacts_7_app: 0.26,
    artifacts_7_round_sd: 107,
    artifacts_7_round_da: 27408,
    artifacts_8: 'Branch & Blade Song, Chaos Jazz',
    artifacts_8_1: 'Branch & Blade Song',
    artifacts_8_2: 'Chaos Jazz',
    artifacts_8_3: '',
    artifacts_8_app: 0.18,
    artifacts_8_round_sd: 126,
    artifacts_8_round_da: 24227,
    artifacts_9: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_9_1: 'Woodpecker Electro',
    artifacts_9_2: 'Branch & Blade Song',
    artifacts_9_3: '',
    artifacts_9_app: 0.15,
    artifacts_9_round_sd: 139,
    artifacts_9_round_da: 25795,
    artifacts_10: 'Branch & Blade Song, Woodpecker Electro, Flex',
    artifacts_10_1: 'Branch & Blade Song',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: 'Flex',
    artifacts_10_app: 0.14,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 24125
  },
  {
    char: 'astra-yao',
    app_rate_sd: 82.81,
    app_rate_sd_e1: 24.34,
    app_rate_sd_s0: 16.53,
    avg_round_sd: 111,
    avg_round_sd_e1: 83,
    avg_round_sd_s0: 130,
    sample_sd: 4022,
    sample_size_players_sd: 0,
    app_rate_da: 81.13,
    app_rate_da_e1: 21.77,
    app_rate_da_s0: 20.89,
    avg_round_da: 25354,
    avg_round_da_e1: 35670,
    avg_round_da_s0: 21971,
    app_rate_da_boss_1: 22.16,
    avg_round_da_boss_1: 25949,
    app_rate_da_boss_2: 17.74,
    avg_round_da_boss_2: 31060,
    app_rate_da_boss_3: 49.39,
    avg_round_da_boss_3: 23462,
    sample_da: 4699,
    sample_size_players_da: 0,
    app_0: 86.51,
    round_0_sd: 111,
    round_0_da: 25354,
    app_1: 10.14,
    round_1_sd: 86,
    round_1_da: 34191,
    app_2: 2.46,
    round_2_sd: 71,
    round_2_da: 40421,
    app_3: 0.14,
    round_3_sd: 72,
    round_3_da: 35519,
    app_4: 0.08,
    round_4_sd: 79,
    round_4_da: 40497,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 0.66,
    round_6_sd: 47,
    round_6_da: 53380,
    cons_avg: 0.2,
    weapons_1: 'Bashful Demon',
    weapons_1_app: 37.02,
    weapons_1_round_sd: 112,
    weapons_1_round_da: 25093,
    weapons_2: 'Elegant Vanity',
    weapons_2_app: 26.39,
    weapons_2_round_sd: 105,
    weapons_2_round_da: 27473,
    weapons_3: 'Kaboom the Cannon',
    weapons_3_app: 21.92,
    weapons_3_round_sd: 109,
    weapons_3_round_da: 25688,
    weapons_4: 'The Vault',
    weapons_4_app: 11.34,
    weapons_4_round_sd: 123,
    weapons_4_round_da: 22918,
    weapons_5: 'Slice of Time',
    weapons_5_app: 1.07,
    weapons_5_round_sd: 132,
    weapons_5_round_da: 21300,
    weapons_6: 'Weeping Cradle',
    weapons_6_app: 0.88,
    weapons_6_round_sd: 122,
    weapons_6_round_da: 23994,
    weapons_7: 'Unfettered Game Ball',
    weapons_7_app: 0.72,
    weapons_7_round_sd: 116,
    weapons_7_round_da: 22258,
    weapons_8: '[Reverb] Mark II',
    weapons_8_app: 0.11,
    weapons_8_round_sd: 116,
    weapons_8_round_da: 20727,
    weapons_9: '[Reverb] Mark I',
    weapons_9_app: 0.09,
    weapons_9_round_sd: 104,
    weapons_9_round_da: 22426,
    weapons_10: 'Starlight Engine',
    weapons_10_app: 0.03,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0,
    artifacts_1: 'Astral Voice, Swing Jazz',
    artifacts_1_1: 'Astral Voice',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 84.59,
    artifacts_1_round_sd: 111,
    artifacts_1_round_da: 25541,
    artifacts_2: 'Astral Voice, Hormone Punk',
    artifacts_2_1: 'Astral Voice',
    artifacts_2_2: 'Hormone Punk',
    artifacts_2_3: '',
    artifacts_2_app: 12.57,
    artifacts_2_round_sd: 114,
    artifacts_2_round_da: 24771,
    artifacts_3: 'Astral Voice, Flex',
    artifacts_3_1: 'Astral Voice',
    artifacts_3_2: 'Flex',
    artifacts_3_3: '',
    artifacts_3_app: 0.69,
    artifacts_3_round_sd: 118,
    artifacts_3_round_da: 21064,
    artifacts_4: 'Swing Jazz, Astral Voice',
    artifacts_4_1: 'Swing Jazz',
    artifacts_4_2: 'Astral Voice',
    artifacts_4_3: '',
    artifacts_4_app: 0.68,
    artifacts_4_round_sd: 105,
    artifacts_4_round_da: 25934,
    artifacts_5: 'Astral Voice, Branch & Blade Song',
    artifacts_5_1: 'Astral Voice',
    artifacts_5_2: 'Branch & Blade Song',
    artifacts_5_3: '',
    artifacts_5_app: 0.27,
    artifacts_5_round_sd: 162,
    artifacts_5_round_da: 15788,
    artifacts_6: 'ATK +10%, Swing Jazz, Flex',
    artifacts_6_1: 'ATK +10%',
    artifacts_6_2: 'Swing Jazz',
    artifacts_6_3: 'Flex',
    artifacts_6_app: 0.2,
    artifacts_6_round_sd: 135,
    artifacts_6_round_da: 26738,
    artifacts_7: 'Astral Voice, Woodpecker Electro',
    artifacts_7_1: 'Astral Voice',
    artifacts_7_2: 'Woodpecker Electro',
    artifacts_7_3: '',
    artifacts_7_app: 0.16,
    artifacts_7_round_sd: 151,
    artifacts_7_round_da: 19987,
    artifacts_8: 'Astral Voice, Chaotic Metal',
    artifacts_8_1: 'Astral Voice',
    artifacts_8_2: 'Chaotic Metal',
    artifacts_8_3: '',
    artifacts_8_app: 0.11,
    artifacts_8_round_sd: 141,
    artifacts_8_round_da: 19674,
    artifacts_9: 'Swing Jazz, Hormone Punk',
    artifacts_9_1: 'Swing Jazz',
    artifacts_9_2: 'Hormone Punk',
    artifacts_9_3: '',
    artifacts_9_app: 0.06,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 16491,
    artifacts_10: 'Astral Voice, Freedom Blues',
    artifacts_10_1: 'Astral Voice',
    artifacts_10_2: 'Freedom Blues',
    artifacts_10_3: '',
    artifacts_10_app: 0.05,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 33063
  },
  {
    char: 'evelyn',
    app_rate_sd: 43.44,
    app_rate_sd_e1: 8.5,
    app_rate_sd_s0: 10.62,
    avg_round_sd: 115,
    avg_round_sd_e1: 86,
    avg_round_sd_s0: 133,
    sample_sd: 2110,
    sample_size_players_sd: 0,
    app_rate_da: 46.93,
    app_rate_da_e1: 8.62,
    app_rate_da_s0: 12.98,
    avg_round_da: 22481,
    avg_round_da_e1: 32607,
    avg_round_da_s0: 19478,
    app_rate_da_boss_1: 1.21,
    avg_round_da_boss_1: 21317,
    app_rate_da_boss_2: 0.42,
    avg_round_da_boss_2: 22910,
    app_rate_da_boss_3: 51.02,
    avg_round_da_boss_3: 22508,
    sample_da: 2718,
    sample_size_players_da: 0,
    app_0: 90.17,
    round_0_sd: 115,
    round_0_da: 22481,
    app_1: 5.66,
    round_1_sd: 91,
    round_1_da: 30462,
    app_2: 2.13,
    round_2_sd: 79,
    round_2_da: 37085,
    app_3: 0.65,
    round_3_sd: 68,
    round_3_da: 39923,
    app_4: 0.11,
    round_4_sd: 74,
    round_4_da: 40128,
    app_5: 0.12,
    round_5_sd: 80,
    round_5_da: 53955,
    app_6: 1.16,
    round_6_sd: 44,
    round_6_da: 54427,
    cons_avg: 0.19,
    weapons_1: 'Heartstring Nocturne',
    weapons_1_app: 63.43,
    weapons_1_round_sd: 105,
    weapons_1_round_da: 24384,
    weapons_2: 'Marcato Desire',
    weapons_2_app: 20.38,
    weapons_2_round_sd: 131,
    weapons_2_round_da: 19595,
    weapons_3: 'The Brimstone',
    weapons_3_app: 6.94,
    weapons_3_round_sd: 124,
    weapons_3_round_da: 21463,
    weapons_4: 'Starlight Engine',
    weapons_4_app: 3.19,
    weapons_4_round_sd: 131,
    weapons_4_round_da: 20200,
    weapons_5: 'Cannon Rotor',
    weapons_5_app: 1.36,
    weapons_5_round_sd: 144,
    weapons_5_round_da: 17938,
    weapons_6: 'Steel Cushion',
    weapons_6_app: 1.04,
    weapons_6_round_sd: 149,
    weapons_6_round_da: 18277,
    weapons_7: 'Gilded Blossom',
    weapons_7_app: 0.95,
    weapons_7_round_sd: 137,
    weapons_7_round_da: 18403,
    weapons_8: 'Zanshin Herb Case',
    weapons_8_app: 0.61,
    weapons_8_round_sd: 118,
    weapons_8_round_da: 24132,
    weapons_9: 'Severed Innocence',
    weapons_9_app: 0.26,
    weapons_9_round_sd: 112,
    weapons_9_round_da: 20888,
    weapons_10: 'Street Superstar',
    weapons_10_app: 0.25,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 17791,
    artifacts_1: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_1_1: 'Woodpecker Electro',
    artifacts_1_2: 'Branch & Blade Song',
    artifacts_1_3: '',
    artifacts_1_app: 49.26,
    artifacts_1_round_sd: 117,
    artifacts_1_round_da: 21858,
    artifacts_2: 'Woodpecker Electro, Inferno Metal',
    artifacts_2_1: 'Woodpecker Electro',
    artifacts_2_2: 'Inferno Metal',
    artifacts_2_3: '',
    artifacts_2_app: 18.4,
    artifacts_2_round_sd: 116,
    artifacts_2_round_da: 22488,
    artifacts_3: 'Woodpecker Electro, Puffer Electro',
    artifacts_3_1: 'Woodpecker Electro',
    artifacts_3_2: 'Puffer Electro',
    artifacts_3_3: '',
    artifacts_3_app: 4.02,
    artifacts_3_round_sd: 102,
    artifacts_3_round_da: 26287,
    artifacts_4: 'Inferno Metal, Branch & Blade Song',
    artifacts_4_1: 'Inferno Metal',
    artifacts_4_2: 'Branch & Blade Song',
    artifacts_4_3: '',
    artifacts_4_app: 2.42,
    artifacts_4_round_sd: 129,
    artifacts_4_round_da: 21175,
    artifacts_5: 'Branch & Blade Song, Inferno Metal, Woodpecker Electro',
    artifacts_5_1: 'Branch & Blade Song',
    artifacts_5_2: 'Inferno Metal',
    artifacts_5_3: 'Woodpecker Electro',
    artifacts_5_app: 2.16,
    artifacts_5_round_sd: 122,
    artifacts_5_round_da: 22513,
    artifacts_6: 'Woodpecker Electro, Astral Voice',
    artifacts_6_1: 'Woodpecker Electro',
    artifacts_6_2: 'Astral Voice',
    artifacts_6_3: '',
    artifacts_6_app: 1.79,
    artifacts_6_round_sd: 102,
    artifacts_6_round_da: 24421,
    artifacts_7: 'ATK +10%, Branch & Blade Song, Woodpecker Electro',
    artifacts_7_1: 'ATK +10%',
    artifacts_7_2: 'Branch & Blade Song',
    artifacts_7_3: 'Woodpecker Electro',
    artifacts_7_app: 1.55,
    artifacts_7_round_sd: 105,
    artifacts_7_round_da: 24213,
    artifacts_8: 'Hormone Punk, Inferno Metal',
    artifacts_8_1: 'Hormone Punk',
    artifacts_8_2: 'Inferno Metal',
    artifacts_8_3: '',
    artifacts_8_app: 1.53,
    artifacts_8_round_sd: 97,
    artifacts_8_round_da: 25113,
    artifacts_9: 'Branch & Blade Song, Woodpecker Electro, Inferno Metal',
    artifacts_9_1: 'Branch & Blade Song',
    artifacts_9_2: 'Woodpecker Electro',
    artifacts_9_3: 'Inferno Metal',
    artifacts_9_app: 1.4,
    artifacts_9_round_sd: 117,
    artifacts_9_round_da: 21635,
    artifacts_10: 'Inferno Metal, Woodpecker Electro',
    artifacts_10_1: 'Inferno Metal',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.75,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 19761
  },
  {
    char: 'pulchra',
    app_rate_sd: 9.18,
    app_rate_sd_e1: 2.0,
    app_rate_sd_s0: 1.77,
    avg_round_sd: 146,
    avg_round_sd_e1: 115,
    avg_round_sd_s0: 167,
    sample_sd: 446,
    sample_size_players_sd: 0,
    app_rate_da: 4.87,
    app_rate_da_e1: 1.0,
    app_rate_da_s0: 1.24,
    avg_round_da: 19948,
    avg_round_da_e1: 28956,
    avg_round_da_s0: 15343,
    app_rate_da_boss_1: 4.63,
    avg_round_da_boss_1: 20597,
    app_rate_da_boss_2: 0.11,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 0.54,
    avg_round_da_boss_3: 14547,
    sample_da: 282,
    sample_size_players_da: 0,
    app_0: 5.38,
    round_0_sd: 182,
    round_0_da: 16449,
    app_1: 8.37,
    round_1_sd: 164,
    round_1_da: 15906,
    app_2: 10.64,
    round_2_sd: 150,
    round_2_da: 17085,
    app_3: 10.98,
    round_3_sd: 153,
    round_3_da: 19187,
    app_4: 8.31,
    round_4_sd: 142,
    round_4_da: 19955,
    app_5: 6.89,
    round_5_sd: 141,
    round_5_da: 20781,
    app_6: 49.43,
    round_6_sd: 135,
    round_6_da: 22206,
    cons_avg: 4.26,
    weapons_1: 'Box Cutter',
    weapons_1_app: 72.7,
    weapons_1_round_sd: 140,
    weapons_1_round_da: 21477,
    weapons_2: 'Steam Oven',
    weapons_2_app: 11.46,
    weapons_2_round_sd: 173,
    weapons_2_round_da: 17389,
    weapons_3: 'Hellfire Gears',
    weapons_3_app: 7.45,
    weapons_3_round_sd: 139,
    weapons_3_round_da: 17680,
    weapons_4: 'Precious Fossilized Core',
    weapons_4_app: 4.56,
    weapons_4_round_sd: 150,
    weapons_4_round_da: 17009,
    weapons_5: 'Demara Battery Mark II',
    weapons_5_app: 1.01,
    weapons_5_round_sd: 166,
    weapons_5_round_da: 20608,
    weapons_6: 'Six Shooter',
    weapons_6_app: 0.96,
    weapons_6_round_sd: 198,
    weapons_6_round_da: 15494,
    weapons_7: 'The Restrained',
    weapons_7_app: 0.81,
    weapons_7_round_sd: 161,
    weapons_7_round_da: 13906,
    weapons_8: 'Blazing Laurel',
    weapons_8_app: 0.27,
    weapons_8_round_sd: 82,
    weapons_8_round_da: 16345,
    weapons_9: '[Vortex] Arrow',
    weapons_9_app: 0.27,
    weapons_9_round_sd: 146,
    weapons_9_round_da: 10537,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Shadow Harmony, Shockstar Disco',
    artifacts_1_1: 'Shadow Harmony',
    artifacts_1_2: 'Shockstar Disco',
    artifacts_1_3: '',
    artifacts_1_app: 35.54,
    artifacts_1_round_sd: 146,
    artifacts_1_round_da: 20234,
    artifacts_2: 'Astral Voice, Shockstar Disco',
    artifacts_2_1: 'Astral Voice',
    artifacts_2_2: 'Shockstar Disco',
    artifacts_2_3: '',
    artifacts_2_app: 26.53,
    artifacts_2_round_sd: 145,
    artifacts_2_round_da: 19665,
    artifacts_3: 'Proto Punk, Shockstar Disco',
    artifacts_3_1: 'Proto Punk',
    artifacts_3_2: 'Shockstar Disco',
    artifacts_3_3: '',
    artifacts_3_app: 10.07,
    artifacts_3_round_sd: 135,
    artifacts_3_round_da: 24421,
    artifacts_4: 'Shadow Harmony, Woodpecker Electro',
    artifacts_4_1: 'Shadow Harmony',
    artifacts_4_2: 'Woodpecker Electro',
    artifacts_4_3: '',
    artifacts_4_app: 6.05,
    artifacts_4_round_sd: 151,
    artifacts_4_round_da: 16210,
    artifacts_5: 'Swing Jazz, Shockstar Disco',
    artifacts_5_1: 'Swing Jazz',
    artifacts_5_2: 'Shockstar Disco',
    artifacts_5_3: '',
    artifacts_5_app: 1.68,
    artifacts_5_round_sd: 132,
    artifacts_5_round_da: 22390,
    artifacts_6: 'Astral Voice, Swing Jazz',
    artifacts_6_1: 'Astral Voice',
    artifacts_6_2: 'Swing Jazz',
    artifacts_6_3: '',
    artifacts_6_app: 1.36,
    artifacts_6_round_sd: 138,
    artifacts_6_round_da: 18688,
    artifacts_7: 'Shockstar Disco, Swing Jazz',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Swing Jazz',
    artifacts_7_3: '',
    artifacts_7_app: 1.26,
    artifacts_7_round_sd: 134,
    artifacts_7_round_da: 22371,
    artifacts_8: 'Astral Voice, Flex',
    artifacts_8_1: 'Astral Voice',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 0.59,
    artifacts_8_round_sd: 215,
    artifacts_8_round_da: 0.0,
    artifacts_9: 'Shadow Harmony, Branch & Blade Song',
    artifacts_9_1: 'Shadow Harmony',
    artifacts_9_2: 'Branch & Blade Song',
    artifacts_9_3: '',
    artifacts_9_app: 0.59,
    artifacts_9_round_sd: 144,
    artifacts_9_round_da: 0.0,
    artifacts_10: 'Astral Voice, Shadow Harmony',
    artifacts_10_1: 'Astral Voice',
    artifacts_10_2: 'Shadow Harmony',
    artifacts_10_3: '',
    artifacts_10_app: 0.59,
    artifacts_10_round_sd: 158,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'anby-demara-soldier-0',
    app_rate_sd: 26.46,
    app_rate_sd_e1: 6.22,
    app_rate_sd_s0: 5.02,
    avg_round_sd: 135,
    avg_round_sd_e1: 104,
    avg_round_sd_s0: 149,
    sample_sd: 1285,
    sample_size_players_sd: 0,
    app_rate_da: 12.69,
    app_rate_da_e1: 2.83,
    app_rate_da_s0: 3.11,
    avg_round_da: 22117,
    avg_round_da_e1: 33110,
    avg_round_da_s0: 18357,
    app_rate_da_boss_1: 13.38,
    avg_round_da_boss_1: 22273,
    app_rate_da_boss_2: 0.17,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 0.19,
    avg_round_da_boss_3: 0,
    sample_da: 735,
    sample_size_players_da: 0,
    app_0: 86.84,
    round_0_sd: 135,
    round_0_da: 22117,
    app_1: 6.13,
    round_1_sd: 114,
    round_1_da: 29066,
    app_2: 4.86,
    round_2_sd: 96,
    round_2_da: 36870,
    app_3: 0.25,
    round_3_sd: 122,
    round_3_da: 23700,
    app_4: 0.2,
    round_4_sd: 100,
    round_4_da: 38859,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.72,
    round_6_sd: 55,
    round_6_da: 47918,
    cons_avg: 0.28,
    weapons_1: 'Severed Innocence',
    weapons_1_app: 68.22,
    weapons_1_round_sd: 127,
    weapons_1_round_da: 24199,
    weapons_2: 'Marcato Desire',
    weapons_2_app: 14.2,
    weapons_2_round_sd: 151,
    weapons_2_round_da: 18487,
    weapons_3: 'Starlight Engine',
    weapons_3_app: 4.81,
    weapons_3_round_sd: 142,
    weapons_3_round_da: 18936,
    weapons_4: 'The Brimstone',
    weapons_4_app: 4.26,
    weapons_4_round_sd: 146,
    weapons_4_round_da: 23265,
    weapons_5: 'Cannon Rotor',
    weapons_5_app: 1.6,
    weapons_5_round_sd: 159,
    weapons_5_round_da: 13965,
    weapons_6: 'Zanshin Herb Case',
    weapons_6_app: 1.49,
    weapons_6_round_sd: 147,
    weapons_6_round_da: 17465,
    weapons_7: 'Heartstring Nocturne',
    weapons_7_app: 1.31,
    weapons_7_round_sd: 139,
    weapons_7_round_da: 20422,
    weapons_8: 'Gilded Blossom',
    weapons_8_app: 1.08,
    weapons_8_round_sd: 171,
    weapons_8_round_da: 14813,
    weapons_9: 'Steel Cushion',
    weapons_9_app: 0.88,
    weapons_9_round_sd: 128,
    weapons_9_round_da: 21563,
    weapons_10: 'Riot Suppressor Mark VI',
    weapons_10_app: 0.42,
    weapons_10_round_sd: 156,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Shadow Harmony, Woodpecker Electro',
    artifacts_1_1: 'Shadow Harmony',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 50.32,
    artifacts_1_round_sd: 133,
    artifacts_1_round_da: 22273,
    artifacts_2: 'Shadow Harmony, Branch & Blade Song',
    artifacts_2_1: 'Shadow Harmony',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 36.89,
    artifacts_2_round_sd: 138,
    artifacts_2_round_da: 21320,
    artifacts_3: 'Shadow Harmony, Thunder Metal',
    artifacts_3_1: 'Shadow Harmony',
    artifacts_3_2: 'Thunder Metal',
    artifacts_3_3: '',
    artifacts_3_app: 3.49,
    artifacts_3_round_sd: 141,
    artifacts_3_round_da: 23891,
    artifacts_4: 'Shadow Harmony, Astral Voice',
    artifacts_4_1: 'Shadow Harmony',
    artifacts_4_2: 'Astral Voice',
    artifacts_4_3: '',
    artifacts_4_app: 1.87,
    artifacts_4_round_sd: 126,
    artifacts_4_round_da: 25234,
    artifacts_5: 'Shadow Harmony, Puffer Electro',
    artifacts_5_1: 'Shadow Harmony',
    artifacts_5_2: 'Puffer Electro',
    artifacts_5_3: '',
    artifacts_5_app: 1.19,
    artifacts_5_round_sd: 123,
    artifacts_5_round_da: 30007,
    artifacts_6: 'Shadow Harmony, Flex',
    artifacts_6_1: 'Shadow Harmony',
    artifacts_6_2: 'Flex',
    artifacts_6_3: '',
    artifacts_6_app: 0.97,
    artifacts_6_round_sd: 137,
    artifacts_6_round_da: 20608,
    artifacts_7: 'Thunder Metal, Woodpecker Electro',
    artifacts_7_1: 'Thunder Metal',
    artifacts_7_2: 'Woodpecker Electro',
    artifacts_7_3: '',
    artifacts_7_app: 0.69,
    artifacts_7_round_sd: 146,
    artifacts_7_round_da: 19216,
    artifacts_8: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_8_1: 'Woodpecker Electro',
    artifacts_8_2: 'Branch & Blade Song',
    artifacts_8_3: '',
    artifacts_8_app: 0.6,
    artifacts_8_round_sd: 143,
    artifacts_8_round_da: 38056,
    artifacts_9: 'Shadow Harmony, Hormone Punk',
    artifacts_9_1: 'Shadow Harmony',
    artifacts_9_2: 'Hormone Punk',
    artifacts_9_3: '',
    artifacts_9_app: 0.37,
    artifacts_9_round_sd: 114,
    artifacts_9_round_da: 0.0,
    artifacts_10: 'Woodpecker Electro, Thunder Metal',
    artifacts_10_1: 'Woodpecker Electro',
    artifacts_10_2: 'Thunder Metal',
    artifacts_10_3: '',
    artifacts_10_app: 0.26,
    artifacts_10_round_sd: 145,
    artifacts_10_round_da: 0.0
  }
];
