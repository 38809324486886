/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ZZZCharacter } from '../components/zzz-character';

interface IProps {
  name: string;
  character_element?: string;
}

export const ZZZProfileSynergies: React.FC<IProps> = ({ name }) => {
  const infoSupports = (
    <>
      <li>
        <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
        <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover />{' '}
        <ZZZCharacter mode="inline" slug="lucy" enablePopover /> - These 3
        generalist units are capable of acting as Support for any team in the
        game. Astra Yao provides Quick Assists, extra Chain Attacks, healing,
        super low field time, ATK, DMG and CRIT DMG buffs which makes her the
        overall best choice in almost all cases. Nicole provides the
        sought-after DEF Shred and CRIT Rate at M6 which makes her competitive,
        and Lucy has long-lasting ATK buffs which makes her a very practical
        choice, especially in Fire teams where she can help with building
        Anomaly and trigger Additional Abilities. All in all, these three can
        work with anyone.
      </li>
    </>
  );
  return (
    <>
      {name === 'anby-demara' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="harumasa" enablePopover /> -
            Harumasa's current best F2P Stun partner is Anby, replacing Qingyi
            if you don't have her. She's desirable for a few reasons, being her
            quick Daze application which fills in for Harumasa's downtime due to
            her field time requirements, her ability to inflict Shock anomaly,
            and her acceptable personal damage. All in all the two form a great
            pair if you don't have other Agents.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="zhu-yuan" enablePopover /> - Anby
            makes for a great F2P stunner for Zhu Yuan due to her quick Daze
            application that fills in for Zhu Yuan's downtime. She acts as a
            solid replacement to Qingyi, so it's easy to see why the two work
            well together as their kits have similarities. Much like the synergy
            with Harumasa, Anby is an option to consider if you lack stronger
            Stun Agents.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover /> -
            The Demara sisters are able to activate each other's Additional
            Abilities, which is great for enhancing Anby's Energy Regen while
            Nicole gets to provide an extra Ether DMG Boost to the whole party.
            Beyond that, Anby is able to quickly create Stun windows for
            Nicole's DEF shred to gain extra effectiveness, greatly enhancing
            the performance of the DPS character you pair them with, making the
            two a great duo to increase your DPS character's damage.
          </li>
        </ul>
      )}
      {name === 'anby-demara-soldier-0' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="pulchra" enablePopover /> - These
            two are the best Stun options for SAnby to shine, Trigger being a
            level above Pulchra. Both can provide buffs (Pulchra via M6
            especially) and both can inflict Daze and Aftershock off-field,
            however Trigger provides much more significant buffs, more damage,
            and more Daze off-field, that she activates quicker. However,
            Pulchra and Trigger both make for an amazing Stun pairing for SAnby
            due to their ability to leave the window open for SAnby to take
            field time and make full use of her kit mechanics. They make for one
            of the top teams in the game.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="caesar" enablePopover /> - Caesar
            has a special synergy with SAnby, because she takes little field
            time and provides additional Daze, buffs and a DMG% mark in that
            short amount of time. Since SAnby wants to spend a lot of time on
            the field, having a character to inflict extra Daze while providing
            damage boosts is a great deal of help to SAnby, which makes the
            pairing especially remarkable.
          </li>
          {infoSupports}
        </ul>
      )}
      {name === 'anton' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="qingyi" enablePopover /> - These
            two Stun powerhouses are able to activate Anton's Additional Ability
            and allows him to capitalize on Shock damage while creating lots of
            Stun windows for him to deal a lot of damage. They both provide Stun
            DMG Multiplier debuffs but Qingyi deals more Daze at the cost of
            more field time while Trigger is almost always off-field, so both
            have their pros and cons, but both appear as the best options for
            Anton if you want to run a Stun unit with him.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="grace-howard" enablePopover /> -
            One of Anton’s kit-defining features is his Additional Ability that
            allows him to partially trigger any Shock on the target he is
            hitting every 4th Critical Hit he deals. Grace allows him to exploit
            this as she activates this Additional Ability by herself inflicts
            good Shock damage that Anton can freely exploit during his damage
            windows.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover /> -
            These 2 generalist Support units are capable of servicing as the
            Support to any team in the game. Astra Yao provides Quick Assists,
            extra Chain Attacks, healing, super low field time, ATK, DMG and
            CRIT DMG buffs which makes her the overall best choice in almost all
            cases. Nicole provides the sought-after DEF Shred and CRIT Rate at
            M6 which makes her competitive. They can work with any team in the
            game to great effect, whether that's by themselves or together.
            Astra Yao is especially desirable on Anton by allowing him to
            exploit his extremely high Quick Assist multiplier, and if you want
            to make the effort on capitalizing this effect, Astra Yao's Special
            is perfect for the job.
          </li>
        </ul>
      )}
      {name === 'astra-yao' && (
        <ul className="bigger-margin">
          <li>
            To be completely clear: Note that we only highlight the main
            synergies Astra Yao has here, and that you can run her with pretty
            much absolutely anyone and she'll be the best Support option or very
            close to it at the strict minimum. We'll only highlight where she's
            particularly good below but feel free to run her with anyone you
            want:
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="evelyn" enablePopover /> - Astra
            Yao has a particular synergy with Evelyn because she's able to
            provide all her typical buffs to her, but most importantly her
            Ultimate allows your next 2 Quick Assists to become Chain Attacks —
            Chains being most relevant for Evelyn to exploit, the synergy
            between the two is particularly noteworthy and she provides a
            drastic boost to Evelyn's damage as a result (even though the same
            could be said for a lot of characters, it's especially remarkable in
            this example).
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="miyabi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="jane-doe" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="burnice" enablePopover /> - Astra
            Yao takes pretty much no field time — you only swap her in at the
            start to activate her buffs via her Special, you use her to perform
            Quick Assists, and you use her Ultimate typically during Stun
            windows. That's it. Therefore, any DPS character that benefits from
            taking a lot of field time especially benefits from having Astra Yao
            on the team, and this includes the faces above.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="trigger" enablePopover /> - Astra
            Yao enables a particular team archetype to function especially well,
            being Double Support. On certain DPS characters, it's straight-up
            their best team by far, and on most units it's at least slightly
            better than other choices or close to them. Nicole and Trigger are
            the best partners for Astra to pair with for Double Support teams
            because they take extremely little field time as well, allowing your
            DPS character to take the spotlight for almost the whole gameplay —
            Nicole provides more potent buffs and debuffs, Trigger provides more
            Daze and damage. Depending on the DPS used, the two will alternate
            in coordination with Astra Yao to make for one of the best team
            archetypes in the game.
          </li>
        </ul>
      )}
      {name === 'ben' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="koleda" enablePopover /> - Ben and
            Koleda have synergy in both lore and kit as Ben empowers two of
            Koleda's best abilities, her Ultimate and Enhanced Basic Attacks.
            Koleda’s empowered Ultimate has a larger AoE, is easier to aim and
            also deals more damage and Daze whereas the empowered Basic Attacks
            gain a good chunk more Daze and damage. Overall, Ben makes Koleda
            stronger but there is a catch to combining them. Ben doesn't provide
            enough buffs, extra damage, or extra Daze. Koleda and the DPS she
            pairs with much rather having a proper Support such as Lucy to
            empower their damage further, Koleda has enough Daze on her own.
            With that being said, the pair is still viable to use and clear
            content with as per the rest of the character cast, so feel free to
            use them both if you want to!
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="evelyn" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="soldier-11" enablePopover /> -
            Evelyn and Soldier 11 share one trait in common: they're Fire Main
            DPS units who benefit from Stun — Ben provides 16% CRIT Rate via his
            Shield to Fire units and he also provides Daze for them to exploit.
            This makes pairing Ben with these two choices viable (and while a
            proper Support like Lucy tends to outclass Ben, he can act as an
            acceptable replacement).
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="lucy" enablePopover /> - When
            paired with Ben and certain Fire team options, Lucy can act as a DPS
            when paired with Ben — it's not exactly strong compared to other
            team choices, as per the rest of Ben's synergies, but Ben provides a
            Shield that grants an extra 16% CRIT Rate to Fire and Belobog
            characters, which Lucy can exploit to make use of her somewhat
            noteworthy damage potential. It's definitely an unconventional team
            option, but one that works nonetheless.
          </li>
        </ul>
      )}
      {name === 'billy-kid' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="caesar" enablePopover /> - Caesar
            can serve as another Support that activates Billy's Additional
            Ability since they share the Physical element. She's particularly
            good with Billy for this reason, but she's also able to provide a
            lot of extra Daze Billy can't supply on his own with minimal field
            time while providing ATK buffs and DMG% debuffs, greatly enhancing
            his damage in 3 ways.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover /> -
            Nicole is particularly useful with Billy because as members of the
            Cunning Hares, they activate each others' Additional Abilities which
            makes for a great synergy. Billy capitalizes off of Nicole's ability
            to shred the DEF of enemies via her big attacks and is able to make
            great use of the debuffs during stun windows by unleashing a lot of
            burst DPS, making for a good pair.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="trigger" enablePopover /> - These
            two function as universal Support Agents (and Stun in Trigger's
            case) that can provide lots of buffs, debuffs and allow Billy to
            capitalize on his damage windows by providing some of the biggest
            potential boosts to your damage. However don't forget to pair them
            with Nicole (with Astra/Trigger) or Caesar (with Trigger) to
            activate everyone's Additional Abilities!
          </li>
        </ul>
      )}
      {name === 'burnice' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="miyabi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="jane-doe" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="piper" enablePopover /> - Anomaly
            and Disorder DPS characters are Burnice's best option to pair with
            in general, as they activate her Additional Ability and take her
            off-field Afterburn DMG and on-field Burn application to new heights
            by allowing them to activate Anomaly-related buffs and damage bursts
            extremely quickly. With a character like Miyabi, Burnice becomes a
            destructive force to be reckoned with, and Piper works amazingly as
            possibly the best F2P DPS in the game along with her. All in all,
            these synergies make for some of the best teams in the game.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lucy" enablePopover /> - These 2
            generalist Support units are capable of servicing as the Support to
            any team in the game. Astra Yao provides Quick Assists, extra Chain
            Attacks, healing, super low field time, ATK, DMG and CRIT DMG buffs
            which makes her the overall best choice in almost all cases. Lucy
            has long-lasting ATK buffs which makes her a very practical choice,
            especially in Fire teams where she can help with building Anomaly
            and trigger Additional Abilities, which particularly helps Burnice.
          </li>
        </ul>
      )}
      {name === 'caesar' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter
              mode="inline"
              slug="anby-demara-soldier-0"
              enablePopover
            />{' '}
            - Caesar has a special synergy with SAnby, because she takes little
            field time and provides additional Daze, buffs and a DMG% mark in
            that short amount of time. Since SAnby wants to spend a lot of time
            on the field, having a character to inflict extra Daze while
            providing damage boosts is a great deal of help to SAnby, which
            makes the pairing especially remarkable.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="corin" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="billy-kid" enablePopover /> - Both
            Corin and Billy have particular synergies with Caesar because not
            only do they greatly benefit from the Daze she applies on top of her
            buffs and debuffs, she also allows for their Additional Abilities to
            be activated when in some cases it can be complicated to do so. The
            additional survivability Caesar provides is also not to be ignored
            as the extra Interruption Resistance and ability to fend off damage
            is appreciated in all cases. All in all, these pairings make for
            underrated F2P options that won't disappoint given the right amount
            of love.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lucy" enablePopover /> - These 4
            generalist units are capable of servicing as the Support and/or Stun
            to just about any team in the game. Astra Yao provides Quick
            Assists, extra Chain Attacks, healing, super low field time, ATK,
            DMG and CRIT DMG buffs which makes her the overall best Support
            choice in almost all cases. Nicole provides the sought-after DEF
            Shred and CRIT Rate at M6 which makes her competitive, and Lucy has
            long-lasting ATK buffs which makes her a very practical choice,
            especially in Fire teams where she can help with building Anomaly
            and trigger Additional Abilities. Last but not least, Trigger
            provides lots of off-field Daze, Stun DMG Multiplier debuffs and can
            perform Defensive Assists for Caesar to activate her Additional
            Ability. All in all, these characters can work with just about
            anyone.
          </li>
        </ul>
      )}
      {name === 'corin' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="caesar" enablePopover /> - Corin
            has a particular synergy with Caesar because not only does she
            greatly benefit from the Daze Caesar applies on top of her buffs and
            debuffs, she also allows for her Additional Ability to be activated
            when it can be complicated to do so. The additional survivability
            Caesar provides is also not to be ignored as the extra Interruption
            Resistance and ability to fend off damage is appreciated in many
            situations. All in all, this pairing makes for an underrated F2P
            option that won't disappoint given the right amount of love.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="lycaon" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="pulchra" enablePopover /> - Lycaon
            and Pulchra both serve as stunners able to provide for Corin's
            Additional Ability: Lycaon does it by sharing the same faction,
            Pulchra does it by sharing the same Physical attribute. Both provide
            buffs to Corin's damage, Pulchra via her M6 and Lycaon via his Stun
            DMG Multiplier debuff. These two traits are all Corin needs to
            appreciate them as F2P Stun options in her teams.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="trigger" enablePopover /> - These
            3 generalist units are capable of servicing as the Support and/or
            Stun to just about any team in the game. Astra Yao provides Quick
            Assists, extra Chain Attacks, healing, super low field time, ATK,
            DMG and CRIT DMG buffs which makes her the overall best choice in
            almost all cases. Nicole provides the sought-after DEF Shred and
            CRIT Rate at M6 which makes her competitive. Trigger can also
            provide Daze and damage while off-field and has a universal Stun DMG
            Multiplier debuff that Corin can very much enjoy. Astra Yao is
            especially desirable on Corin by allowing her to exploit her high
            Quick Assist multiplier, and if you want to make the effort on
            capitalizing this effect, Astra Yao's Special is perfect for the
            job.
          </li>
        </ul>
      )}
      {name === 'ellen' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="lighter" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lycaon" enablePopover /> - These 3
            Stun options each have their pros and cons, typically with Lighter
            coming out on top as the best choice for Ellen. This is because he
            sports massive boosts to Ice DMG, Ice RES shred and has amazing Daze
            infliction which allows Ellen's damage to reach its true potential.
            However, Lycaon also provides Ice RES shred while inflicting slower
            Daze but he allows Ellen to activate her Additional Ability as a
            trade-off. Lastly, Trigger, much like Lycaon, can increase the Stun
            DMG multiplier of enemies, all that while dealing damage and Daze
            off-field, and Ellen can make good use of the extra field time,
            making these 3 options very competitive, typically with Lighter
            coming out on top by a small margin.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover /> -
            These 2 generalist Support units are capable of servicing as the
            Support to any team in the game. Astra Yao provides Quick Assists,
            extra Chain Attacks, healing, super low field time, ATK, DMG and
            CRIT DMG buffs which makes her the overall best choice in almost all
            cases. Nicole provides the sought-after DEF Shred and CRIT Rate at
            M6 which makes her competitive. They can work with any team in the
            game to great effect, whether that's by themselves or together.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="soukaku" enablePopover /> -
            Soukaku has a particular synergy with Ellen since both are of the
            Ice attribute. This makes both able to activate each other's
            Additional Abilities, and Soukaku can enjoy reducing Ice RES,
            increasing Ice DMG and increasing the ATK of Ellen all at once to
            greatly improve her damage potential. She isn't stronger than
            premium options like Astra Yao but remains a very strong Support
            choice, particularly for F2P players.
          </li>
        </ul>
      )}
      {name === 'evelyn' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="lighter" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="koleda" enablePopover /> - All 3
            of these Stun agents have synergies with Evelyn for different
            reasons, and all perform great. Lighter is the best option since he
            provides quick Daze application all the while providing massive
            buffs to Fire DMG, greatly boosting Evelyn's potential. Trigger has
            the advantage of mostly remaining off-field, which allows Evelyn to
            capitalize on the field time, without forfeiting damage boosts, and
            Koleda has very quick Daze application and allows for each other's
            Additional Abilities to be activated, which makes Koleda provide a
            buff to Evelyn's Chain Attacks, making it a great F2P choice.
          </li>
          {infoSupports}
        </ul>
      )}
      {name === 'grace-howard' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="miyabi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover /> - Miyabi
            and Yanagi both benefit from having a character to apply Anomaly
            with since that's where a lot of their damage lies — whether its
            Shocks or Disorders, Grace has fast Shock application to help with
            their kits. However, make sure to run a Support unit with Miyabi to
            activate her Additional Ability!
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="anton" enablePopover /> - One of
            Anton’s kit-defining features is his Additional Ability that allows
            him to partially trigger any Shock on the target he is hitting every
            4th Critical Hit he deals. Grace allows him to exploit this as she
            activates this Additional Ability by herself inflicts good Shock
            damage that Anton can freely exploit during his damage windows.
          </li>
          {infoSupports}
        </ul>
      )}
      {name === 'harumasa' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="qingyi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="anby-demara" enablePopover /> -
            These 3 Electric Stun Agents work with Harumasa by virtue of
            providing debuffs and/or filling in for field time when Harumasa's
            damage window is on cooldown. Qingyi is the best of all of these
            choices since she fills both niches, however Trigger is able to
            provide good Daze while providing a Stun DMG Multiplier debuff which
            greatly helps Harumasa's damage, and Anby fills in for field time
            while stunning quickly but she provides no buffs, so she ends up as
            a F2P option only if you don't have the other two.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover /> -
            These 2 generalist Support units are capable of servicing as the
            Support to any team in the game. Astra Yao provides Quick Assists,
            extra Chain Attacks, healing, super low field time, ATK, DMG and
            CRIT DMG buffs which makes her the overall best choice in almost all
            cases. Nicole provides the sought-after DEF Shred and CRIT Rate at
            M6 which makes her competitive. They can work with any team in the
            game to great effect, Nicole being particularly appreciated in
            Harumasa teams as she fills up a bit of the time where Harumasa is
            on cooldown and awaiting his next damage window.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="rina" enablePopover /> - Rina is
            particularly good with Harumasa because while the PEN Ratio buffs
            she provides are appreciated, she also increases Shock duration and
            Electric DMG which makes her especially stand out with Electric
            Agents. While some other Support options are still stronger choices,
            if you wish to use Rina somewhere Harumasa's a very good choice.
          </li>
        </ul>
      )}
      {name === 'jane-doe' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="burnice" enablePopover /> - Jane
            is a great Anomaly/Disorder DPS partner for Burnice. She utilizes
            all available field time Burnice grants the team through her
            incredibly powerful Basic Attack combos, triggering constant
            Assaults which synergize excellently with Burnice’s frequent Burns
            to trigger extremely powerful Disorders. Both characters also
            trigger each other's Additional Ability which allows for many
            different team options.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="seth" enablePopover /> - Seth
            helps Jane and other Anomaly characters by specializing in Anomaly
            synergy and doesn't require to be played on the field for a long
            time. He applies a generic 20% Anomaly RES shred to enemies - which
            amplifies all Anomaly build-up against them. This effect greatly
            enhances Jane's Anomaly DMG potential. On top of that, Seth grants a
            chosen squad member (based on team positioning) up to 100 additional
            Anomaly Proficiency. This boost not only enhances Jane’s damage
            output but also assists her in capping out her Core Passive bonus,
            making for a very potent F2P synergy.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lucy" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover /> -
            These 4 generalist units are capable of servicing as the Support
            and/or Stun to just about any team in the game. Astra Yao provides
            Quick Assists, extra Chain Attacks, healing, super low field time,
            ATK, DMG and CRIT DMG buffs which makes her the overall best Support
            choice in almost all cases. Nicole provides the sought-after DEF
            Shred and CRIT Rate at M6 which makes her competitive, and Lucy has
            long-lasting ATK buffs which makes her a very practical choice,
            especially in Fire teams where she can help with building Anomaly
            and trigger Additional Abilities. Last but not least, Trigger
            provides lots of off-field Daze and Stun DMG Multiplier debuffs
            which enables Jane to use more field time that she greatly
            appreciates as well as make use of more Stun windows, however using
            Seth is necessary to activate Trigger's Additional Ability!
          </li>
        </ul>
      )}
      {name === 'koleda' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="evelyn" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="soldier-11" enablePopover /> -
            Koleda does a few things for both Soldier 11 and Evelyn. All
            characters activate each others' Additional Abilities which allows
            Koleda to boost Chain Attack DMG (especially relevant on Evelyn, but
            also exploitable on Soldier 11), and allows both units to deal extra
            damage. In addition, Koleda applies a lot of Daze very quickly,
            allowing for many Stun windows for the DPS units to exploit
            throughout battle. This makes Koleda a strong F2P choice for both
            Agents.
          </li>
          {infoSupports}
        </ul>
      )}
      {name === 'lighter' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="evelyn" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="miyabi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="ellen" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="soldier-11" enablePopover /> -
            Lighter pairs especially well with Fire or Ice DPS characters who
            benefit from Stun windows — so typically CRIT-based Agents. 4
            characters fall within that archetype, being Evelyn, Ellen, Soldier
            11 and Miyabi (who does Anomaly DMG but wants to build CRIT
            regardless). He's able to provide huge Ice and Fire RES Shred and
            DMG boosts as well as massive amounts of Daze for them, greatly
            enhancing their damage.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lucy" enablePopover /> - These 2
            generalist Support units are capable of servicing as the Support to
            any team in the game. Astra Yao provides Quick Assists, extra Chain
            Attacks, healing, super low field time, ATK, DMG and CRIT DMG buffs
            which makes her the overall best choice in almost all cases. On the
            other hand, Lucy has long-lasting ATK buffs which makes her a very
            practical choice, especially in Fire teams where she can help with
            building Anomaly and trigger Additional Abilities (the case for
            Lighter). All in all, these two can work with any team in the game
            to great effect.
          </li>
        </ul>
      )}
      {name === 'lucy' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="evelyn" enablePopover /> - Evelyn
            particularly benefits from a few things Lucy provides. The first of
            which is her big ATK buff which is a stat Evelyn doesn't give
            herself in her own kit as much as other buffs so she experiences
            fewer deminishing returns. The second is her high uptime on this
            buff which allows for Lucy to take a minimal amount of field time
            and allow Evelyn to take the spotlight, the third is Lucy's CRIT DMG
            buff and the fourth is her help towards Fire Anomaly buildup. All in
            all they make for a great pair
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="lighter" enablePopover /> -
            Lighter is the Stun Agent who's most often paired with Lucy because
            they're both in the same Faction so they automatically activate each
            other's Additional Abilities. To top it off, Lucy gives ATK% while
            Lighter gives DMG% so the two stats contribute greatly to any Fire
            or Ice DPS. Finally, both contribute to Fire Anomaly buildup when
            it's relevant, making the two an amazing choice.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="piper" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="burnice" enablePopover /> - The
            two Anomaly DPS Agents from the Sons of Calydon both form great
            pairs with Lucy. Sharing a faction means they contribute to each
            other's Additional Abilities, the lengthy ATK buff pairs well with
            their kits and Lucy's minimal field time requirement allows Piper
            especially to shine, while Burnice enjoys the extra Fire Anomaly
            help, making for amazing team options.
          </li>
        </ul>
      )}
      {name === 'lycaon' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="miyabi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="ellen" enablePopover /> - Lycaon
            provides a big shred to Ice RES and increases Stun DMG multipliers
            of enemies — this obviously means he pairs well with Ice Agents as
            he's greatly able to buff their damage and provide a lot of Daze for
            them. He stands out as a really potent F2P Stun Agent for both Ice
            damage dealers we have in the game right now as a result: Miyabi and
            Ellen. Easily able to activate Lycaon's Additional Ability, the
            pairings are natural and effective.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="corin" enablePopover /> - Corin
            might seem like a surprising synergy with Lycaon at first — and
            indeed Corin doesn't deal any Ice DMG. However, Corin's Additional
            Ability requires an extra Physical or Victoria Housekeeping
            character be present on the team, and that condition can be
            difficult to fill. That's where Lycaon comes in: he's able to
            provide Daze, a Stun DMG multiplier debuff, and Corin's Additional
            Ability all at once for her to benefit from, making for a solid F2P
            team.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover /> -
            Astra Yao and Nicole are 2 generalist Support units are capable of
            servicing as the Support to any team in the game. Astra Yao provides
            Quick Assists, extra Chain Attacks, healing, super low field time,
            ATK, DMG and CRIT DMG buffs which makes her the overall best choice
            in almost all cases. Nicole provides the sought-after DEF Shred and
            CRIT Rate at M6 which makes her competitive. They can work with any
            team in the game to great effect. However, more interesting to
            mention here is Soukaku: she stands out as an amazing F2P option for
            Ice teams with Lycaon, where she's able to provide him with his
            Additional Ability, and she's able to buff the Ice DMG of allies and
            reduce the Ice RES of enemies even further with Lycaon, all the
            while providing a big ATK buff. This makes the pairing viable,
            F2P-friendly and shockingly effective.
          </li>
        </ul>
      )}
      {name === 'miyabi' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="burnice" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="piper" enablePopover /> - Miyabi
            pairs well with just about all types of characters, but most
            noteworthy are Anomaly DPS Agents that can activate Disorders for
            Miyabi to exploit and regenerate Fallen Frost with to deal even more
            damage faster. The most obvious example of this is Yanagi, sharing a
            faction with her and effortlessly activating each other's Additional
            Abilities, but that's not all: she's able to activate massive
            Disorder DMG and exploit Miyabi's swap window during her Shimotsuki
            Charged Attack effectively for one of the best team compositions in
            the game, if not arguably the single best. Burnice also stands out
            by simple but extremely effective virtue of having one of the most
            effective Anomaly applications that works even while off-field which
            creates massive Disorders super frequently, and finally Piper
            appears as a surprising but viable F2P-friendly choice for Miyabi to
            exploit if you don't own anyone else — no matter what Miyabi's
            Disorder teams are not only viable, they're ridiculously high in
            damage and make for some of the best teams in the entire game.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lighter" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lycaon" enablePopover /> - Miyabi
            isn't just locked to leaning into Anomaly: she can exploit Stun
            characters and capitalize on Stun windows via the massive Daze
            infliction and buffs from Lighter and Lycaon who both decrease Ice
            RES, Lighter who also increases Ice DMG, and Trigger who debuffs
            targets with an increased Stun DMG multiplier along with Lycaon, but
            in Trigger's case she does so with off-field Aftershocks and almost
            no field time. In any case, they allow Miyabi to capitalize on her
            huge bursts of damage during Stun windows, which makes for a more
            special, but extremely effective playstyle.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lucy" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="soukaku" enablePopover /> - Astra
            Yao, Nicole, Lucy and Soukaku are four generalist Support units are
            capable of servicing as the Support to any team in the game. Astra
            Yao provides Quick Assists, extra Chain Attacks, healing, super low
            field time, ATK, DMG and CRIT DMG buffs which makes her the overall
            best choice in almost all cases. Nicole provides the sought-after
            DEF Shred and CRIT Rate at M6 which makes her competitive, and Lucy
            has long-lasting ATK buffs which makes her a very practical choice,
            especially in Fire teams where she can help with building Anomaly
            and trigger Additional Abilities. All in all, these three can work
            with any team in the game to great effect, whether that's together
            or by themselves. However, more interesting to mention here is
            Soukaku: she stands out as an amazing F2P option for Miyabi as she's
            able to buff the Ice DMG of allies, she shares a Faction with her so
            they're both able to activate their Additional Abilities, she
            reduces the Ice RES of enemies, all the while providing a big ATK
            buff. This makes the pairing viable, F2P-friendly and shockingly
            effective — Soukaku is not to be underestimated with Miyabi.
          </li>
        </ul>
      )}
      {name === 'nekomata' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="pulchra" enablePopover /> -
            Trigger and Pulchra both fill very similar niches: they provide
            off-field Daze via Aftershocks and have buffs and debuffs to their
            name - Pulchra debuffs with a DMG% mark at M6, Trigger debuffs
            targets with an increased Stun DMG multiplier. No matter what, they
            allow Nekomata to shine by allowing her to take a lot of the field
            time which she particularly appreciates — just make sure to pair
            Trigger with Nicole so you activate Nekomata's Additional Ability.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover /> -
            These 2 generalist Support units are capable of servicing as the
            Support to any team in the game. Astra Yao provides Quick Assists,
            extra Chain Attacks, healing, super low field time, ATK, DMG and
            CRIT DMG buffs which makes her the overall best choice in almost all
            cases. Nicole provides the sought-after DEF Shred and CRIT Rate at
            M6 which makes her competitive. They can work with any team in the
            game to great effect, Nicole being particularly appreciated as she
            shares a faction with Nekomata, meaning they activate each other's
            Additional Abilities.
          </li>
        </ul>
      )}
      {name === 'nicole-demara' && (
        <ul className="bigger-margin">
          <li>
            To be completely clear: Note that we only highlight the main
            synergies Nicole has here, and that you can run her with pretty much
            absolutely anyone and she'll be the second best Support option or
            very close to it at the strict minimum. We'll only highlight where
            she's particularly good below but feel free to run her with anyone
            you want:
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="zhu-yuan" enablePopover /> -
            Nicole is particularly outstanding with Zhu Yuan, because not only
            does she provide the usual DEF Shred debuffs and CRIT Rate buffs for
            Zhu Yuan to exploit, but they also activate each other's Additional
            Abilities which boosts Zhu Yuan's CRIT Rate even further and
            provides an Ether DMG boost to the whole party! This makes the
            pairing particularly outstanding, not to mention Nicole oftens fills
            in for Zhu Yuan's downtimes nicely by taking a short bit of field
            time compared to someone like Astra Yao.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="harumasa" enablePopover /> - Very
            similarly to Zhu Yuan, Nicole and Harumasa make for an especially
            good Support-DPS pairing because Nicole fills in very slightly for
            the downtimes during which Harumasa has trouble making any use of
            his damage potential. His damage potential comes in windows and
            downtime needs to be filled with productive things: Nicole helps for
            that slightly, at least more so than Astra Yao, and provides roughly
            similar damage boosts when optimized, which makes the pairing
            particularly synergistic.
          </li>
        </ul>
      )}
      {name === 'piper' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="burnice" enablePopover /> - Piper
            stands out as a fairly obvious choice for Burnice for several
            reasons. The first of which is they're part of the same faction and
            class, so they're able to trigger each other's Additional Abilities
            effortlessly. Second, they both rely on Anomaly DMG to deal massive
            amounts of damage: one on-field (Piper), the other mostly off-field
            (Burnice). This makes the pairing extremely natural, rewarding, and
            very high damage. While better options do exist for Burnice, they
            usually aren't better than Piper by that much, which is impressive
            for an A-rank Agent.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="miyabi" enablePopover /> - Miyabi
            looks like a very odd pairing for Piper at first; but once you take
            a good look you understand Miyabi wants Disorders and lots of them,
            which Piper helps a great deal with. She does good Anomaly damage
            and Piper has some short Quickswap windows on occasion (notably on
            her Special and EX Special's finishing move). All in all, the
            pairing is only to be considered for F2P Miyabi teams but will prove
            surprisingly potent if you lack Lycaon and premium options for her.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lucy" enablePopover /> - These 2
            generalist Support units are capable of servicing as the Support to
            any team in the game. Astra Yao provides Quick Assists, extra Chain
            Attacks, healing, super low field time, ATK, DMG and CRIT DMG buffs
            which makes her the overall best choice in almost all cases. On the
            other hand, Lucy has long-lasting ATK buffs which makes her a very
            practical choice, especially in Fire teams where she can help with
            building Anomaly and trigger Additional Abilities (the case for
            Piper). All in all, these two can work with any team in the game to
            great effect.
          </li>
        </ul>
      )}
      {name === 'pulchra' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter
              mode="inline"
              slug="anby-demara-soldier-0"
              enablePopover
            />{' '}
            - Pulchra has a massively valuable synergy in the form of SAnby —
            this is for many reasons. First, they activate each other's
            Additional Abilities. This has the effect to boost the Aftershock
            DMG of both by a great amount, but it also applies to all types of
            DMG if Pulchra is at M6. However, most importantly, SAnby benefits a
            huge amount from having someone handle the stunning while off-field,
            and very similarly to Trigger, Pulchra does exactly that. She
            creates opportunities in the form of Stun windows for SAnby without
            eating up any of the field time that SAnby very much wants, making
            for a very capable duo that ranks high up in the meta.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="nekomata" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="soldier-11" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="corin" enablePopover /> - Pulchra
            is also great with other characters that want a lot of field time
            and Physical Attack Agents especially as she's able to activate
            their Additional Abilities effortlessly. This is the case with both
            Nekomata and Corin (the latter of which usually struggles with
            this), so there's no conflicts there. Besides, Pulchra takes up
            minimal amounts of field time as she mostly deals Daze while
            off-field, much to the benefit of the 3 above characters who really
            enjoy the extra time.
          </li>
          {infoSupports}
        </ul>
      )}
      {name === 'qingyi' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="zhu-yuan" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="harumasa" enablePopover /> -
            Qingyi excels at building up a lot of Daze and Stun DMG multiplier
            debuffs and doing it fast; the issue is that that comes at some
            noteworthy amount of field time. This means she pairs best with
            characters who have burst-focused damage windows with fairly long
            downtimes: Zhu Yuan and Harumasa immediately strike as the perfect
            fit for this, and indeed Qingyi is their best Stun agent for those
            reasons. The pairing is smooth, natural, and extremely effective.
          </li>
          <li>
            <ZZZCharacter
              mode="inline"
              slug="anby-demara-soldier-0"
              enablePopover
            />{' '}
            - Qingyi seems like a much less obvious choice for SAnby since she
            takes up field time that SAnby would rather have. And while this is
            true, the pros outweigh the cons as Qingyi works effectively with a
            variety of SAnby Support choices, often outperforming M6 Pulchra
            unless Astra Yao is involved.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover /> -
            These 2 generalist Support units are capable of servicing as the
            Support to any team in the game. Astra Yao provides Quick Assists,
            extra Chain Attacks, healing, super low field time, ATK, DMG and
            CRIT DMG buffs which makes her the overall best choice in almost all
            cases. Nicole provides the sought-after DEF Shred and CRIT Rate at
            M6 which makes her competitive. They can work with any team in the
            game to great effect, whether that's by themselves or together.
          </li>
        </ul>
      )}
      {name === 'rina' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="jane-doe" enablePopover /> - Rina
            pairs especially well with Anomaly characters as she herself
            contributes a good amount to Anomaly Buildup, especially when it
            comes to Shock characters like Yanagi. While the pairing is less
            strong with Jane Doe it is functional, and in Yanagi's case Rina
            also buffs her Electric DMG on top of her usual PEN Ratio and
            Electric Anomaly Buildup buffs which is greatly appreciated.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="harumasa" enablePopover /> -
            Harumasa naturally appreciates Rina's PEN Ratio buff and the Shock
            Anomalies are a small addition to the total team's damage, but
            Harumasa can also benefit from Rina's Additional Ability which gives
            an extra 10% Electric DMG to the whole team, a nice buff for
            Harumasa to exploit on top of everything Rina already provides.
          </li>
        </ul>
      )}
      {name === 'seth' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="jane-doe" enablePopover /> - Seth
            helps Jane and other Anomaly characters by specializing in Anomaly
            synergy and doesn't require to be played on the field for a long
            time. He applies a generic 20% Anomaly RES shred to enemies - which
            amplifies all Anomaly build-up against them. This effect greatly
            enhances Jane's Anomaly DMG potential. On top of that, Seth grants a
            chosen squad member (based on team positioning) up to 100 additional
            Anomaly Proficiency. This boost not only enhances Jane’s damage
            output but also assists her in capping out her Core Passive bonus,
            making for a very potent F2P synergy.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover /> - Seth is
            especially strong at buffing other Anomaly DPS Agents as he reduces
            the Anomaly Buildup RES of enemies via his Additional Ability.
            Yanagi and Seth activate each other's Additional Abilities which
            greatly boosts the Anomaly Buildup of both characters. As Yanagi
            primarily tries to deal damage via Anomalies and Disorders, the
            pairing is natural.
          </li>
        </ul>
      )}
      {name === 'soldier-11' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="lighter" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="koleda" enablePopover /> - The 2
            Fire Stun options are the most obvious-looking choice for Soldier
            11, and indeed Lighter is the best Stun Agent she has. Lighter is
            able to massively buff Fire DMG and reduce the Fire RES of enemies
            which boosts Soldier 11's damage by a ton, all the while dealing
            massive Daze. Koleda shares the Daze infliction but her buffs are
            limited to Chain Attacks and aren't as desirable, but she remains a
            good choice.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="trigger" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="pulchra" enablePopover /> - These
            2 off-field Stun choices are less natural than Lighter and Koleda
            but work brilliantly with Soldier 11. Pulchra at M6 gives a nice
            DMG% buff for Soldier 11 to exploit and Trigger gives a huge Stun
            DMG Multiplier increase as well. Both characters deal damage and
            Daze while off-field which allows Soldier 11 to take that field time
            instead and deal a lot of damage with either Trigger or Pulchra
            backing her up, making the pairings surprisingly strong.
          </li>
          {infoSupports}
        </ul>
      )}
      {name === 'soukaku' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="miyabi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="ellen" enablePopover /> - Soukaku
            stands out as an amazing F2P option for both Miyabi and Ellen as
            she's able to buff the Ice DMG of allies and she reduces the Ice RES
            of enemies, all the while providing a big ATK buff. Fully accessible
            by F2P players, Soukaku is a clear-cut buffer for all Ice DPS Agents
            and sure enough, she's one of the best F2P options for both Miyabi
            and Ellen, and she's even able to activate both of their Additional
            Abilities on her own. The pairing is viable, F2P-friendly and
            shockingly effective.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="lycaon" enablePopover /> - Soukaku
            stands out as an amazing F2P option for Ice teams with Lycaon, as
            she's able to provide him with his Additional Ability, and she's
            able to buff the Ice DMG of allies and reduce the Ice RES of enemies
            even further with Lycaon, all the while providing a big ATK buff.
            The pairing is definitely not to be underestimated.
          </li>
        </ul>
      )}
      {name === 'trigger' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter
              mode="inline"
              slug="anby-demara-soldier-0"
              enablePopover
            />{' '}
            - SAnby has a kit that complements Trigger perfectly, they elevate
            each other to new heights. SAnby capitalizes on having lots of field
            time to work with, and that typically involves sacrificing Daze to
            do so. However, Trigger is the best off-field Stun unit in the game
            and as a result can allow SAnby to exploit her full potential while
            offering Stun windows, all the while dealing boosted Aftershock DMG
            thanks to SAnby's Additional Ability, boosting Trigger's DMG who
            supplies Stun DMG Multiplier increases for SAnby to exploit. All in
            all the two are very synergistic and excel together.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover /> - Yanagi,
            whether paired with Miyabi or a Support, is an offensive powerhouse
            with Trigger. Trigger supplies some Shock anomaly but also a ton of
            off-field Daze, while activating each other's Additional Abilities,
            on top of Trigger's Stun DMG Multiplier debuff. This allows Yanagi
            to reveal her full Hypercarry potential, as well as work
            fantastically with Miyabi in a Quickswap playstyle.
          </li>
          {infoSupports}
        </ul>
      )}
      {name === 'yanagi' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="miyabi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="burnice" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="grace-howard" enablePopover /> -
            Yanagi synergizes well with characters who specialize in Anomaly
            DMG, Disorder DMG especially. Miyabi is the most obvious example of
            such a synergy, as she shares a faction with Yanagi which means they
            activate each other's Additional Abilities and they also both
            greatly capitalize off of Disorder DMG. Yanagi and Miyabi together
            makes for one of the highest damaging teams in the game, as they
            simply have perfect synergy together. Burnice is also amazing by
            simple virtue of being able to apply massive amounts of Burn anomaly
            to trigger Disorder with, and Grace is an okay F2P option who
            unfortunately won't help much with Disorders as she's of the same
            attribute as Yanagi but she remains functional.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="trigger" enablePopover /> -
            Trigger is a character Yanagi is unexpectedly extremely strong with.
            As Trigger shares an attribute with Yanagi, they activate each
            other's Additional Abilities, giving Yanagi higher damage and giving
            higher Daze to Trigger. Trigger additionally leaves the field time
            up to Yanagi as she operates off-field, which allows her to build up
            Electric Anomaly instead of spending time on another Agent.
            Trigger's universal damage boosts are always nice and overall this
            makes the pairing particularly synergistic.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="lucy" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="rina" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="seth" enablePopover /> - All of
            these Support choices can bring something to the table when it comes
            to Yanagi, the best one being listed first. Astra Yao provides Quick
            Assists, extra Chain Attacks, healing, super low field time, ATK,
            DMG and CRIT DMG buffs which makes her the overall best choice in
            almost all cases. Nicole provides the sought-after DEF Shred and
            CRIT Rate at M6 which makes her competitive, and Lucy has
            long-lasting ATK buffs which makes her a very practical choice,
            especially considering she can help with building Fire Anomaly.
            Finally, Rina is a good F2P option who gives PEN Ratio and Electric
            DMG boosts as well as helping towards Shock Anomaly, and Seth helps
            in the same way but favours Anomaly Buildup and Shielding over pure
            damage. All of these choices are therefore suitable for Yanagi's
            teams, but make sure to pick the best one depending on your last
            party member.
          </li>
        </ul>
      )}
      {name === 'zhu-yuan' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="qingyi" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="anby-demara" enablePopover /> -
            On-field Stun options are great for Zhu Yuan because they fill in
            for her downtime so no on-field time is wasted in between her damage
            windows. Qingyi is the most natural option and the best choice. She
            shares a faction with Zhu Yuan, she stuns very quickly, and she
            applies Stun DMG Multiplier debuffs to increase Zhu Yuan's damage
            against enemies. This means Zhu Yuan greatly benefits from Qingyi
            being present in her teams, however Anby is an option you can
            consider if you don't want to run Double Support team compositions
            and instead want to run a Stun Agent, as she applies a lot of Daze
            and fills in for Zhu Yuan's downtime, but beyond that point she
            lacks in extra utility which makes her viable, but worse.
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="nicole-demara" enablePopover />{' '}
            <ZZZCharacter mode="inline" slug="astra-yao" enablePopover /> -
            These 2 generalist Support units are capable of servicing as the
            Support to any team in the game. Astra Yao provides Quick Assists,
            extra Chain Attacks, healing, super low field time, ATK, DMG and
            CRIT DMG buffs which makes her the overall best choice in almost all
            cases. Nicole provides the sought-after DEF Shred and CRIT Rate at
            M6 which makes her competitive. They can work with any team in the
            game to great effect, whether that's by themselves or together,
            Nicole being particularly appreciated with Zhu Yuan as she also
            activates her Additional Ability which increases the whole party's
            Ether DMG and filling in for Zhu Yuan's downtimes.
          </li>
        </ul>
      )}
      {name === 'test' && (
        <ul className="bigger-margin">
          <li>
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover /> -
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover /> -
          </li>
          <li>
            <ZZZCharacter mode="inline" slug="yanagi" enablePopover /> -
          </li>
        </ul>
      )}
    </>
  );
};
